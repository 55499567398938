import React, { useContext, useEffect } from 'react';
import { IoIosPeople } from 'react-icons/io';
import { TeamContext, ProfileContext } from 'App';
import { disableEmojis, activeEmojis } from 'mockdata';
import ReactGA from 'react-ga';
import './index.scss';

interface ITeamInfo {
    teamId: number;
    teamIcon: number;
    teamName: string;
    packageSize: number;
    emailContact: string;
    teamDescription: string;
    teamCreator: string;
    teamCreatedTime: number;
    teamMembers: number[];
}

interface ITalentInfo {
    profileId: number;
    firstName: string;
    lastName: string;
    summary: string;
    avatar: string;
    jobTitle: string;
    department: number;
    openToWork: boolean;
    lastPaidTime: number;
    minBudget: number;
    skills: string;
    twitter: string;
    discord: string;
    linkedin: string;
    behance: string;
    github: string;
    medium: string;
    pinterest: string;
    facebook: string;
    youtube: string;
    experience: string[];
    creator: string;
    createdTime: number;
}

export const Teams = () => {

    const teams = useContext(TeamContext);
    const profiles = useContext(ProfileContext);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className='xemployed-teams'>
            <div className='top-teams-title'>
                <div className='title-effect'>
                    <p> WORK &nbsp;
                        <span>
                            AND
                        </span> &nbsp;
                        COLLABORATE &nbsp;
                        <span>
                            WITH THE
                        </span> &nbsp;
                        BEST &nbsp;
                        <span>
                            TEAMS
                        </span>
                    </p>
                </div>
            </div>
            <div className='bottom-all-teams row'>
                {
                    teams.map((team: ITeamInfo, index: number) => {
                        return (
                            <div key={index} className='single-team-container col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                                <div className='inner-div-container'>
                                    <div className='top-icon-title'>
                                        <img src={activeEmojis[team.teamIcon]} />
                                        <div className='right-container'>
                                            <div className='up-title'>
                                                <strong>{team.teamName}</strong>
                                            </div>
                                            <div className='down-package'>
                                                Package Price &nbsp;
                                                <strong>{team.packageSize}</strong> &nbsp;
                                                USD
                                            </div>
                                        </div>
                                    </div>
                                    <div className='middle-description'>
                                        {team.teamDescription}
                                    </div>
                                    <div className='bottom-team-members'>
                                        {
                                            team.teamMembers.length === 0 ? (
                                                <div className='empty-members'>
                                                    <IoIosPeople className='member-icon' />
                                                    No Members Yet ...
                                                </div>
                                            ) : (
                                                <div className='all-members'>
                                                    {
                                                        team.teamMembers.map((teamMember: any, memberIndex: number) => {
                                                            const talent: ITalentInfo = profiles.filter((profileItem: ITalentInfo) => profileItem.profileId === teamMember.toNumber())[0];

                                                            return (
                                                                <div key={memberIndex} className='single-team-member'>
                                                                    <div className='img-container'>
                                                                        <img src={talent.avatar} />
                                                                    </div>
                                                                    <div className='member-info'>
                                                                        {talent.firstName + ' ' + talent.lastName}
                                                                    </div>
                                                                    <div className='member-info'>
                                                                        |
                                                                    </div>
                                                                    <div className='member-info'>
                                                                        {talent.jobTitle}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};