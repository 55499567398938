import React, { useEffect, useState, useContext, useRef } from 'react';
import { Form, NavDropdown } from 'react-bootstrap';

import { ImCloudUpload } from 'react-icons/im';
import { FaChevronDown } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { HiTrash } from 'react-icons/hi';

import { useGetAccountInfo, useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { ArgSerializer, BigUIntValue, BytesValue, TransactionPayload, U32Value, BooleanValue, TokenPayment } from '@multiversx/sdk-core/out';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions/useGetPendingTransactions';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import BigNumber from 'bignumber.js/bignumber.js';

import HireMeActive from 'assets/img/hire-me-edit-active.svg';
import HireMeUnactive from 'assets/img/hire-me-unactive.svg';
import HireMeHover from 'assets/img/hire-me-hover.svg';

import { Divider, Backdrop, CircularProgress } from '@mui/material';
import swal from 'sweetalert';
import axios from 'axios';

import { ProfileContext, TransactionHappenContext } from 'App';
import { REACT_APP_API_KEY, REACT_APP_API_SECRET, PINATA_URL, PINATA_GATEWAY, CONTRACT_ADDRESS, TOKEN_IDENTIFIER } from 'config';
import { getBlxcNftCount, getTycheNftCount } from 'utils/getnfts';
import './index.scss';
interface ITalentInfo {
    profileId: number;
    firstName: string;
    lastName: string;
    summary: string;
    avatar: string;
    jobTitle: string;
    department: number;
    openToWork: boolean;
    lastPaidTime: number;
    minBudget: number;
    skills: string;
    twitter: string;
    discord: string;
    linkedin: string;
    behance: string;
    github: string;
    medium: string;
    pinterest: string;
    facebook: string;
    youtube: string;
    experience: string[];
    creator: string;
    createdTime: number;
}

interface ICreateTalentInfo {
    firstName: string;
    lastName: string;
    summary: string;
    avatar: string;
    jobTitle: string;
    department: number;
    openToWork: boolean;
    minBudget: number;
    skills: string;
    twitter: string;
    discord: string;
    linkedin: string;
    behance: string;
    github: string;
    medium: string;
    pinterest: string;
    facebook: string;
    youtube: string;
    experience: string[];
}

export const ProfileEdit = () => {

    const { address } = useGetAccountInfo();
    const profiles = useContext(ProfileContext);

    const { hasPendingTransactions } = useGetPendingTransactions();
    const { transactionHappen, setTransactionHappen } = useContext(TransactionHappenContext);
    const [transactionType, setTransactionType] = useState('');
    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({ transactionId: sessionId });

    const [uploadingNow, setUploadingNow] = useState(false);
    const createInputImage: any = useRef();
    const editInputImage: any = useRef();

    const [profileData, setProfileData] = useState<ITalentInfo | null>(null);
    const [editProfileData, setEditProfileData] = useState<ITalentInfo | null>(null);
    const [createProfileData, setCreateProfileData] = useState<ICreateTalentInfo>({
        firstName: '',
        lastName: '',
        avatar: '',
        summary: '',
        jobTitle: '',
        department: 0,
        openToWork: false,
        minBudget: 0,
        skills: '',
        twitter: '',
        discord: '',
        linkedin: '',
        behance: '',
        github: '',
        medium: '',
        pinterest: '',
        facebook: '',
        youtube: '',
        experience: [],
    });
    const [hireMeHover, setHireMeHover] = useState(false);
    const [skillsInput, setSkillsInput] = useState('');
    const [newExperienceJobPosition, setNewExperienceJobPosition] = useState('');
    const [newExperienceProjectName, setNewExperienceProjectName] = useState('');
    const [newExperienceStartingDate, setNewExperienceStartingDate] = useState('');
    const [newExperienceEndingDate, setNewExperienceEndingDate] = useState('');
    const [newExperienceJobType, setNewExperienceJobType] = useState('');

    const handleHireMeHover = () => {
        setHireMeHover(true);
    };

    const handleHireMeUnhover = () => {
        setHireMeHover(false);
    };

    // Change Profile Information

    const handleEditAvatar = async (event: any) => {
        const fileToHandle = event.target.files[0];

        if (fileToHandle) {
            try {
                setUploadingNow(true);
                const formData: any = new FormData();
                formData.append('file', fileToHandle);

                const API_KEY = REACT_APP_API_KEY;
                const API_SECRET = REACT_APP_API_SECRET;

                const url = PINATA_URL;

                const response = await axios.post(url, formData, {
                    headers: {
                        'Content-Type': `multipart/form-data;boundary=${formData._boundary}`,
                        pinata_api_key: API_KEY,
                        pinata_secret_api_key: API_SECRET,
                    },
                });

                const photoUrl = response.data.IpfsHash;

                if (editProfileData !== null) {
                    setEditProfileData({ ...editProfileData, avatar: (PINATA_GATEWAY + photoUrl) });
                }
                setUploadingNow(false);

            } catch (error) {
                console.log('Error sending File to IPFS: ');
                console.log(error);
            }
        }
    };

    const handleHireMeStatusChange = () => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, openToWork: !editProfileData.openToWork });
        }
    };

    const handleSummaryChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, summary: e.target.value });
        }
    };

    const handleFirstNameChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, firstName: e.target.value });
        }
    };

    const handleLastNameChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, lastName: e.target.value });
        }
    };

    const handleJobTitleChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, jobTitle: e.target.value });
        }
    };

    const handleDepartmentChange = (departmentType: number) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, department: departmentType });
        }
    };

    /********** For adding & remove skills *****************/

    const handleSkillKeyDown = (e: any) => {
        if (editProfileData !== null) {
            const tempSkills = editProfileData.skills;
            if (e.keyCode === 13 && skillsInput !== '') {
                setEditProfileData({ ...editProfileData, skills: tempSkills.concat(`${skillsInput};`) });
                setSkillsInput('');
            }
            if (e.key === ';') {
                e.preventDefault();
            }
        }
    };

    const handleSkillsInputChange = (e: any) => {
        setSkillsInput(e.target.value);
    };

    const handleRemoveSkill = (index: number) => {
        if (editProfileData !== null) {
            const skillsArray = editProfileData?.skills.split(';');
            skillsArray.splice(index, 1);
            let tempSkills = '';
            skillsArray.map(item => {
                if (item !== '') {
                    tempSkills = tempSkills.concat(`${item};`);
                }
            });
            setEditProfileData({ ...editProfileData, skills: tempSkills });
            console.log(tempSkills);
        }
    };

    /**********************************************************/

    const handleMinBudgetChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, minBudget: e.target.value });
        }
    };

    const handleTwitterChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, twitter: e.target.value });
        }
    };

    const handleDiscordChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, discord: e.target.value });
        }
    };

    const handleLinkedinChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, linkedin: e.target.value });
        }
    };

    const handleBehanceChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, behance: e.target.value });
        }
    };

    const handleGithubChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, github: e.target.value });
        }
    };

    const handleMediumChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, medium: e.target.value });
        }
    };

    const handlePinterestChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, pinterest: e.target.value });
        }
    };

    const handleFacebookChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, facebook: e.target.value });
        }
    };

    const handleYoutubeChange = (e: any) => {
        if (editProfileData !== null) {
            setEditProfileData({ ...editProfileData, youtube: e.target.value });
        }
    };

    /**************** Work Experience *********************/

    const handleRemoveWorkExperience = (index: number) => {
        if (editProfileData !== null) {
            const tempExperience = editProfileData.experience;
            tempExperience.splice(index, 1);
            setEditProfileData({ ...editProfileData, experience: tempExperience });
        }
    };

    const handleNewJobPositionChange = (e: any) => {
        setNewExperienceJobPosition(e.target.value);
    };

    const handleNewProjectNameChange = (e: any) => {
        setNewExperienceProjectName(e.target.value);
    };

    const handleNewStartingDateChange = (e: any) => {
        setNewExperienceStartingDate(e.target.value);
    };

    const handleNewEndingDateChange = (e: any) => {
        setNewExperienceEndingDate(e.target.value);
    };

    const handleNewJobType = (jobType: string) => {
        setNewExperienceJobType(jobType);
    };

    const handleSubmitNewExperience = () => {
        if (editProfileData !== null) {
            const tempExperience = editProfileData.experience;
            const newExperience = `${newExperienceJobPosition};${newExperienceJobType};${newExperienceProjectName};${newExperienceStartingDate};${newExperienceEndingDate}`;
            setEditProfileData({ ...editProfileData, experience: tempExperience.concat(newExperience) });
            setNewExperienceJobPosition('');
            setNewExperienceProjectName('');
            setNewExperienceStartingDate('');
            setNewExperienceEndingDate('');
            setNewExperienceJobType('');
        }
    };

    /************************************************** */

    const handleSaveChangeProfile = async (profileId: number) => {

        if (editProfileData !== null) {
            const args = [
                new U32Value(profileId),
                BytesValue.fromUTF8(editProfileData.firstName),
                BytesValue.fromUTF8(editProfileData.lastName),
                BytesValue.fromUTF8(editProfileData.avatar),
                BytesValue.fromUTF8(editProfileData.summary),
                BytesValue.fromUTF8(editProfileData.jobTitle),
                new U32Value(editProfileData.department),
                new BooleanValue(editProfileData.openToWork),
                new U32Value(editProfileData.minBudget),
                BytesValue.fromUTF8(editProfileData.skills),
                BytesValue.fromUTF8(editProfileData.twitter),
                BytesValue.fromUTF8(editProfileData.discord),
                BytesValue.fromUTF8(editProfileData.linkedin),
                BytesValue.fromUTF8(editProfileData.behance),
                BytesValue.fromUTF8(editProfileData.github),
                BytesValue.fromUTF8(editProfileData.medium),
                BytesValue.fromUTF8(editProfileData.pinterest),
                BytesValue.fromUTF8(editProfileData.facebook),
                BytesValue.fromUTF8(editProfileData.youtube),
            ];

            editProfileData.experience.map((item: string) => {
                args.push(BytesValue.fromUTF8(item));
            });

            const { argumentsString } = new ArgSerializer().valuesToString(args);
            const data = new TransactionPayload(`updateProfile@${argumentsString}`);
            const gas = (55000000 + editProfileData.summary.length * 1500) > 600000000 ? 600000000 : (55000000 + editProfileData.summary.length * 1500);

            const tx = {
                value: 0,
                receiver: CONTRACT_ADDRESS,
                data: data.toString(),
                gasLimit: gas,
            };

            await refreshAccount();
            const result = await sendTransactions({
                transactions: tx,
            });

            setSessionId(result.sessionId);
        }
    };

    const handleDiscardChangeProfile = () => {
        setEditProfileData(profileData);
    };

    /******************************************************/

    /***************** CREATE NEW PROFILE *****************/

    const handleCreateHireMeStatusChange = () => {
        setCreateProfileData({ ...createProfileData, openToWork: !createProfileData.openToWork });
    };

    const handleCreateAvatar = async (event: any) => {
        const fileToHandle = event.target.files[0];

        if (fileToHandle) {
            try {
                setUploadingNow(true);
                const formData: any = new FormData();
                formData.append('file', fileToHandle);

                const API_KEY = REACT_APP_API_KEY;
                const API_SECRET = REACT_APP_API_SECRET;

                const url = PINATA_URL;

                const response = await axios.post(url, formData, {
                    headers: {
                        'Content-Type': `multipart/form-data;boundary=${formData._boundary}`,
                        pinata_api_key: API_KEY,
                        pinata_secret_api_key: API_SECRET,
                    },
                });

                const photoUrl = response.data.IpfsHash;
                setCreateProfileData({ ...createProfileData, avatar: (PINATA_GATEWAY + photoUrl) });
                setUploadingNow(false);

            } catch (error) {
                console.log('Error sending File to IPFS: ');
                console.log(error);
            }
        }
    };

    const handleCreateSummaryChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, summary: e.target.value });
    };

    const handleCreateFirstNameChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, firstName: e.target.value });
    };

    const handleCreateLastNameChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, lastName: e.target.value });
    };

    const handleCreateJobTitleChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, jobTitle: e.target.value });
    };

    const handleCreateDepartmentChange = (departmentType: number) => {
        setCreateProfileData({ ...createProfileData, department: departmentType });
    };

    /********** For adding & remove skills *****************/

    const handleCreateSkillKeyDown = (e: any) => {
        if (createProfileData !== null) {
            const tempSkills = createProfileData.skills;
            if (e.keyCode === 13 && skillsInput !== '') {
                setCreateProfileData({ ...createProfileData, skills: tempSkills.concat(`${skillsInput};`) });
                setSkillsInput('');
            }
            if (e.key === ';') {
                e.preventDefault();
            }
        }
    };

    const handleCreateSkillsInputChange = (e: any) => {
        setSkillsInput(e.target.value);
    };

    const handleCreateRemoveSkill = (index: number) => {
        if (createProfileData !== null) {
            const skillsArray = createProfileData?.skills.split(';');
            skillsArray.splice(index, 1);
            let tempSkills = '';
            skillsArray.map(item => {
                if (item !== '') {
                    tempSkills = tempSkills.concat(`${item};`);
                }
            });
            setCreateProfileData({ ...createProfileData, skills: tempSkills });
            console.log(tempSkills);
        }
    };

    const handleCreateMinBudgetChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, minBudget: e.target.value });
    };

    const handleCreateTwitterChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, twitter: e.target.value });
    };

    const handleCreateDiscordChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, discord: e.target.value });
    };

    const handleCreateLinkedinChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, linkedin: e.target.value });
    };

    const handleCreateBehanceChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, behance: e.target.value });
    };

    const handleCreateGithubChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, github: e.target.value });
    };

    const handleCreateMediumChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, medium: e.target.value });
    };

    const handleCreatePinterestChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, pinterest: e.target.value });
    };

    const handleCreateFacebookChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, facebook: e.target.value });
    };

    const handleCreateYoutubeChange = (e: any) => {
        setCreateProfileData({ ...createProfileData, youtube: e.target.value });
    };

    /**************** Work Experience *********************/

    const handleCreateRemoveWorkExperience = (index: number) => {
        const tempExperience = createProfileData.experience;
        tempExperience.splice(index, 1);
        setCreateProfileData({ ...createProfileData, experience: tempExperience });
    };

    const handleCreateSubmitNewExperience = () => {
        const tempExperience = createProfileData.experience;
        const newExperience = `${newExperienceJobPosition};${newExperienceJobType};${newExperienceProjectName};${newExperienceStartingDate};${newExperienceEndingDate}`;
        setCreateProfileData({ ...createProfileData, experience: tempExperience.concat(newExperience) });
        setNewExperienceJobPosition('');
        setNewExperienceProjectName('');
        setNewExperienceStartingDate('');
        setNewExperienceEndingDate('');
        setNewExperienceJobType('');
    };

    const handleSubmitNewProfile = async () => {

        if (createProfileData.openToWork) {

            const blxcAmount = await getBlxcNftCount(address);
            const tycheAmount = await getTycheNftCount(address);

            if (blxcAmount >= 8 || tycheAmount >= 8) {
                const args = [
                    BytesValue.fromUTF8(createProfileData.firstName),
                    BytesValue.fromUTF8(createProfileData.lastName),
                    BytesValue.fromUTF8(createProfileData.avatar),
                    BytesValue.fromUTF8(createProfileData.summary),
                    BytesValue.fromUTF8(createProfileData.jobTitle),
                    new U32Value(createProfileData.department),
                    new BooleanValue(createProfileData.openToWork),
                    new U32Value(createProfileData.minBudget),
                    BytesValue.fromUTF8(createProfileData.skills),
                    BytesValue.fromUTF8(createProfileData.twitter),
                    BytesValue.fromUTF8(createProfileData.discord),
                    BytesValue.fromUTF8(createProfileData.linkedin),
                    BytesValue.fromUTF8(createProfileData.behance),
                    BytesValue.fromUTF8(createProfileData.github),
                    BytesValue.fromUTF8(createProfileData.medium),
                    BytesValue.fromUTF8(createProfileData.pinterest),
                    BytesValue.fromUTF8(createProfileData.facebook),
                    BytesValue.fromUTF8(createProfileData.youtube),
                ];

                createProfileData.experience.map((item: string) => {
                    args.push(BytesValue.fromUTF8(item));
                });

                const { argumentsString } = new ArgSerializer().valuesToString(args);
                const data = new TransactionPayload(`createNewProfile@${argumentsString}`);
                const gas = (55000000 + createProfileData.summary.length * 1500) > 600000000 ? 600000000 : (55000000 + createProfileData.summary.length * 1500);

                const tx = {
                    value: 0,
                    receiver: CONTRACT_ADDRESS,
                    data: data.toString(),
                    gasLimit: gas,
                };

                await refreshAccount();
                const result = await sendTransactions({
                    transactions: tx,
                });

                setSessionId(result.sessionId);

            }

            else {
                const value = new BigNumber(15).multipliedBy(
                    Math.pow(10, 6)
                );

                const args = [
                    BytesValue.fromUTF8(TOKEN_IDENTIFIER),
                    new BigUIntValue(TokenPayment.fungibleFromBigInteger(TOKEN_IDENTIFIER, value, 6).valueOf()),
                    BytesValue.fromUTF8('createNewProfile'),
                    BytesValue.fromUTF8(createProfileData.firstName),
                    BytesValue.fromUTF8(createProfileData.lastName),
                    BytesValue.fromUTF8(createProfileData.avatar),
                    BytesValue.fromUTF8(createProfileData.summary),
                    BytesValue.fromUTF8(createProfileData.jobTitle),
                    new U32Value(createProfileData.department),
                    new BooleanValue(createProfileData.openToWork),
                    new U32Value(createProfileData.minBudget),
                    BytesValue.fromUTF8(createProfileData.skills),
                    BytesValue.fromUTF8(createProfileData.twitter),
                    BytesValue.fromUTF8(createProfileData.discord),
                    BytesValue.fromUTF8(createProfileData.linkedin),
                    BytesValue.fromUTF8(createProfileData.behance),
                    BytesValue.fromUTF8(createProfileData.github),
                    BytesValue.fromUTF8(createProfileData.medium),
                    BytesValue.fromUTF8(createProfileData.pinterest),
                    BytesValue.fromUTF8(createProfileData.facebook),
                    BytesValue.fromUTF8(createProfileData.youtube),
                ];

                createProfileData.experience.map((item: string) => {
                    args.push(BytesValue.fromUTF8(item));
                });

                const { argumentsString } = new ArgSerializer().valuesToString(args);
                const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);
                const gas = (55000000 + createProfileData.summary.length * 1500) > 600000000 ? 600000000 : (55000000 + createProfileData.summary.length * 1500);

                const tx = {
                    value: 0,
                    receiver: CONTRACT_ADDRESS,
                    data: data.toString(),
                    gasLimit: gas,
                };

                await refreshAccount();
                const result = await sendTransactions({
                    transactions: tx,
                });

                setSessionId(result.sessionId);
            }
        }

        else {

            const args = [
                BytesValue.fromUTF8(createProfileData.firstName),
                BytesValue.fromUTF8(createProfileData.lastName),
                BytesValue.fromUTF8(createProfileData.avatar),
                BytesValue.fromUTF8(createProfileData.summary),
                BytesValue.fromUTF8(createProfileData.jobTitle),
                new U32Value(createProfileData.department),
                new BooleanValue(createProfileData.openToWork),
                new U32Value(createProfileData.minBudget),
                BytesValue.fromUTF8(createProfileData.skills),
                BytesValue.fromUTF8(createProfileData.twitter),
                BytesValue.fromUTF8(createProfileData.discord),
                BytesValue.fromUTF8(createProfileData.linkedin),
                BytesValue.fromUTF8(createProfileData.behance),
                BytesValue.fromUTF8(createProfileData.github),
                BytesValue.fromUTF8(createProfileData.medium),
                BytesValue.fromUTF8(createProfileData.pinterest),
                BytesValue.fromUTF8(createProfileData.facebook),
                BytesValue.fromUTF8(createProfileData.youtube),
            ];

            createProfileData.experience.map((item: string) => {
                args.push(BytesValue.fromUTF8(item));
            });

            const { argumentsString } = new ArgSerializer().valuesToString(args);
            const data = new TransactionPayload(`createNewProfile@${argumentsString}`);
            const gas = (55000000 + createProfileData.summary.length * 1500) > 600000000 ? 600000000 : (55000000 + createProfileData.summary.length * 1500);

            const tx = {
                value: 0,
                receiver: CONTRACT_ADDRESS,
                data: data.toString(),
                gasLimit: gas,
            };

            await refreshAccount();
            const result = await sendTransactions({
                transactions: tx,
            });

            setSessionId(result.sessionId);
        }
    };

    /******************************************************/

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            if (transactionType === 'create-profile') {
                setTransactionHappen(!transactionHappen);
                swal('Success', 'You successfully created a new profile!', 'success');
                setProfileData(profiles.filter((item: ITalentInfo) => item.creator === address)[0]);
                setEditProfileData(profiles.filter((item: ITalentInfo) => item.creator === address)[0]);
            } else if (transactionType === 'edit-profile') {
                setTransactionHappen(!transactionHappen);
                swal('Success', 'You successfully updated your profile!', 'success');
                setProfileData(profiles.filter((item: ITalentInfo) => item.creator === address)[0]);
                setEditProfileData(profiles.filter((item: ITalentInfo) => item.creator === address)[0]);
            }
        }
    }, [sessionId, hasPendingTransactions]);

    useEffect(() => {
        setProfileData(profiles.filter((item: ITalentInfo) => item.creator === address)[0]);
        setEditProfileData(profiles.filter((item: ITalentInfo) => item.creator === address)[0]);
    }, []);

    return (
        <div className='profile-edit'>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={uploadingNow}
            >
                <CircularProgress
                    color='inherit' />
            </Backdrop>
            {
                editProfileData ? (
                    <div className='edit-profile-box'>
                        <div className='top-avatar-summary row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-4'>
                                <div
                                    className='avatar-upload'
                                    style={{
                                        backgroundImage: `url(${editProfileData?.avatar})`
                                    }}
                                >
                                    <div
                                        className='upload-image-btn'
                                        onClick={() => editInputImage.current.click()}
                                    >
                                        <div className='icon-container'>
                                            <ImCloudUpload />
                                        </div>
                                        <div className='upload-text'>
                                            <input hidden accept='image/*' type='file' onChange={handleEditAvatar} ref={editInputImage} />
                                            Upload
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='summary-container col-sm-12 col-md-12 col-lg-12 col-xl-8'>
                                <div className='up-summary-title'>
                                    Professional Summary *
                                </div>
                                <div className='down-summary-edit'>
                                    <Form.Control as='textarea'
                                        placeholder='Write a Professional Summary here...'
                                        value={editProfileData?.summary}
                                        onChange={handleSummaryChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='middle-primary-info row'>
                            <div className='left-hire-me col-sm-12 col-md-12 col-lg-12 col-xl-4'>
                                <div
                                    className='open-to-work'
                                    onMouseOver={handleHireMeHover}
                                    onMouseLeave={handleHireMeUnhover}
                                    onClick={handleHireMeStatusChange}
                                >
                                    {
                                        editProfileData?.openToWork ? (
                                            <img src={HireMeActive} />
                                        ) : (
                                            <img src={hireMeHover ? HireMeHover : HireMeUnactive} />
                                        )
                                    }
                                </div>
                                <div className='hire-me-text'>
                                    IF YOU&apos;RE OPEN TO WORK, PRESS THIS! <br /> <br />
                                    15 USDC FOR 1 MONTH
                                </div>
                            </div>
                            <div className='right-primary col-sm-12 col-md-12 col-lg-12 col-xl-8'>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        First Name *
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Write your First Name...'
                                            value={editProfileData?.firstName}
                                            onChange={handleFirstNameChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Last Name *
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Write your Last Name...'
                                            value={editProfileData?.lastName}
                                            onChange={handleLastNameChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Job Title *
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Write your Job Title...'
                                            value={editProfileData?.jobTitle}
                                            onChange={handleJobTitleChange}
                                        />
                                    </div>
                                </div>
                                <div className='choose-department-box'>
                                    <div className='up-label'>
                                        Choose a Department *
                                    </div>
                                    <div className='down-select'>
                                        <NavDropdown
                                            className='filter-dropdown'
                                            title={
                                                <div className='filter-by-options'>
                                                    {
                                                        editProfileData?.department === 0 ? (
                                                            <div className='selected-blank-options'>
                                                                No Filter
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        editProfileData?.department === 1 ? (
                                                            <div className='selected-options'>
                                                                Graphic & Design
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        editProfileData?.department === 2 ? (
                                                            <div className='selected-options'>
                                                                Digital Marketing
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        editProfileData?.department === 3 ? (
                                                            <div className='selected-options'>
                                                                Writing & Translation
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        editProfileData?.department === 4 ? (
                                                            <div className='selected-options'>
                                                                Video & Animation
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        editProfileData?.department === 5 ? (
                                                            <div className='selected-options'>
                                                                Music & Audio
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        editProfileData?.department === 6 ? (
                                                            <div className='selected-options'>
                                                                Programming & Tech
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        editProfileData?.department === 7 ? (
                                                            <div className='selected-options'>
                                                                Business
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        editProfileData?.department === 8 ? (
                                                            <div className='selected-options'>
                                                                LifeStyle
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        editProfileData?.department === 9 ? (
                                                            <div className='selected-options'>
                                                                AI Services
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    <FaChevronDown className='filter-down-icon' />
                                                </div>
                                            }
                                        >
                                            <NavDropdown.Item onClick={() => handleDepartmentChange(1)}>
                                                <div className='filter-menu-dropdown'>
                                                    Graphic & Design
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleDepartmentChange(2)}>
                                                <div className='filter-menu-dropdown'>
                                                    Digital Marketing
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleDepartmentChange(3)}>
                                                <div className='filter-menu-dropdown'>
                                                    Writing & Translation
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleDepartmentChange(4)}>
                                                <div className='filter-menu-dropdown'>
                                                    Video & Animation
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleDepartmentChange(5)}>
                                                <div className='filter-menu-dropdown'>
                                                    Music & Audio
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleDepartmentChange(6)}>
                                                <div className='filter-menu-dropdown'>
                                                    Programming & Tech
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleDepartmentChange(7)}>
                                                <div className='filter-menu-dropdown'>
                                                    Business
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleDepartmentChange(8)}>
                                                <div className='filter-menu-dropdown'>
                                                    LifeStyle
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleDepartmentChange(9)}>
                                                <div className='filter-menu-dropdown'>
                                                    AI Services
                                                </div>
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    </div>
                                </div>
                                <div className='add-skill-box'>
                                    <div className='up-label'>
                                        Add your Skills
                                    </div>
                                    <div className='down-skills'>
                                        <Form.Control
                                            placeholder='Write a Skill, then press Enter...'
                                            onKeyDown={handleSkillKeyDown}
                                            value={skillsInput}
                                            onChange={handleSkillsInputChange}
                                        />
                                        <div className='skills-container'>
                                            {
                                                editProfileData?.skills.split(';').slice(0, editProfileData?.skills.split(';').length - 1).map((skill, index) => (
                                                    <div key={index} className='single-skill'>
                                                        {skill}
                                                        <MdClose
                                                            className='close-icon'
                                                            onClick={() => handleRemoveSkill(index)}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Min Budget *
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Ex. 1,000'
                                            value={editProfileData?.minBudget}
                                            onChange={handleMinBudgetChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Twitter Profile Link *
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={editProfileData?.twitter}
                                            onChange={handleTwitterChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Discord Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={editProfileData?.discord}
                                            onChange={handleDiscordChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Linkedin Profile Link *
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={editProfileData?.linkedin}
                                            onChange={handleLinkedinChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Behance Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={editProfileData?.behance}
                                            onChange={handleBehanceChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Github Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={editProfileData?.github}
                                            onChange={handleGithubChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Medium Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={editProfileData?.medium}
                                            onChange={handleMediumChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Pinterest Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={editProfileData?.pinterest}
                                            onChange={handlePinterestChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Facebook Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={editProfileData?.facebook}
                                            onChange={handleFacebookChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        YouTube Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={editProfileData?.youtube}
                                            onChange={handleYoutubeChange}
                                        />
                                    </div>
                                </div>
                                <div className='add-experience-box'>
                                    <div className='up-label'>
                                        Work Experience
                                    </div>
                                    <div className='down-experience-container'>
                                        {
                                            editProfileData?.experience.map((experience, index) => {
                                                return (
                                                    <div key={index} className='single-experience-box'>
                                                        <div className='up-title'>
                                                            <div className='left-job-title'>
                                                                {experience.split(';')[0]}
                                                            </div>
                                                            <div className='right-job-type'>
                                                                {experience.split(';')[1]}
                                                            </div>
                                                        </div>
                                                        <div className='down-experience'>
                                                            <div className='left-company-title'>
                                                                {experience.split(';')[2]}
                                                            </div>
                                                            <div className='right-experience-period'>
                                                                {
                                                                    new Date(experience.split(';')[3]).toDateString()
                                                                    + ' - ' +
                                                                    new Date(experience.split(';')[4]).toDateString()
                                                                }
                                                            </div>
                                                        </div>
                                                        <Divider className='bottom-divider' />
                                                        <div className='remove-container'>
                                                            <HiTrash
                                                                className='remove-icon'
                                                                onClick={() => handleRemoveWorkExperience(index)}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    <div className='new-experience-box row'>
                                        <div className='single-left-box col-sm-12 col-md-12 col-lg-6'>
                                            <div className='up-label'>
                                                Job Position
                                            </div>
                                            <div className='down-input'>
                                                <Form.Control
                                                    placeholder='Write Here'
                                                    value={newExperienceJobPosition}
                                                    onChange={handleNewJobPositionChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='single-right-box col-sm-12 col-md-12 col-lg-6'>
                                            <div className='up-label'>
                                                Project Name
                                            </div>
                                            <div className='down-input'>
                                                <Form.Control
                                                    placeholder='Write Here'
                                                    value={newExperienceProjectName}
                                                    onChange={handleNewProjectNameChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='single-left-box col-sm-12 col-md-12 col-lg-6'>
                                            <div className='up-label'>
                                                Starting Date
                                            </div>
                                            <div className='down-input'>
                                                <Form.Control
                                                    placeholder='Month/Day/Year'
                                                    value={newExperienceStartingDate}
                                                    onChange={handleNewStartingDateChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='single-right-box col-sm-12 col-md-12 col-lg-6'>
                                            <div className='up-label'>
                                                Ending Date
                                            </div>
                                            <div className='down-input'>
                                                <Form.Control
                                                    placeholder='Month/Day/Year'
                                                    value={newExperienceEndingDate}
                                                    onChange={handleNewEndingDateChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='select-job-type col-sm-12 col-md-12 col-lg-6'>
                                            <div
                                                className={`single-type-box ${newExperienceJobType === 'Full Time' ? 'active' : ''}`}
                                                onClick={() => handleNewJobType('Full Time')}
                                            >
                                                Full Time
                                            </div>
                                            <div
                                                className={`single-type-box ${newExperienceJobType === 'Part Time' ? 'active' : ''}`}
                                                onClick={() => handleNewJobType('Part Time')}
                                            >
                                                Part Time
                                            </div>
                                        </div>
                                        <div className='submit-container col-sm-12 col-md-12 col-lg-6'>
                                            <div
                                                className='submit-btn'
                                                onClick={handleSubmitNewExperience}
                                            >
                                                Add Work Experience
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='discard-save-container row'>
                                    <div className='save-container col-sm-12 col-md-12 col-lg-6'>
                                        {
                                            editProfileData === profileData ? (
                                                <div className='save-btn'>
                                                    Save Changes
                                                </div>
                                            ) : (
                                                <div
                                                    className='save-btn active'
                                                    onClick={
                                                        () => {
                                                            handleSaveChangeProfile(editProfileData.profileId);
                                                            setTransactionType('edit-profile');
                                                        }
                                                    }
                                                >
                                                    Save Changes
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='discard-container col-sm-12 col-md-12 col-lg-6'>
                                        <div
                                            className='discard-btn'
                                            onClick={handleDiscardChangeProfile}
                                        >
                                            Discard Changes
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='create-profile-box'>
                        <div className='top-avatar-summary row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-4'>
                                <div
                                    className='avatar-upload'
                                    style={{
                                        backgroundImage: `url(${createProfileData?.avatar})`
                                    }}
                                >
                                    <div
                                        className='upload-image-btn'
                                        onClick={() => createInputImage.current.click()}
                                    >
                                        <div className='icon-container'>
                                            <ImCloudUpload />
                                        </div>
                                        <div className='upload-text'>
                                            <input hidden accept='image/*' type='file' onChange={handleCreateAvatar} ref={createInputImage} />
                                            Upload
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='summary-container col-sm-12 col-md-12 col-lg-12 col-xl-8'>
                                <div className='up-summary-title'>
                                    Professional Summary *
                                </div>
                                <div className='down-summary-edit'>
                                    <Form.Control as='textarea'
                                        placeholder='Write a Professional Summary here...'
                                        value={createProfileData?.summary}
                                        onChange={handleCreateSummaryChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='middle-primary-info row'>
                            <div className='left-hire-me col-sm-12 col-md-12 col-lg-12 col-xl-4'>
                                <div
                                    className='open-to-work'
                                    onMouseOver={handleHireMeHover}
                                    onMouseLeave={handleHireMeUnhover}
                                    onClick={handleCreateHireMeStatusChange}
                                >
                                    {
                                        createProfileData?.openToWork ? (
                                            <img src={HireMeActive} />
                                        ) : (
                                            <img src={hireMeHover ? HireMeHover : HireMeUnactive} />
                                        )
                                    }
                                </div>
                                <div className='hire-me-text'>
                                    IF YOU&apos;RE OPEN TO WORK, PRESS THIS! <br /> <br />
                                    15 USDC FOR 1 MONTH
                                </div>
                            </div>
                            <div className='right-primary col-sm-12 col-md-12 col-lg-12 col-xl-8'>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        First Name *
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Write your First Name...'
                                            value={createProfileData?.firstName}
                                            onChange={handleCreateFirstNameChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Last Name *
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Write your Last Name...'
                                            value={createProfileData?.lastName}
                                            onChange={handleCreateLastNameChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Job Title *
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Write your Job Title...'
                                            value={createProfileData?.jobTitle}
                                            onChange={handleCreateJobTitleChange}
                                        />
                                    </div>
                                </div>
                                <div className='choose-department-box'>
                                    <div className='up-label'>
                                        Choose a Department *
                                    </div>
                                    <div className='down-select'>
                                        <NavDropdown
                                            className='filter-dropdown'
                                            title={
                                                <div className='filter-by-options'>
                                                    {
                                                        createProfileData?.department === 0 ? (
                                                            <div className='selected-blank-options'>
                                                                No Filter
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        createProfileData?.department === 1 ? (
                                                            <div className='selected-options'>
                                                                Graphic & Design
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        createProfileData?.department === 2 ? (
                                                            <div className='selected-options'>
                                                                Digital Marketing
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        createProfileData?.department === 3 ? (
                                                            <div className='selected-options'>
                                                                Writing & Translation
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        createProfileData?.department === 4 ? (
                                                            <div className='selected-options'>
                                                                Video & Animation
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        createProfileData?.department === 5 ? (
                                                            <div className='selected-options'>
                                                                Music & Audio
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        createProfileData?.department === 6 ? (
                                                            <div className='selected-options'>
                                                                Programming & Tech
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        createProfileData?.department === 7 ? (
                                                            <div className='selected-options'>
                                                                Business
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        createProfileData?.department === 8 ? (
                                                            <div className='selected-options'>
                                                                LifeStyle
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        createProfileData?.department === 9 ? (
                                                            <div className='selected-options'>
                                                                AI Services
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    <FaChevronDown className='filter-down-icon' />
                                                </div>
                                            }
                                        >
                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(1)}>
                                                <div className='filter-menu-dropdown'>
                                                    Graphic & Design
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(2)}>
                                                <div className='filter-menu-dropdown'>
                                                    Digital Marketing
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(3)}>
                                                <div className='filter-menu-dropdown'>
                                                    Writing & Translation
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(4)}>
                                                <div className='filter-menu-dropdown'>
                                                    Video & Animation
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(5)}>
                                                <div className='filter-menu-dropdown'>
                                                    Music & Audio
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(6)}>
                                                <div className='filter-menu-dropdown'>
                                                    Programming & Tech
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(7)}>
                                                <div className='filter-menu-dropdown'>
                                                    Business
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(8)}>
                                                <div className='filter-menu-dropdown'>
                                                    LifeStyle
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(9)}>
                                                <div className='filter-menu-dropdown'>
                                                    AI Services
                                                </div>
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    </div>
                                </div>
                                <div className='add-skill-box'>
                                    <div className='up-label'>
                                        Add your Skills
                                    </div>
                                    <div className='down-skills'>
                                        <Form.Control
                                            placeholder='Write a Skill, then press Enter...'
                                            onKeyDown={handleCreateSkillKeyDown}
                                            value={skillsInput}
                                            onChange={handleCreateSkillsInputChange}
                                        />
                                        <div className='skills-container'>
                                            {
                                                createProfileData?.skills.split(';').slice(0, createProfileData?.skills.split(';').length - 1).map((skill, index) => (
                                                    <div key={index} className='single-skill'>
                                                        {skill}
                                                        <MdClose
                                                            className='close-icon'
                                                            onClick={() => handleCreateRemoveSkill(index)}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Min Budget *
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Ex. 1,000'
                                            value={createProfileData?.minBudget}
                                            onChange={handleCreateMinBudgetChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Twitter Profile Link *
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={createProfileData?.twitter}
                                            onChange={handleCreateTwitterChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Discord Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={createProfileData?.discord}
                                            onChange={handleCreateDiscordChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Linkedin Profile Link *
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={createProfileData?.linkedin}
                                            onChange={handleCreateLinkedinChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Behance Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={createProfileData?.behance}
                                            onChange={handleCreateBehanceChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Github Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={createProfileData?.github}
                                            onChange={handleCreateGithubChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Medium Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={createProfileData?.medium}
                                            onChange={handleCreateMediumChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Pinterest Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={createProfileData?.pinterest}
                                            onChange={handleCreatePinterestChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        Facebook Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={createProfileData?.facebook}
                                            onChange={handleCreateFacebookChange}
                                        />
                                    </div>
                                </div>
                                <div className='single-edit-box'>
                                    <div className='up-label'>
                                        YouTube Profile Link
                                    </div>
                                    <div className='down-input'>
                                        <Form.Control
                                            placeholder='Add Link...'
                                            value={createProfileData?.youtube}
                                            onChange={handleCreateYoutubeChange}
                                        />
                                    </div>
                                </div>
                                <div className='add-experience-box'>
                                    <div className='up-label'>
                                        Work Experience
                                    </div>
                                    <div className='down-experience-container'>
                                        {
                                            createProfileData?.experience.map((experience, index) => {
                                                return (
                                                    <div key={index} className='single-experience-box'>
                                                        <div className='up-title'>
                                                            <div className='left-job-title'>
                                                                {experience.split(';')[0]}
                                                            </div>
                                                            <div className='right-job-type'>
                                                                {experience.split(';')[1]}
                                                            </div>
                                                        </div>
                                                        <div className='down-experience'>
                                                            <div className='left-company-title'>
                                                                {experience.split(';')[2]}
                                                            </div>
                                                            <div className='right-experience-period'>
                                                                {
                                                                    new Date(experience.split(';')[3]).toDateString()
                                                                    + ' - ' +
                                                                    new Date(experience.split(';')[4]).toDateString()
                                                                }
                                                            </div>
                                                        </div>
                                                        <Divider className='bottom-divider' />
                                                        <div className='remove-container'>
                                                            <HiTrash
                                                                className='remove-icon'
                                                                onClick={() => handleCreateRemoveWorkExperience(index)}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    <div className='new-experience-box row'>
                                        <div className='single-left-box col-sm-12 col-md-12 col-lg-6'>
                                            <div className='up-label'>
                                                Job Position
                                            </div>
                                            <div className='down-input'>
                                                <Form.Control
                                                    placeholder='Write Here'
                                                    value={newExperienceJobPosition}
                                                    onChange={handleNewJobPositionChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='single-right-box col-sm-12 col-md-12 col-lg-6'>
                                            <div className='up-label'>
                                                Project Name
                                            </div>
                                            <div className='down-input'>
                                                <Form.Control
                                                    placeholder='Write Here'
                                                    value={newExperienceProjectName}
                                                    onChange={handleNewProjectNameChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='single-left-box col-sm-12 col-md-12 col-lg-6'>
                                            <div className='up-label'>
                                                Starting Date
                                            </div>
                                            <div className='down-input'>
                                                <Form.Control
                                                    placeholder='Month/Day/Year'
                                                    value={newExperienceStartingDate}
                                                    onChange={handleNewStartingDateChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='single-right-box col-sm-12 col-md-12 col-lg-6'>
                                            <div className='up-label'>
                                                Ending Date
                                            </div>
                                            <div className='down-input'>
                                                <Form.Control
                                                    placeholder='Month/Day/Year'
                                                    value={newExperienceEndingDate}
                                                    onChange={handleNewEndingDateChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='select-job-type col-sm-12 col-md-12 col-lg-6'>
                                            <div
                                                className={`single-type-box ${newExperienceJobType === 'Full Time' ? 'active' : ''}`}
                                                onClick={() => handleNewJobType('Full Time')}
                                            >
                                                Full Time
                                            </div>
                                            <div
                                                className={`single-type-box ${newExperienceJobType === 'Part Time' ? 'active' : ''}`}
                                                onClick={() => handleNewJobType('Part Time')}
                                            >
                                                Part Time
                                            </div>
                                        </div>
                                        <div className='submit-container col-sm-12 col-md-12 col-lg-6'>
                                            <div
                                                className='submit-btn'
                                                onClick={handleCreateSubmitNewExperience}
                                            >
                                                Add Work Experience
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='discard-save-container'>
                                    <div className='save-container'>
                                        <div
                                            className='save-btn active'
                                            onClick={
                                                () => {
                                                    handleSubmitNewProfile();
                                                    setTransactionType('create-profile');
                                                }
                                            }
                                        >
                                            Submit
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};