import React, { useContext, useEffect } from 'react';
import ReactGA from 'react-ga';

import { useNavigate } from 'react-router-dom';
import { FaTwitter } from 'react-icons/fa';
import { ArticleContext } from 'App';
import './index.scss';

interface IArticleInfo {
    articleId: number;
    coverImage: string;
    title: string;
    twitter: string;
    authorName: string;
    content: string;
    submitter: string;
    postedTime: number;
}

const regex = /(<([^>]+)>)/gi;

export const Avenue = () => {

    const navigate = useNavigate();
    const articles = useContext(ArticleContext);

    const handleArticleDetailView = (articleId: number) => {
        window.scrollTo(0, 0);
        navigate(`/article-view/${articleId}`, { replace: true });
    };

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className='xemployed-avenue'>
            <div className='top-avenue-title'>
                <div className='title-effect'>
                    <p> MEET &nbsp;
                        <span>
                            OUR SPOTLIGHT
                        </span> &nbsp;
                        CREATORS
                    </p>
                </div>
            </div>
            <div className='bottom-all-articles row'>
                {
                    articles.map((article: IArticleInfo, index: number) => {
                        return (
                            <div key={index} className='single-article-container col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                                <img src={article.coverImage} />
                                <div className='top-article-title'>
                                    {article.title}
                                </div>
                                <div className='middle-article-author'>
                                    Written By &nbsp;
                                    <strong>{article.authorName}</strong> &nbsp;
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href={article?.twitter}
                                    >
                                        <FaTwitter className='twitter-icon' />
                                    </a>
                                </div>
                                <div className='bottom-article-content'>
                                    {article.content.replace(regex, '').replace('&nbsp', '\n').slice(0, 300) + '...'}
                                </div>
                                <div
                                    className='read-more-btn'
                                    onClick={() => handleArticleDetailView(article.articleId)}
                                >
                                    Read More ...
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};