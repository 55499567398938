import axios from 'axios';
import swal from 'sweetalert';

export const getBlxcNftCount = async (address: string) => {
    try {
        const res: any = await axios.get(`https://api.multiversx.com/accounts/${address}/nfts/count?collection=BLXC-4311ab`);
        if (res.status == 200) {
            return res.data;
        } else {
            console.log('please insert correct token id');
            return 0;
        }
    } catch (error: any) {
        console.log(error.response.data.message);
    }
};

export const getTycheNftCount = async (address: string) => {
    try {
        const res: any = await axios.get(`https://api.multiversx.com/accounts/${address}/nfts/count?collection=TYCH-d7e96b`);
        if (res.status == 200) {
            return res.data;
        } else {
            console.log('please insert correct token id');
            return 0;
        }
    } catch (error: any) {
        console.log(error.response.data.message);
    }
};
