import React, { useState, useContext, useEffect } from 'react';
import { useGetIsLoggedIn, useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

import { FaTwitter, FaChevronDown, FaDiscord, FaCrown } from 'react-icons/fa';
import { HiHandThumbUp, HiCurrencyDollar } from 'react-icons/hi2';
import { GoPlus } from 'react-icons/go';
import { MdGroup, MdAccessTimeFilled } from 'react-icons/md';
import swal from 'sweetalert';

import { NavDropdown } from 'react-bootstrap';
import { departmentType } from 'mockdata';
import { SelectedAccountMenuContext, JobContext } from 'App';
import './index.scss';

interface IJobInfo {
    jobId: number;
    companyName: string;
    companyLogo: string;
    jobDescription: string;
    jobTitle: string;
    salary: number;
    department: number;
    jobType: string;
    twitter: string;
    discord: string;
    featured: boolean;
    creator: string;
    createdTime: number;
}

export const Employ = () => {

    const navigate = useNavigate();
    const isLoggedIn = useGetIsLoggedIn();

    const [departmentFilter, setDepartmentFilter] = useState(0);
    const [dateFilter, setDateFilter] = useState(0);
    const { setSelectedAccountMenu } = useContext(SelectedAccountMenuContext);
    const jobs = useContext(JobContext);
    const [filteredJobs, setFilteredJobs] = useState<IJobInfo[]>([]);

    const handleSelectDepartmentFilter = (filterType: number) => {
        setDepartmentFilter(filterType);
        const tempJobs = jobs.filter((item: IJobInfo) => item.department === filterType);
        setFilteredJobs(tempJobs);
    };

    const handleSelectDateFilter = (filterType: number) => {
        setDateFilter(filterType);
    };

    const handleCreateNewJob = () => {
        if (isLoggedIn) {
            window.scrollTo(0, 0);
            setSelectedAccountMenu(2);
            navigate('/account', { replace: true });
        } else {
            swal('Oops', 'You have to connect your wallet first!', 'error');
        }
    };

    const handleViewJobDetail = (jobId: number) => {
        window.scrollTo(0, 0);
        navigate(`/job-view/${jobId}`, { replace: true });
    };

    useEffect(() => {
        setFilteredJobs(jobs);
    }, [jobs]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className='xemployed-employ'>
            <div className='top-employ-title'>
                <div className='title-effect'>
                    <p> FIND &nbsp;
                        <span>
                            THE BEST &nbsp;
                        </span>
                        JOB &nbsp;
                        <span> FOR YOUR </span> &nbsp;
                        SKILLS
                    </p>
                </div>
            </div>
            <div className='middle-filter-bar'>
                <div className='inner-filter-bar row'>
                    <div className='left-department-filter col-sm-12 col-md-12 col-lg-5'>
                        <p> Department </p>
                        <NavDropdown
                            className='filter-dropdown'
                            title={
                                <div className='filter-by-options'>
                                    {
                                        departmentFilter === 0 ? (
                                            <div className='selected-blank-options'>
                                                No Filter
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 1 ? (
                                            <div className='selected-options'>
                                                Graphic & Design
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 2 ? (
                                            <div className='selected-options'>
                                                Digital Marketing
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 3 ? (
                                            <div className='selected-options'>
                                                Writing & Translation
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 4 ? (
                                            <div className='selected-options'>
                                                Video & Animation
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 5 ? (
                                            <div className='selected-options'>
                                                Music & Audio
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 6 ? (
                                            <div className='selected-options'>
                                                Programming & Tech
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 7 ? (
                                            <div className='selected-options'>
                                                Business
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 8 ? (
                                            <div className='selected-options'>
                                                LifeStyle
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 9 ? (
                                            <div className='selected-options'>
                                                AI Services
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    <FaChevronDown className='filter-down-icon' />
                                </div>
                            }
                        >
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(1)}>
                                <div className='filter-menu-dropdown'>
                                    Graphic & Design
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(2)}>
                                <div className='filter-menu-dropdown'>
                                    Digital Marketing
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(3)}>
                                <div className='filter-menu-dropdown'>
                                    Writing & Translation
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(4)}>
                                <div className='filter-menu-dropdown'>
                                    Video & Animation
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(5)}>
                                <div className='filter-menu-dropdown'>
                                    Music & Audio
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(6)}>
                                <div className='filter-menu-dropdown'>
                                    Programming & Tech
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(7)}>
                                <div className='filter-menu-dropdown'>
                                    Business
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(8)}>
                                <div className='filter-menu-dropdown'>
                                    LifeStyle
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(9)}>
                                <div className='filter-menu-dropdown'>
                                    AI Services
                                </div>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>
                    <div className='right-date-filter col-sm-12 col-md-12 col-lg-5'>
                        <p> Date </p>
                        <NavDropdown
                            className='filter-dropdown'
                            title={
                                <div className='filter-by-options'>
                                    {
                                        dateFilter === 0 ? (
                                            <div className='selected-blank-options'>
                                                No Filter
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        dateFilter === 1 ? (
                                            <div className='selected-options'>
                                                Recent
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        dateFilter === 2 ? (
                                            <div className='selected-options'>
                                                Older
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    <FaChevronDown className='filter-down-icon' />
                                </div>
                            }
                        >
                            <NavDropdown.Item onClick={() => handleSelectDateFilter(1)}>
                                <div className='filter-menu-dropdown'>
                                    Recent
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDateFilter(2)}>
                                <div className='filter-menu-dropdown'>
                                    Older
                                </div>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>
                    <div className='post-job col-sm-12 col-md-12 col-lg-2'>
                        <p>
                            <HiHandThumbUp className='crown-icon' />
                            Post Job
                        </p>
                        <div
                            className='post-btn-container'
                            onClick={handleCreateNewJob}
                        >
                            <div className='plus-btn'>
                                <GoPlus className='plus-icon' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottom-all-jobs row'>
                {
                    filteredJobs?.filter((pItem: IJobInfo) => pItem.featured).map((job: IJobInfo, index: number) => {
                        return (
                            <div key={index} className='single-job-container col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                                {
                                    job.featured ? (
                                        <div className='featured-container'>
                                            <FaCrown className='crown-icon' />
                                            FEATURED
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }
                                <div className='job-info'>
                                    <div className='job-primary-info'>
                                        <div className='left-info'>
                                            <div className='poster-address'>
                                                {job.creator.slice(0, 20) + '...'}
                                            </div>
                                            <div className='job-title'>
                                                {job.jobTitle}
                                            </div>
                                            <div className='job-department'>
                                                {departmentType[job.department - 1]}
                                            </div>
                                        </div>
                                        <div className='right-info'>
                                            <div className='logo-social-container'>
                                                <div className='poster-socials'>
                                                    <a
                                                        target='_blank'
                                                        rel='noreferrer'
                                                        href={job.discord}
                                                    >
                                                        <FaDiscord className='social-icon' />
                                                    </a>
                                                    <a
                                                        target='_blank'
                                                        rel='noreferrer'
                                                        href={job.twitter}
                                                    >
                                                        <FaTwitter className='social-icon' />
                                                    </a>
                                                </div>
                                                <div className='poster-logo'>
                                                    <div className='hexagon hexagon-div'>
                                                        <div className='hexagon-in-one'>
                                                            <div
                                                                className='hexagon-in-two'
                                                                style={{
                                                                    backgroundImage: `url(${job.companyLogo})`
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='created-time'>
                                                {new Date(job.createdTime * 1000).toLocaleDateString()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='job-main-info'>
                                        <div className='job-salary'>
                                            <HiCurrencyDollar className='salary-icon' />
                                            {job.salary + ' USD / Month'}
                                        </div>
                                        <div className='job-poster'>
                                            <MdGroup className='poster-icon' />
                                            {job.companyName}
                                        </div>
                                        <div className='job-description'>
                                            {job.jobDescription}
                                        </div>
                                        <div className='bottom-control'>
                                            <div className='job-type'>
                                                <MdAccessTimeFilled className='time-icon' />
                                                {job.jobType}
                                            </div>
                                            <div
                                                className='view-detail'
                                                onClick={() => handleViewJobDetail(job.jobId)}
                                            >
                                                View Detail ...
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                {
                    jobs?.filter((pItem: IJobInfo) => pItem.featured === false).map((job: IJobInfo, index: number) => {
                        return (
                            <div key={index} className='single-job-container col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                                {
                                    job.featured ? (
                                        <div className='featured-container'>
                                            <FaCrown className='crown-icon' />
                                            FEATURED
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }
                                <div className='job-info'>
                                    <div className='job-primary-info'>
                                        <div className='left-info'>
                                            <div className='poster-address'>
                                                {job.creator.slice(0, 20) + '...'}
                                            </div>
                                            <div className='job-title'>
                                                {job.jobTitle}
                                            </div>
                                            <div className='job-department'>
                                                {departmentType[job.department - 1]}
                                            </div>
                                        </div>
                                        <div className='right-info'>
                                            <div className='logo-social-container'>
                                                <div className='poster-socials'>
                                                    <a
                                                        target='_blank'
                                                        rel='noreferrer'
                                                        href={job.discord}
                                                    >
                                                        <FaDiscord className='social-icon' />
                                                    </a>
                                                    <a
                                                        target='_blank'
                                                        rel='noreferrer'
                                                        href={job.twitter}
                                                    >
                                                        <FaTwitter className='social-icon' />
                                                    </a>
                                                </div>
                                                <div className='poster-logo'>
                                                    <div className='hexagon hexagon-div'>
                                                        <div className='hexagon-in-one'>
                                                            <div
                                                                className='hexagon-in-two'
                                                                style={{
                                                                    backgroundImage: `url(${job.companyLogo})`
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='created-time'>
                                                {new Date(job.createdTime * 1000).toLocaleDateString()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='job-main-info'>
                                        <div className='job-salary'>
                                            <HiCurrencyDollar className='salary-icon' />
                                            {job.salary + ' USD / Month'}
                                        </div>
                                        <div className='job-poster'>
                                            <MdGroup className='poster-icon' />
                                            {job.companyName}
                                        </div>
                                        <div className='job-description'>
                                            {job.jobDescription}
                                        </div>
                                        <div className='bottom-control'>
                                            <div className='job-type'>
                                                <MdAccessTimeFilled className='time-icon' />
                                                {job.jobType}
                                            </div>
                                            <div
                                                className='view-detail'
                                                onClick={() => handleViewJobDetail(job.jobId)}
                                            >
                                                View Detail ...
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};