import { RouteType } from '@multiversx/sdk-dapp/types';
import { dAppName } from 'config';
import { withPageTitle } from './components/PageTitle';

import { Account, Dashboard, Hire, Employ, Avenue, Home, Teams, ProfileView, ArticleView, JobView } from './pages';

export const routeNames = {
  home: '/',
  dashboard: '/dashboard',
  unlock: '/unlock',
  hire: '/hire',
  employ: '/employ',
  avenue: '/avenue',
  teams: '/teams',
  profileView: '/profile-view/:profile_id',
  articleView: '/article-view/:article_id',
  jobView: '/job-view/:job_id',
  account: '/account',
};

interface RouteWithTitleType extends RouteType {
  title: string;
}

export const routes: RouteWithTitleType[] = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.dashboard,
    title: 'Dashboard',
    component: Dashboard,
    authenticatedRoute: true
  },
  {
    path: routeNames.hire,
    title: 'Hire',
    component: Hire,
    authenticatedRoute: false
  },
  {
    path: routeNames.employ,
    title: 'Employ',
    component: Employ,
    authenticatedRoute: false
  },
  {
    path: routeNames.avenue,
    title: 'Avenue',
    component: Avenue,
    authenticatedRoute: false,
  },
  {
    path: routeNames.teams,
    title: 'Teams',
    component: Teams,
    authenticatedRoute: false,
  },
  {
    path: routeNames.profileView,
    title: 'Profile View',
    component: ProfileView,
    authenticatedRoute: false
  },
  {
    path: routeNames.articleView,
    title: 'Article View',
    component: ArticleView,
    authenticatedRoute: false
  },
  {
    path: routeNames.jobView,
    title: 'Job View',
    component: JobView,
    authenticatedRoute: false
  },
  {
    path: routeNames.account,
    title: 'Account',
    component: Account,
    authenticatedRoute: true
  }
];

export const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • MultiversX ${dAppName}`
    : `MultiversX ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});
