import React, { useState, useContext, useEffect } from 'react';
import { useGetIsLoggedIn, useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { useNavigate } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { FaTwitter, FaLinkedinIn, FaChevronDown, FaCrown } from 'react-icons/fa';
import { GoPlus } from 'react-icons/go';
import ReactGA from 'react-ga';

import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import HexagonIcon from '@mui/icons-material/Hexagon';

import { styled } from '@mui/material/styles';
import { Rating } from '@mui/material';

import HireMeActive from 'assets/img/hire-me-active.svg';
import { departmentType } from 'mockdata';
import { SelectedAccountMenuContext, ProfileContext, ReviewContext } from 'App';

import swal from 'sweetalert';
import './index.scss';

interface IReviewInfo {
    reviewId: number;
    receiverAddress: string;
    reviewerAddress: string;
    jobTitle: string;
    reviewDetail: string;
    reviewPoint: number;
}
interface ITalentInfo {
    profileId: number;
    firstName: string;
    lastName: string;
    summary: string;
    avatar: string;
    jobTitle: string;
    department: number;
    openToWork: boolean;
    lastPaidTime: number;
    minBudget: number;
    skills: string;
    twitter: string;
    discord: string;
    linkedin: string;
    behance: string;
    github: string;
    medium: string;
    pinterest: string;
    facebook: string;
    youtube: string;
    experience: string[];
    creator: string;
    createdTime: number;
}

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
        color: '#ff3d47',
    },
});

export const Hire = () => {

    const navigate = useNavigate();
    const isLoggedIn = useGetIsLoggedIn();
    const { address } = useGetAccountInfo();

    const profiles = useContext(ProfileContext);
    const reviews = useContext(ReviewContext);

    const [filteredProfiles, setFilteredProfiles] = useState<ITalentInfo[]>([]);
    const [departmentFilter, setDepartmentFilter] = useState(0);
    const [reviewsFilter, setReviewsFilter] = useState(0);
    const { setSelectedAccountMenu } = useContext(SelectedAccountMenuContext);

    const handleSelectDepartmentFilter = (filterType: number) => {
        setDepartmentFilter(filterType);
        const tempProfiles = profiles.filter((item: ITalentInfo) => item.department === filterType);
        setFilteredProfiles(tempProfiles);
    };

    const handleSelectReviewsFilter = (filterType: number) => {
        setReviewsFilter(filterType);
    };

    const handleSingleProfile = (profileId: number) => {
        window.scrollTo(0, 0);
        navigate(`/profile-view/${profileId}`, { replace: true });
    };

    const handleCreateNewProfile = () => {
        if (isLoggedIn) {
            if (profiles.filter((item: ITalentInfo) => item.creator === address)[0]) {
                swal('Oops', 'You already have a profile!', 'error');
            } else {
                window.scrollTo(0, 0);
                setSelectedAccountMenu(0);
                navigate('/account', { replace: true });
            }
        } else {
            swal('Oops', 'You have to connect your wallet first!', 'error');
        }
    };

    useEffect(() => {
        setFilteredProfiles(profiles);
    }, [profiles]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    
    return (
        <div className='xemployed-hire'>
            <div className='top-hire-title'>
                <div className='title-effect'>
                    <p> FIND &nbsp;
                        <span>
                            THE BEST SKILLS FOR YOUR
                        </span> &nbsp;
                        WEB3 PROJECT
                    </p>
                </div>
            </div>
            <div className='middle-filter-bar'>
                <div className='inner-filter-bar row'>
                    <div className='left-department-filter col-sm-12 col-md-12 col-lg-5'>
                        <p> Department </p>
                        <NavDropdown
                            className='filter-dropdown'
                            title={
                                <div className='filter-by-options'>
                                    {
                                        departmentFilter === 0 ? (
                                            <div className='selected-blank-options'>
                                                No Filter
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 1 ? (
                                            <div className='selected-options'>
                                                Graphic & Design
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 2 ? (
                                            <div className='selected-options'>
                                                Digital Marketing
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 3 ? (
                                            <div className='selected-options'>
                                                Writing & Translation
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 4 ? (
                                            <div className='selected-options'>
                                                Video & Animation
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 5 ? (
                                            <div className='selected-options'>
                                                Music & Audio
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 6 ? (
                                            <div className='selected-options'>
                                                Programming & Tech
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 7 ? (
                                            <div className='selected-options'>
                                                Business
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 8 ? (
                                            <div className='selected-options'>
                                                LifeStyle
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        departmentFilter === 9 ? (
                                            <div className='selected-options'>
                                                AI Services
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    <FaChevronDown className='filter-down-icon' />
                                </div>
                            }
                        >
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(1)}>
                                <div className='filter-menu-dropdown'>
                                    Graphic & Design
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(2)}>
                                <div className='filter-menu-dropdown'>
                                    Digital Marketing
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(3)}>
                                <div className='filter-menu-dropdown'>
                                    Writing & Translation
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(4)}>
                                <div className='filter-menu-dropdown'>
                                    Video & Animation
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(5)}>
                                <div className='filter-menu-dropdown'>
                                    Music & Audio
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(6)}>
                                <div className='filter-menu-dropdown'>
                                    Programming & Tech
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(7)}>
                                <div className='filter-menu-dropdown'>
                                    Business
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(8)}>
                                <div className='filter-menu-dropdown'>
                                    LifeStyle
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectDepartmentFilter(9)}>
                                <div className='filter-menu-dropdown'>
                                    AI Services
                                </div>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>
                    <div className='right-reviews-filter col-sm-12 col-md-12 col-lg-5'>
                        <p> Reviews </p>
                        <NavDropdown
                            className='filter-dropdown'
                            title={
                                <div className='filter-by-options'>
                                    {
                                        reviewsFilter === 0 ? (
                                            <div className='selected-blank-options'>
                                                No Filter
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        reviewsFilter === 1 ? (
                                            <div className='selected-options'>
                                                1 HeXs
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        reviewsFilter === 2 ? (
                                            <div className='selected-options'>
                                                2 HeXs
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        reviewsFilter === 3 ? (
                                            <div className='selected-options'>
                                                3 HeXs
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        reviewsFilter === 4 ? (
                                            <div className='selected-options'>
                                                4 HeXs
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        reviewsFilter === 5 ? (
                                            <div className='selected-options'>
                                                5 HeXs
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    <FaChevronDown className='filter-down-icon' />
                                </div>
                            }
                        >
                            <NavDropdown.Item onClick={() => handleSelectReviewsFilter(1)}>
                                <div className='filter-menu-dropdown'>
                                    1 HeXs
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectReviewsFilter(2)}>
                                <div className='filter-menu-dropdown'>
                                    2 HeXs
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectReviewsFilter(3)}>
                                <div className='filter-menu-dropdown'>
                                    3 HeXs
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectReviewsFilter(4)}>
                                <div className='filter-menu-dropdown'>
                                    4 HeXs
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleSelectReviewsFilter(5)}>
                                <div className='filter-menu-dropdown'>
                                    5 HeXs
                                </div>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>
                    <div className='post-service col-sm-12 col-md-12 col-lg-2'>
                        <p>
                            <FaCrown className='crown-icon' />
                            Post Service
                        </p>
                        <div
                            className='post-btn-container'
                            onClick={handleCreateNewProfile}
                        >
                            <div className='plus-btn'>
                                <GoPlus className='plus-icon' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottom-all-talents row'>
                {
                    filteredProfiles.map((talent: ITalentInfo, index: number) => {

                        const talentReviews = reviews.filter((review: IReviewInfo) => review.receiverAddress === talent.creator);
                        let reviewPointSum = 0;
                        talentReviews.map((review: IReviewInfo) => {
                            reviewPointSum += review.reviewPoint;
                        });
                        const averagePoint = reviewPointSum / talentReviews.length;
                        // console.log('average', averagePoint);

                        return (
                            <div key={index} className='single-talent-container col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                                <div
                                    className='talent-info row'
                                    onClick={() => handleSingleProfile(talent.profileId)}
                                >
                                    <div className='left-avatar col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-5'>
                                        <img src={talent.avatar} />
                                        {
                                            talent.openToWork === true ? (
                                                <img src={HireMeActive} className='hire-me-badge' />
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </div>
                                    <div className='right-main-info col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-7'>
                                        <div className='talent-name'>
                                            <p>
                                                <span>
                                                    {talent.firstName.toUpperCase()}
                                                </span> &nbsp;
                                                {talent.lastName.toUpperCase()}
                                            </p>
                                        </div>
                                        <div className='talent-job-title'>
                                            {talent.jobTitle}
                                        </div>
                                        <div className='talent-review-count'>
                                            <StyledRating
                                                defaultValue={averagePoint}
                                                precision={0.1}
                                                icon={<HexagonIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                                emptyIcon={<HexagonOutlinedIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                                readOnly
                                            />
                                        </div>
                                        <div className='talent-department'>
                                            <p> Department </p>
                                            <span>
                                                {departmentType[talent.department - 1]}
                                            </span>
                                        </div>
                                        <div className='talent-social'>
                                            <div className='social-container'>
                                                <a
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={talent.twitter}
                                                >
                                                    <FaTwitter className='twitter-icon' />
                                                </a>
                                                <a
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={talent.linkedin}
                                                >
                                                    <FaLinkedinIn className='linkedin-icon' />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};