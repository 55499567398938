import React, { useState, useContext, useEffect } from 'react';
import ReactGA from 'react-ga';

import { FaUserAlt } from 'react-icons/fa';
import { AiFillMessage } from 'react-icons/ai';
import { RiMagicFill, RiTeamFill, RiArticleFill } from 'react-icons/ri';
import { SelectedAccountMenuContext } from 'App';
import { ProfileEdit, Proposals, PostJob, CreateTeam, PostArticle } from 'components/AccountItem';
import './index.scss';

export const Account = () => {
    const { selectedAccountMenu, setSelectedAccountMenu } = useContext(SelectedAccountMenuContext);

    const handleSingleMenu = (menuItem: number) => {
        setSelectedAccountMenu(menuItem);
    };

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className='xemployed-account'>
            <div className='left-menu-bar'>
                <div
                    className={`single-account-menu ${selectedAccountMenu === 0 ? 'active' : ''}`}
                    onClick={() => handleSingleMenu(0)}
                >
                    <FaUserAlt className='menu-icon' />
                    Profile
                </div>
                <div
                    className={`single-account-menu ${selectedAccountMenu === 1 ? 'active' : ''}`}
                    onClick={() => handleSingleMenu(1)}
                >
                    <AiFillMessage className='menu-icon' />
                    Proposals
                </div>
                <div
                    className={`single-account-menu ${selectedAccountMenu === 2 ? 'active' : ''}`}
                    onClick={() => handleSingleMenu(2)}
                >
                    <RiMagicFill className='menu-icon' />
                    Post a Job
                </div>
                <div
                    className={`single-account-menu ${selectedAccountMenu === 3 ? 'active' : ''}`}
                    onClick={() => handleSingleMenu(3)}
                >
                    <RiTeamFill className='menu-icon' />
                    Create a Team
                </div>
                <div
                    className={`single-account-menu ${selectedAccountMenu === 4 ? 'active' : ''}`}
                    onClick={() => handleSingleMenu(4)}
                >
                    <RiArticleFill className='menu-icon' />
                    Post an Article
                </div>
            </div>
            <div className='right-menu-detail'>
                {
                    selectedAccountMenu === 0 ? (
                        <ProfileEdit />
                    ) : (
                        <></>
                    )
                }
                {
                    selectedAccountMenu === 1 ? (
                        <Proposals />
                    ) : (
                        <></>
                    )
                }
                {
                    selectedAccountMenu === 2 ? (
                        <PostJob />
                    ) : (
                        <></>
                    )
                }
                {
                    selectedAccountMenu === 3 ? (
                        <CreateTeam />
                    ) : (
                        <></>
                    )
                }
                {
                    selectedAccountMenu === 4 ? (
                        <PostArticle />
                    ) : (
                        <></>
                    )
                }
            </div>
        </div>
    );
};