import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { FaTwitter } from 'react-icons/fa';
import parse from 'html-react-parser';
import ReactGA from 'react-ga';

import { ArticleContext } from 'App';
import './index.scss';
interface IArticleInfo {
    articleId: number;
    coverImage: string;
    title: string;
    twitter: string;
    authorName: string;
    content: string;
    submitter: string;
    postedTime: number;
}

export const ArticleView = () => {

    const { article_id } = useParams();
    const navigate = useNavigate();

    const articles = useContext(ArticleContext);
    const [articleData, setArticleData] = useState<IArticleInfo | null>(null);

    const handleBackExplore = () => {
        navigate('/avenue', { replace: true });
    };

    useEffect(() => {
        setArticleData(articles?.filter((item: IArticleInfo) => item.articleId.toString() === article_id)[0]);
    }, [articles]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    
    return (
        <div className='article-view'>
            {
                articleData ? (
                    <div className='article-view-container'>
                        <div className='article-back'>
                            <div
                                className='left-go-back'
                                onClick={handleBackExplore}
                            >
                                <RiArrowGoBackLine />
                            </div>
                        </div>
                        <div className='article-info-container'>
                            <div className='top-article-title'>
                                {articleData.title.toUpperCase()}
                            </div>
                            <div className='bottom-writer'>
                                Written By &nbsp;
                                <strong>{articleData.authorName}</strong>
                            </div>
                            <div className='social-container'>
                                <a
                                    target='_blank'
                                    rel='noreferrer'
                                    href={articleData?.twitter}
                                >
                                    <FaTwitter className='twitter-social-icons' />
                                </a>
                            </div>
                            <div className='cover-image'>
                                <img src={articleData.coverImage} />
                            </div>
                        </div>
                        <div className='article-detail'>
                            {parse(articleData.content)}
                        </div>
                    </div>
                ) : (
                    <></>
                )
            }
        </div>
    );
};