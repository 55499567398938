import React, { useState, useEffect, useContext } from 'react';
import { Form, NavDropdown } from 'react-bootstrap';
import swal from 'sweetalert';
import { IoIosPeople } from 'react-icons/io';
import { FaPencilAlt } from 'react-icons/fa';

import { useGetAccountInfo, useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { ArgSerializer, BigUIntValue, BytesValue, TransactionPayload, U32Value, U64Value, TokenPayment } from '@multiversx/sdk-core/out';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions/useGetPendingTransactions';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import BigNumber from 'bignumber.js/bignumber.js';

import EmptyTeams from 'assets/img/empty-teams.svg';
import { CONTRACT_ADDRESS, TOKEN_IDENTIFIER } from 'config';
import { TeamContext, TransactionHappenContext, ProfileContext } from 'App';
import { disableEmojis, activeEmojis } from 'mockdata';
import './index.scss';

interface ITalentInfo {
    profileId: number;
    firstName: string;
    lastName: string;
    summary: string;
    avatar: string;
    jobTitle: string;
    department: number;
    openToWork: boolean;
    lastPaidTime: number;
    minBudget: number;
    skills: string;
    twitter: string;
    discord: string;
    linkedin: string;
    behance: string;
    github: string;
    medium: string;
    pinterest: string;
    facebook: string;
    youtube: string;
    experience: string[];
    creator: string;
    createdTime: number;
}
interface ITeamInfo {
    teamId: number;
    teamIcon: number;
    teamName: string;
    packageSize: number;
    emailContact: string;
    teamDescription: string;
    teamCreator: string;
    teamCreatedTime: number;
    teamMembers: number[];
}

interface ICreateTeamInfo {
    teamIcon: number;
    teamName: string;
    packageSize: number;
    emailContact: string;
    teamDescription: string;
    teamMembers: number[];
}

export const CreateTeam = () => {

    const teams = useContext(TeamContext);
    const profiles = useContext(ProfileContext);
    const { address } = useGetAccountInfo();

    const [myTeamData, setMyTeamData] = useState<ITeamInfo[]>();
    const [teamEventHandler, setTeamEventHandler] = useState(0);

    const { hasPendingTransactions } = useGetPendingTransactions();
    const { transactionHappen, setTransactionHappen } = useContext(TransactionHappenContext);
    const [transactionType, setTransactionType] = useState('');
    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({ transactionId: sessionId });

    const handleCreateNewTeam = () => {
        setTeamEventHandler(1);
    };

    const handleCancelCreateTeam = () => {
        setTeamEventHandler(0);
    };

    const handleEditExistingTeam = (teamId: number) => {
        setTeamEventHandler(2);
        setEditTeamInfo(teams.filter((team: ITeamInfo) => team.teamId === teamId)[0]);
    };

    const handleCancelEditTeam = () => {
        setTeamEventHandler(0);
    };

    const [editTeamInfo, setEditTeamInfo] = useState<ITeamInfo | null>(null);
    const [createTeamInfo, setCreateTeamInfo] = useState<ICreateTeamInfo>({
        teamIcon: 0,
        teamName: '',
        packageSize: 0,
        emailContact: '',
        teamDescription: '',
        teamMembers: [],
    });

    // edit team

    const handleEditSelectEmoji = (index: number) => {
        if (editTeamInfo !== null) {
            setEditTeamInfo({ ...editTeamInfo, teamIcon: index });
        }
    };

    const onEditChangeTeamName = (e: any) => {
        if (editTeamInfo !== null) {
            setEditTeamInfo({ ...editTeamInfo, teamName: e.target.value });
        }
    };

    const onEditChangePackagePrice = (e: any) => {
        if (editTeamInfo !== null) {
            setEditTeamInfo({ ...editTeamInfo, packageSize: e.target.value });
        }
    };

    const onEditChangeEmailContact = (e: any) => {
        if (editTeamInfo !== null) {
            setEditTeamInfo({ ...editTeamInfo, emailContact: e.target.value });
        }
    };

    const onEditChangeTeamDescription = (e: any) => {
        if (editTeamInfo !== null) {
            setEditTeamInfo({ ...editTeamInfo, teamDescription: e.target.value });
        }
    };

    const handleSubmitEditTeam = async () => {
        if (editTeamInfo !== null) {
            const args = [
                new U32Value(editTeamInfo.teamId),
                new U32Value(editTeamInfo.teamIcon),
                BytesValue.fromUTF8(editTeamInfo.teamName),
                new U64Value(editTeamInfo.packageSize),
                BytesValue.fromUTF8(editTeamInfo.emailContact),
                BytesValue.fromUTF8(editTeamInfo.teamDescription),
            ];

            const { argumentsString } = new ArgSerializer().valuesToString(args);
            const data = new TransactionPayload(`updateTeam@${argumentsString}`);

            const gas = (55000000 + editTeamInfo.teamDescription.length * 1500) > 600000000 ?
                600000000 : (55000000 + editTeamInfo.teamDescription.length * 1500);

            const tx = {
                value: 0,
                receiver: CONTRACT_ADDRESS,
                data: data.toString(),
                gasLimit: gas,
            };
            await refreshAccount();
            const result = await sendTransactions({
                transactions: tx,
            });

            setSessionId(result.sessionId);
        }
    };

    // create team 

    const handleSelectEmoji = (index: number) => {
        setCreateTeamInfo({ ...createTeamInfo, teamIcon: index });
    };

    const onChangeTeamName = (e: any) => {
        setCreateTeamInfo({ ...createTeamInfo, teamName: e.target.value });
    };

    const onChangePackagePrice = (e: any) => {
        setCreateTeamInfo({ ...createTeamInfo, packageSize: e.target.value });
    };

    const onChangeEmailContact = (e: any) => {
        setCreateTeamInfo({ ...createTeamInfo, emailContact: e.target.value });
    };

    const onChangeTeamDescription = (e: any) => {
        setCreateTeamInfo({ ...createTeamInfo, teamDescription: e.target.value });
    };

    const handleSubmitNewTeam = async () => {

        const value = new BigNumber(300).multipliedBy(
            Math.pow(10, 6)
        );

        const args = [
            BytesValue.fromUTF8(TOKEN_IDENTIFIER),
            new BigUIntValue(TokenPayment.fungibleFromBigInteger(TOKEN_IDENTIFIER, value, 6).valueOf()),
            BytesValue.fromUTF8('createNewTeam'),
            new U32Value(createTeamInfo.teamIcon),
            BytesValue.fromUTF8(createTeamInfo.teamName),
            new U64Value(createTeamInfo.packageSize),
            BytesValue.fromUTF8(createTeamInfo.emailContact),
            BytesValue.fromUTF8(createTeamInfo.teamDescription),
        ];

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);
        const gas = (55000000 + createTeamInfo.teamDescription.length * 1500) > 600000000 ? 600000000 : (55000000 + createTeamInfo.teamDescription.length * 1500);

        const tx = {
            value: 0,
            receiver: CONTRACT_ADDRESS,
            data: data.toString(),
            gasLimit: gas,
        };

        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        setSessionId(result.sessionId);
    };

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            if (transactionType === 'create-team') {
                setTransactionHappen(!transactionHappen);
                setTeamEventHandler(0);
                swal('Success', 'You successfully create a new team!', 'success');
            } else if (transactionType === 'edit-team') {
                setTransactionHappen(!transactionHappen);
                setTeamEventHandler(0);
                swal('Success', 'You successfully update team information!', 'success');
            }
        }
    }, [sessionId, hasPendingTransactions]);

    useEffect(() => {
        setMyTeamData(teams.filter((item: ITeamInfo) => item.teamCreator === address));
    }, []);

    return (
        <div className='create-team'>
            <div className='top-already-existing'>
                <div className='posted-label'>
                    My Teams
                </div>
                {
                    myTeamData?.length === 0 ? (
                        <div className='posted-teams-empty'>
                            <img src={EmptyTeams} />
                            <p> No Team Yet... </p>
                        </div>
                    ) : (
                        <div className='posted-teams-container'>
                            {
                                myTeamData?.map((teamItem: ITeamInfo, index: number) => {
                                    return (
                                        <div key={index} className='single-team'>
                                            <div className='top-info'>
                                                <div className='primary-info row'>
                                                    <div className='left-icon-title col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                                                        <img src={disableEmojis[teamItem.teamIcon]} />
                                                        <div className='title-container'>
                                                            <div className='up-title'>
                                                                <strong>{teamItem.teamName}</strong>
                                                            </div>
                                                            <div className='down-package'>
                                                                Package Price &nbsp;
                                                                <strong>{teamItem.packageSize}</strong> USD
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='right-description col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                                                        {teamItem.teamDescription}
                                                    </div>
                                                </div>
                                                <div className='edit-container'>
                                                    <div
                                                        className='edit-btn'
                                                        onClick={() => handleEditExistingTeam(teamItem.teamId)}
                                                    >
                                                        <div className='icon-container'>
                                                            <FaPencilAlt />
                                                        </div>
                                                        <div className='edit-text'>
                                                            Edit
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='team-members'>
                                                {
                                                    teamItem.teamMembers.length === 0 ? (
                                                        <div className='empty-members'>
                                                            <IoIosPeople className='member-icon' />
                                                            No Members Yet ...
                                                        </div>
                                                    ) : (
                                                        <div className='members-container'>
                                                            {
                                                                teamItem.teamMembers.map((member: any, memberIndex: number) => {
                                                                    const talent: ITalentInfo = profiles.filter((profileItem: ITalentInfo) => profileItem.profileId === member.toNumber())[0];

                                                                    return (
                                                                        <div key={memberIndex} className='single-team-member'>
                                                                            <div className='img-container'>
                                                                                <img src={talent.avatar} />
                                                                            </div>
                                                                            <div className='member-info'>
                                                                                {talent.firstName + ' ' + talent.lastName}
                                                                            </div>
                                                                            <div className='member-info'>
                                                                                |
                                                                            </div>
                                                                            <div className='member-info'>
                                                                                {talent.jobTitle}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                }
            </div>
            {
                teamEventHandler === 0 ? (
                    <div className='new-team-container'>
                        <div
                            className='new-team-btn'
                            onClick={handleCreateNewTeam}
                        >
                            Create New Team
                        </div>
                    </div>
                ) : (
                    <div className='team-detail-container'>
                        {
                            teamEventHandler === 1 ? (
                                <div className='create-new-team'>
                                    <div className='choose-xmoji'>
                                        <div className='top-title'>
                                            Choose an xMoji
                                        </div>
                                        <div className='xmoji-container row'>
                                            {
                                                disableEmojis.map((emojiItem: any, index: number) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className='single-emoji col-3 col-sm-2 col-md-3 col-lg-2 col-xl-1'
                                                            onClick={() => handleSelectEmoji(index)}
                                                        >
                                                            {
                                                                createTeamInfo.teamIcon === index ? <img src={activeEmojis[index]} /> : <img src={emojiItem} />
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='single-input-container'>
                                        <div className='up-title'>
                                            Name of the Team
                                        </div>
                                        <div className='down-input'>
                                            <Form.Control
                                                placeholder='Write your team name...'
                                                value={createTeamInfo.teamName}
                                                onChange={onChangeTeamName}
                                            />
                                        </div>
                                    </div>
                                    <div className='single-input-container'>
                                        <div className='up-title'>
                                            Package Price
                                        </div>
                                        <div className='down-input'>
                                            <Form.Control
                                                placeholder='Write package price...'
                                                value={createTeamInfo.packageSize}
                                                onChange={onChangePackagePrice}
                                            />
                                        </div>
                                    </div>
                                    <div className='single-input-container'>
                                        <div className='up-title'>
                                            Email Contact
                                        </div>
                                        <div className='down-input'>
                                            <Form.Control
                                                placeholder='Write team contact...'
                                                value={createTeamInfo.emailContact}
                                                onChange={onChangeEmailContact}
                                            />
                                        </div>
                                    </div>
                                    <div className='single-input-container'>
                                        <div className='up-title'>
                                            Team Description
                                        </div>
                                        <div className='down-input'>
                                            <Form.Control as='textarea'
                                                placeholder='Write team description...'
                                                value={createTeamInfo.teamDescription}
                                                onChange={onChangeTeamDescription}
                                                rows={10}
                                            />
                                        </div>
                                    </div>
                                    <div className='discard-save-container row'>
                                        <div className='save-container col-sm-12 col-md-12 col-lg-6'>
                                            <div
                                                className='save-btn'
                                                onClick={
                                                    () => {
                                                        setTransactionType('create-team');
                                                        handleSubmitNewTeam();
                                                    }
                                                }
                                            >
                                                Submit
                                            </div>
                                        </div>
                                        <div className='discard-container col-sm-12 col-md-12 col-lg-6'>
                                            <div
                                                className='discard-btn'
                                                onClick={handleCancelCreateTeam}
                                            >
                                                Cancel
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='edit-existing-team'>
                                    <div className='choose-xmoji'>
                                        <div className='top-title'>
                                            Choose an xMoji
                                        </div>
                                        <div className='xmoji-container row'>
                                            {
                                                disableEmojis.map((emojiItem: any, index: number) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className='single-emoji col-3 col-sm-2 col-md-3 col-lg-2 col-xl-1'
                                                            onClick={() => handleEditSelectEmoji(index)}
                                                        >
                                                            {
                                                                editTeamInfo?.teamIcon === index ? <img src={activeEmojis[index]} /> : <img src={emojiItem} />
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='single-input-container'>
                                        <div className='up-title'>
                                            Name of the Team
                                        </div>
                                        <div className='down-input'>
                                            <Form.Control
                                                placeholder='Write your team name...'
                                                value={editTeamInfo?.teamName}
                                                onChange={onEditChangeTeamName}
                                            />
                                        </div>
                                    </div>
                                    <div className='single-input-container'>
                                        <div className='up-title'>
                                            Package Price
                                        </div>
                                        <div className='down-input'>
                                            <Form.Control
                                                placeholder='Write package price...'
                                                value={editTeamInfo?.packageSize}
                                                onChange={onEditChangePackagePrice}
                                            />
                                        </div>
                                    </div>
                                    <div className='single-input-container'>
                                        <div className='up-title'>
                                            Email Contact
                                        </div>
                                        <div className='down-input'>
                                            <Form.Control
                                                placeholder='Write team contact...'
                                                value={editTeamInfo?.emailContact}
                                                onChange={onEditChangeEmailContact}
                                            />
                                        </div>
                                    </div>
                                    <div className='single-input-container'>
                                        <div className='up-title'>
                                            Team Description
                                        </div>
                                        <div className='down-input'>
                                            <Form.Control as='textarea'
                                                placeholder='Write team description...'
                                                value={editTeamInfo?.teamDescription}
                                                onChange={onEditChangeTeamDescription}
                                                rows={10}
                                            />
                                        </div>
                                    </div>
                                    <div className='discard-save-container row'>
                                        <div className='save-container col-sm-12 col-md-12 col-lg-6'>
                                            <div
                                                className='save-btn'
                                                onClick={
                                                    () => {
                                                        setTransactionType('edit-team');
                                                        handleSubmitEditTeam();
                                                    }
                                                }
                                            >
                                                Submit
                                            </div>
                                        </div>
                                        <div className='discard-container col-sm-12 col-md-12 col-lg-6'>
                                            <div
                                                className='discard-btn'
                                                onClick={handleCancelEditTeam}
                                            >
                                                Cancel
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};