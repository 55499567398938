import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga';

import { RiArrowGoBackLine } from 'react-icons/ri';
import { FaTwitter, FaDiscord, FaLinkedinIn, FaBehance, FaGithub, FaMediumM, FaPinterest, FaFacebookF, FaYoutube, FaHandsHelping, FaCheck } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import { BsFillEmojiFrownFill } from 'react-icons/bs';
import { Form } from 'react-bootstrap';

import { useGetIsLoggedIn, useGetAccountInfo, useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { ArgSerializer, Address, AddressValue, BytesValue, TransactionPayload, U32Value, BooleanValue, TokenPayment } from '@multiversx/sdk-core/out';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions/useGetPendingTransactions';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import BigNumber from 'bignumber.js/bignumber.js';

import { styled } from '@mui/material/styles';
import { Divider, Rating } from '@mui/material';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import HexagonIcon from '@mui/icons-material/Hexagon';

import swal from 'sweetalert';

import { CONTRACT_ADDRESS, TOKEN_IDENTIFIER } from 'config';
import { ProfileContext, TransactionHappenContext, ReviewContext, TeamContext } from 'App';
import { departmentType, activeEmojis } from 'mockdata';
import HireMeActive from 'assets/img/hire-me-active.svg';

import './index.scss';

interface ITeamInfo {
    teamId: number;
    teamIcon: number;
    teamName: string;
    packageSize: number;
    emailContact: string;
    teamDescription: string;
    teamCreator: string;
    teamCreatedTime: number;
    teamMembers: number[];
}
interface IReviewInfo {
    reviewId: number;
    receiverAddress: string;
    reviewerAddress: string;
    jobTitle: string;
    reviewDetail: string;
    reviewPoint: number;
}

interface ITalentInfo {
    profileId: number;
    firstName: string;
    lastName: string;
    summary: string;
    avatar: string;
    jobTitle: string;
    department: number;
    openToWork: boolean;
    lastPaidTime: number;
    minBudget: number;
    skills: string;
    twitter: string;
    discord: string;
    linkedin: string;
    behance: string;
    github: string;
    medium: string;
    pinterest: string;
    facebook: string;
    youtube: string;
    experience: string[];
    creator: string;
    createdTime: number;
}

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
        color: '#ff3d47',
    },
});

export const ProfileView = () => {

    const navigate = useNavigate();
    const { profile_id } = useParams();
    const isLoggedIn = useGetIsLoggedIn();
    const { address } = useGetAccountInfo();
    const profiles = useContext(ProfileContext);
    const reviews = useContext(ReviewContext);
    const teams = useContext(TeamContext);

    const { hasPendingTransactions } = useGetPendingTransactions();
    const { transactionHappen, setTransactionHappen } = useContext(TransactionHappenContext);
    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({ transactionId: sessionId });
    const [transactionType, setTransactionType] = useState('');

    const [openDirectHireRequest, setOpenDirectHireRequest] = useState(false);
    const [openInviteTeamRequest, setOpenInviteTeamRequest] = useState(false);

    const [directHireJobTitle, setDirectHireJobTitle] = useState('');
    const [directHireContact, setDirectHireContact] = useState('');
    const [directHireJobOffer, setDirectHireJobOffer] = useState('');

    const [openAddReview, setOpenAddReview] = useState(false);
    const [profileData, setProfileData] = useState<ITalentInfo | null>(null);
    const [myTeamsData, setMyTeamsData] = useState<ITeamInfo[]>([]);
    const [averageReviewPoint, setAverageReviewPoint] = useState(0);

    const [newReviewJobTitle, setNewReviewJobTitle] = useState('');
    const [newReviewDetail, setNewReviewDetail] = useState('');
    const [newReviewPoint, setNewReviewPoint] = useState<number | null>(0);

    const handleBackExplore = () => {
        navigate('/hire', { replace: true });
    };

    const handleOpenAddReview = () => {
        setOpenAddReview(true);
    };

    const handleCloseAddReview = () => {
        setOpenAddReview(false);
    };

    const handleOpenInviteTeam = () => {
        if (profileData?.openToWork === true) {
            document.body.style.overflowY = 'hidden';
            setOpenInviteTeamRequest(true);
        } else {
            swal('Oops', 'You can only propose to Open to work people!', 'error');
        }
    };

    const handleOpenProposeCollaboration = () => {
        document.body.style.overflowY = 'hidden';
        setOpenDirectHireRequest(true);
    };

    const handleCloseProposeCollaboration = () => {
        document.body.style.overflowY = 'auto';
        setOpenDirectHireRequest(false);
    };

    const handleCloseInviteTeam = () => {
        document.body.style.overflowY = 'auto';
        setOpenInviteTeamRequest(false);
    };

    const handleChooseSingleTeam = async (selectedId: number) => {
        setTransactionType('direct-team-request');

        const args = [
            new U32Value(selectedId),
            new AddressValue(new Address(profileData?.creator)),
        ];

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`sendDirectTeamRequest@${argumentsString}`);

        const tx = {
            value: 0,
            receiver: CONTRACT_ADDRESS,
            data: data.toString(),
            gasLimit: 20000000,
        };

        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        setSessionId(result.sessionId);
    };

    const onDirectHireJobTitleChange = (e: any) => {
        setDirectHireJobTitle(e.target.value);
    };

    const onDirectHireContactChange = (e: any) => {
        setDirectHireContact(e.target.value);
    };

    const onDirectHireJobOfferChange = (e: any) => {
        setDirectHireJobOffer(e.target.value);
    };

    const handleSubmitDirectHire = async () => {
        document.body.style.overflowY = 'auto';
        setTransactionType('direct-hire-request');

        const args = [
            BytesValue.fromUTF8(directHireJobTitle),
            BytesValue.fromUTF8(directHireContact),
            BytesValue.fromUTF8(directHireJobOffer),
            new AddressValue(new Address(profileData?.creator)),
        ];

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`sendDirectHireRequest@${argumentsString}`);
        const gas = (40000000 + directHireJobOffer.length * 1500) > 600000000 ? 600000000 : (40000000 + directHireJobOffer.length * 1500);

        const tx = {
            value: 0,
            receiver: CONTRACT_ADDRESS,
            data: data.toString(),
            gasLimit: gas,
        };

        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        setSessionId(result.sessionId);
    };

    const onChangeReviewJobTitle = (e: any) => {
        setNewReviewJobTitle(e.target.value);
    };

    const onChangeReviewDetail = (e: any) => {
        setNewReviewDetail(e.target.value);
    };

    const handleSubmitNewRiview = async () => {

        setTransactionType('new-review');

        const args = [
            new AddressValue(new Address(profileData?.creator)),
            BytesValue.fromUTF8(newReviewJobTitle),
            BytesValue.fromUTF8(newReviewDetail),
            new U32Value(newReviewPoint ? newReviewPoint : 0),
        ];

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`createNewReview@${argumentsString}`);
        const gas = (40000000 + newReviewDetail.length * 1500) > 600000000 ? 600000000 : (40000000 + newReviewDetail.length * 1500);

        const tx = {
            value: 0,
            receiver: CONTRACT_ADDRESS,
            data: data.toString(),
            gasLimit: gas,
        };

        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        setSessionId(result.sessionId);
    };

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            if (transactionType === 'new-review') {
                setTransactionHappen(!transactionHappen);
                swal('Success', 'You successfully add a new review!', 'success');
                setOpenAddReview(false);
            } else if (transactionType === 'direct-hire-request') {
                setTransactionHappen(!transactionHappen);
                swal('Success', 'You successfully submit a request!', 'success');
                setOpenDirectHireRequest(false);
            } else if (transactionType === 'direct-team-request') {
                setTransactionHappen(!transactionHappen);
                swal('Success', 'You successfully submit a request!', 'success');
                setOpenInviteTeamRequest(false);
            }
        }
    }, [sessionId, hasPendingTransactions]);

    useEffect(() => {
        
        if (profile_id) {
            const tempProfile = profiles?.filter((item: ITalentInfo) => item.profileId.toString() === profile_id)[0];

            setProfileData(tempProfile);
            const talentReviews = reviews?.filter((review: IReviewInfo) => review.receiverAddress === tempProfile?.creator);
            let reviewPointSum = 0;
            talentReviews.map((review: IReviewInfo) => {
                reviewPointSum += review.reviewPoint;
            });
            setAverageReviewPoint(reviewPointSum / talentReviews.length);

            setMyTeamsData(teams?.filter((item: ITeamInfo) => item.teamCreator === address));
        }

    }, [profiles, profile_id]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className='profile-view'>
            <div className={`direct-hire ${openDirectHireRequest ? 'active' : ''}`}>
                <div className='hire-modal'>
                    <div className='close-btn'>
                        <MdClose className='close-icon' onClick={handleCloseProposeCollaboration} />
                    </div>
                    <div className='up-title'>
                        <p> PROPOSE &nbsp;
                            <span>
                                A COLLABORATION WITH
                            </span> &nbsp;
                            {profileData?.firstName.toUpperCase() + ' ' + profileData?.lastName.toUpperCase()}
                        </p>
                    </div>
                    <div className='down-input-form'>
                        <Form.Control
                            placeholder='Write a job Department...'
                            value={directHireJobTitle}
                            onChange={onDirectHireJobTitleChange}
                        />
                        <Form.Control
                            placeholder='Write your Contact...'
                            value={directHireContact}
                            onChange={onDirectHireContactChange}
                        />
                        <Form.Control as='textarea'
                            rows={5}
                            placeholder='Write a job Offer...'
                            value={directHireJobOffer}
                            onChange={onDirectHireJobOfferChange}
                        />
                        <div
                            className='submit-btn'
                            onClick={handleSubmitDirectHire}
                        >
                            <FaCheck className='submit-icon' />
                            Submit
                        </div>
                    </div>
                </div>
            </div>

            <div className={`direct-team ${openInviteTeamRequest ? 'active' : ''}`}>
                <div className='hire-modal'>
                    <div className='close-btn'>
                        <MdClose className='close-icon' onClick={handleCloseInviteTeam} />
                    </div>
                    <div className='up-title'>
                        <p> CHOOSE &nbsp;
                            <span>
                                A TEAM
                            </span>
                        </p>
                    </div>
                    <div className='down-teams-container'>
                        {
                            myTeamsData.map((team: ITeamInfo, index: number) => {
                                return (
                                    <div
                                        key={index}
                                        className='single-team'
                                        onClick={() => handleChooseSingleTeam(team.teamId)}
                                    >
                                        <img src={activeEmojis[team.teamIcon]} />
                                        {team.teamName}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            {
                profileData ? (
                    <div className='profile-view-container'>
                        <div className='profile-back'>
                            <div
                                className='left-go-back'
                                onClick={handleBackExplore}
                            >
                                <RiArrowGoBackLine />
                            </div>
                        </div>
                        <div className='profile-container row'>
                            <div className='left-personal-profile col-sm-12 col-md-12 col-lg-6 col-xl-4'>
                                <img src={profileData?.avatar} />
                                {
                                    profileData?.openToWork === true ? (
                                        <img src={HireMeActive} className='hire-me-badge' />
                                    ) : (
                                        <></>
                                    )
                                }
                                <div className='full-name-container'>
                                    <div className='full-name'>
                                        <p>
                                            <span>
                                                {profileData?.firstName.toUpperCase()}
                                            </span>
                                            <br />
                                            {profileData?.lastName.toUpperCase()}
                                        </p>
                                    </div>
                                    <div className='job-title'>
                                        {profileData?.jobTitle}
                                    </div>
                                </div>
                                <div className='department-title'>
                                    <div className='up-label'>
                                        Department
                                    </div>
                                    <div className='down-department'>
                                        {departmentType[profileData?.department - 1]}
                                    </div>
                                </div>
                                <div className='social-icons-container'>
                                    <div className='social-icons'>
                                        {
                                            profileData?.twitter !== '' ? (
                                                <a
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={profileData?.twitter}
                                                >
                                                    <FaTwitter className='single-social-icons' />
                                                </a>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            profileData?.discord !== '' ? (
                                                <a
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={profileData?.discord}
                                                >
                                                    <FaDiscord className='single-social-icons' />
                                                </a>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            profileData?.linkedin !== '' ? (
                                                <a
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={profileData?.linkedin}
                                                >
                                                    <FaLinkedinIn className='single-social-icons' />
                                                </a>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            profileData?.behance !== '' ? (
                                                <a
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={profileData?.behance}
                                                >
                                                    <FaBehance className='single-social-icons' />
                                                </a>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            profileData?.github !== '' ? (
                                                <a
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={profileData?.github}
                                                >
                                                    <FaGithub className='single-social-icons' />
                                                </a>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            profileData?.medium !== '' ? (
                                                <a
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={profileData?.medium}
                                                >
                                                    <FaMediumM className='single-social-icons' />
                                                </a>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            profileData?.pinterest !== '' ? (
                                                <a
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={profileData?.pinterest}
                                                >
                                                    <FaPinterest className='single-social-icons' />
                                                </a>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            profileData?.facebook !== '' ? (
                                                <a
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={profileData?.facebook}
                                                >
                                                    <FaFacebookF className='single-social-icons' />
                                                </a>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            profileData?.youtube !== '' ? (
                                                <a
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={profileData?.youtube}
                                                >
                                                    <FaYoutube className='single-social-icons' />
                                                </a>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='review-count'>
                                    <StyledRating
                                        defaultValue={averageReviewPoint}
                                        precision={0.1}
                                        icon={<HexagonIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                        emptyIcon={<HexagonOutlinedIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                        readOnly
                                    />
                                </div>
                                <div className='min-budget'>
                                    Min.Budget {profileData?.minBudget} USD
                                </div>
                                <div className='invite-container'>
                                    {
                                        (profileData?.creator !== address && isLoggedIn) ? (
                                            <div
                                                className='invite-team active'
                                                onClick={handleOpenInviteTeam}
                                            >
                                                <HiUserGroup className='team-icon' />
                                                Invite to Team
                                            </div>
                                        ) : (
                                            <div className='invite-team disable'>
                                                <HiUserGroup className='team-icon' />
                                                Invite to Team
                                            </div>
                                        )
                                    }
                                </div>
                                <div className='propose-container'>
                                    {
                                        (profileData?.creator !== address && isLoggedIn) ? (
                                            <div
                                                className='propose-collaboration active'
                                                onClick={handleOpenProposeCollaboration}
                                            >
                                                <FaHandsHelping className='propose-icon' />
                                                Propose a Collaboration
                                            </div>
                                        ) : (
                                            <div className='propose-collaboration disable'>
                                                <FaHandsHelping className='propose-icon' />
                                                Propose a Collaboration
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className='right-professional-profile col-sm-12 col-md-12 col-lg-6 col-xl-8'>
                                <div className='profile-summary'>
                                    <p> Professional Summary </p>
                                    <span dangerouslySetInnerHTML={{ __html: profileData?.summary.replaceAll('\n', '<br />') }} />
                                </div>
                                <div className='profile-skills'>
                                    <p> Skills </p>
                                    <div className='skills-container'>
                                        {
                                            profileData?.skills.split(';').slice(0, profileData?.skills.split(';').length - 1).map((skill, index) => (
                                                <div key={index} className='single-skill'>
                                                    {skill}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='profile-experience'>
                                    <p> Work Experience </p>
                                    <div className='experience-container'>
                                        {
                                            profileData?.experience.map((experience, index) => {
                                                return (
                                                    <div key={index} className='single-experience-box'>
                                                        <div className='up-title'>
                                                            <div className='left-job-title'>
                                                                {experience.split(';')[0]}
                                                            </div>
                                                            <div className='right-job-type'>
                                                                {experience.split(';')[1]}
                                                            </div>
                                                        </div>
                                                        <div className='down-experience'>
                                                            <div className='left-company-title'>
                                                                {experience.split(';')[2]}
                                                            </div>
                                                            <div className='right-experience-period'>
                                                                {
                                                                    new Date(experience.split(';')[3]).toDateString()
                                                                    + ' - ' +
                                                                    new Date(experience.split(';')[4]).toDateString()
                                                                }
                                                            </div>
                                                        </div>
                                                        <Divider className='bottom-divider' />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='profile-reviews'>
                                    <p> Reviews </p>
                                    <div className='reviews-container'>
                                        {
                                            reviews.filter((review: IReviewInfo) => review.receiverAddress === profileData?.creator).length === 0 ? (
                                                <div className='no-reviews'>
                                                    <BsFillEmojiFrownFill className='unhappy-icon' />
                                                    No Reviews Yet ...
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            reviews.filter((review: IReviewInfo) => review.receiverAddress === profileData?.creator).map((review: IReviewInfo, index: number) => {
                                                return (
                                                    <div key={index} className='single-review-box row'>
                                                        <div className='left-text-container col-sm-12 col-md-8 col-lg-9'>
                                                            <div className='review-address'>
                                                                {review.reviewerAddress.slice(0, 20) + '...'}
                                                            </div>
                                                            <div className='review-category'>
                                                                {review.jobTitle}
                                                            </div>
                                                            <div className='review-detail'>
                                                                {review.reviewDetail}
                                                            </div>
                                                        </div>
                                                        <div className='right-rating-container col-sm-12 col-md-4 col-lg-3'>
                                                            <StyledRating
                                                                defaultValue={review.reviewPoint}
                                                                precision={1}
                                                                icon={<HexagonIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                                                emptyIcon={<HexagonOutlinedIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    {
                                        isLoggedIn ? (
                                            <div className='add-review-container'>
                                                {
                                                    openAddReview ? (
                                                        <div className='add-review-box'>
                                                            <div className='left-text-container'>
                                                                <div className='review-address'>
                                                                    {address.slice(0, 20) + '...'}
                                                                </div>
                                                                <Form.Control
                                                                    placeholder='Write a job department...'
                                                                    value={newReviewJobTitle}
                                                                    onChange={onChangeReviewJobTitle}
                                                                />
                                                                <Form.Control as='textarea'
                                                                    rows={3}
                                                                    placeholder='Write a review here...'
                                                                    value={newReviewDetail}
                                                                    onChange={onChangeReviewDetail}
                                                                />
                                                                <div
                                                                    className='submit-btn'
                                                                    onClick={handleSubmitNewRiview}
                                                                >
                                                                    Submit
                                                                </div>
                                                            </div>
                                                            <div className='right-rating-container'>
                                                                <div className='close-add-review'>
                                                                    <MdClose className='close-icon' onClick={handleCloseAddReview} />
                                                                </div>
                                                                <StyledRating
                                                                    value={newReviewPoint}
                                                                    onChange={
                                                                        (e, newValue) => {
                                                                            setNewReviewPoint(newValue);
                                                                        }
                                                                    }
                                                                    precision={1}
                                                                    icon={<HexagonIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                                                    emptyIcon={<HexagonOutlinedIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className='add-review-btn'
                                                            onClick={handleOpenAddReview}
                                                        >
                                                            Add a Review
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                    </>
                )
            }
        </div>
    );
};