import React, { createContext, useState, useEffect } from 'react';
import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import {
  DappProvider,
  AxiosInterceptorContext // using this is optional
} from '@multiversx/sdk-dapp/wrappers';

import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'components';
import {
  apiTimeout,
  walletConnectV2ProjectId,
  sampleAuthenticatedDomains,
  API_URL,
  CONTRACT_ADDRESS,
  CONTRACT_NAME,
} from 'config';
import { PageNotFound, Unlock } from 'pages';
import { routeNames } from 'routes';
import { routes } from 'routes';

import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions/useGetPendingTransactions';
import { useGetAccountInfo, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import {
  Address,
  AbiRegistry,
  SmartContractAbi,
  SmartContract,
  AddressValue
} from '@multiversx/sdk-core/out';
import Abi from 'abi/xemployed-sc.abi.json';
import { sendQuery } from 'utils/transaction';
import ReactGA from 'react-ga';

export const ContractContext = createContext<any>(undefined);
export const ArticleContext = createContext<any>(undefined);
export const TeamContext = createContext<any>(undefined);
export const ProfileContext = createContext<any>(undefined);
export const JobContext = createContext<any>(undefined);
export const ReviewContext = createContext<any>(undefined);
export const DirectHireContext = createContext<any>(undefined);
export const DirectTeamContext = createContext<any>(undefined);
export const JobProposalContext = createContext<any>(undefined);

export const RightMenuContext = createContext<any>(undefined);
export const SelectedAccountMenuContext = createContext<any>(undefined);
export const TransactionHappenContext = createContext<any>(undefined);

export const TRACKING_ID = 'UA-260629346-1';
ReactGA.initialize(TRACKING_ID);

export const App = () => {
  const [openRightMenu, setOpenRightMenu] = useState(false);
  const [selectedAccountMenu, setSelectedAccountMenu] = useState(0);

  const { hasPendingTransactions } = useGetPendingTransactions();
  const proxy = new ProxyNetworkProvider(API_URL, { timeout: apiTimeout });

  const { address } = useGetAccountInfo();

  const [contract, setContract] = useState<any>(undefined);
  const [profiles, setProfiles] = useState<any>([]);
  const [articles, setArticles] = useState<any>([]);
  const [jobs, setJobs] = useState<any>([]);
  const [teams, setTeams] = useState<any>([]);
  const [reviews, setReviews] = useState<any>([]);
  const [directHires, setDirectHires] = useState<any>([]);
  const [directTeams, setDirectTeams] = useState<any>([]);
  const [jobProposals, setJobProposals] = useState<any>([]);

  const [transactionHappen, setTransactionHappen] = useState(false);

  useEffect(() => {
    (async () => {
      const json = JSON.parse(JSON.stringify(Abi));
      const abiRegistry = await AbiRegistry.create(json);
      const con = new SmartContract({
        address: new Address(CONTRACT_ADDRESS),
        abi: new SmartContractAbi(abiRegistry, [CONTRACT_NAME]),
      });

      setContract(con);
    })();
  }, []); // [] makes useEffect run once


  useEffect(() => {

    (async () => {
      if (!contract) {
        return;
      }
      const interaction = contract.methods.getAllProfiles();
      const res: any = await sendQuery(contract, proxy, interaction);
      if (!res || !res.returnCode.isSuccess()) return;
      const value: any = res.firstValue.valueOf();

      const datas: any = [];
      value.map((item: any) => {
        const tempExperience = item.professional_experience.map((item: any) => {
          return item.toString();
        });

        const data = {
          profileId: item.profile_id.toNumber(),
          firstName: item.first_name.toString(),
          lastName: item.last_name.toString(),
          summary: item.professional_summary.toString(),
          avatar: item.profile_avatar.toString(),
          jobTitle: item.job_title.toString(),
          department: item.job_department.toNumber(),
          openToWork: item.open_to_work,
          lastPaidTime: item.profile_last_paid_time.toNumber(),
          minBudget: item.min_budget.toNumber(),
          skills: item.professional_skills.toString(),
          twitter: item.profile_twitter.toString(),
          discord: item.profile_discord.toString(),
          linkedin: item.profile_linkedin.toString(),
          behance: item.profile_behance.toString(),
          github: item.profile_github.toString(),
          medium: item.profile_medium.toString(),
          pinterest: item.profile_pinterest.toString(),
          facebook: item.profile_facebook.toString(),
          youtube: item.profile_youtube.toString(),
          experience: tempExperience,
          creator: item.profile_creator.toString(),
          createdTime: item.profile_created_time.toNumber(),
        };

        datas.push(data);
      });
      // console.log('all profiles: ', datas);
      setProfiles(datas);
    })();

    (async () => {
      if (!contract) {
        return;
      }
      const interaction = contract.methods.getAllProjects();
      const res: any = await sendQuery(contract, proxy, interaction);
      if (!res || !res.returnCode.isSuccess()) return;
      const value: any = res.firstValue.valueOf();

      const datas: any = [];
      value.map((item: any) => {
        const data = {
          jobId: item.project_id.toNumber(),
          companyName: item.project_name.toString(),
          companyLogo: item.project_cover_image.toString(),
          jobDescription: item.project_description.toString(),
          jobTitle: item.project_position_title.toString(),
          salary: item.project_salary.toNumber(),
          department: item.project_department.toNumber(),
          jobType: item.project_employ_type.toString(),
          twitter: item.project_twitter.toString(),
          discord: item.project_discord.toString(),
          featured: item.project_featured,
          creator: item.project_creator.toString(),
          createdTime: item.project_created_time.toNumber(),
        };
        datas.push(data);
      });
      // console.log('all jobs: ', datas);
      setJobs(datas);
    })();

    (async () => {
      if (!contract) {
        return;
      }
      const interaction = contract.methods.getAllDirectTeams();
      const res: any = await sendQuery(contract, proxy, interaction);
      if (!res || !res.returnCode.isSuccess()) return;
      const value: any = res.firstValue.valueOf();

      const datas: any = [];
      value.map((item: any) => {
        const data = {
          directTeamId: item.direct_team_id.toNumber(),
          invitedTeam: item.direct_invited_team.toNumber(),
          requestReceiver: item.direct_team_receiver.toString(),
          requestSender: item.direct_team_sender.toString(),
          requestTime: item.direct_team_time.toString(),
        };
        datas.push(data);
      });
      // console.log('all direct team requests: ', datas);
      setDirectTeams(datas);
    })();

    (async () => {
      if (!contract) {
        return;
      }
      const interaction = contract.methods.getAllDirectHires();
      const res: any = await sendQuery(contract, proxy, interaction);
      if (!res || !res.returnCode.isSuccess()) return;
      const value: any = res.firstValue.valueOf();

      const datas: any = [];
      value.map((item: any) => {
        const data = {
          directHireId: item.direct_hire_id.toNumber(),
          jobTitle: item.direct_hire_job_title.toString(),
          contact: item.direct_hire_contact.toString(),
          content: item.direct_hire_content.toString(),
          receiver: item.direct_hire_receiver.toString(),
          sender: item.direct_hire_sender.toString(),
          sendTime: item.direct_hire_time.toNumber(),
        };
        datas.push(data);
      });
      // console.log('all direct hires: ', datas);
      setDirectHires(datas);
    })();

    (async () => {
      if (!contract) {
        return;
      }
      const interaction = contract.methods.getAllReviews();
      const res: any = await sendQuery(contract, proxy, interaction);
      if (!res || !res.returnCode.isSuccess()) return;
      const value: any = res.firstValue.valueOf();

      const datas: any = [];
      value.map((item: any) => {
        const data = {
          reviewId: item.review_id.toNumber(),
          receiverAddress: item.receiver_address.toString(),
          reviewerAddress: item.review_creator.toString(),
          jobTitle: item.review_job_title.toString(),
          reviewDetail: item.review_detail.toString(),
          reviewPoint: item.review_point.toNumber(),
        };
        datas.push(data);
      });
      // console.log('all reviews: ', datas);
      setReviews(datas);
    })();

    (async () => {
      if (!contract) {
        return;
      }
      const interaction = contract.methods.getAllJobProposals();
      const res: any = await sendQuery(contract, proxy, interaction);
      if (!res || !res.returnCode.isSuccess()) return;
      const value: any = res.firstValue.valueOf();

      const datas: any = [];
      value.map((item: any) => {
        const data = {
          proposalId: item.job_proposal_id.toNumber(),
          jobId: item.job_selected_id.toNumber(),
          receiver: item.job_proposal_receiver.toString(),
          submitter: item.job_proposal_submitter.toString(),
          submittedTime: item.job_proposal_submitted_time.toNumber(),
        };
        datas.push(data);
      });
      // console.log('all job proposals: ', datas);
      setJobProposals(datas);
    })();

    (async () => {
      if (!contract) {
        return;
      }
      const interaction = contract.methods.getAllArticles();
      const res: any = await sendQuery(contract, proxy, interaction);
      if (!res || !res.returnCode.isSuccess()) return;
      const value: any = res.firstValue.valueOf();

      const datas: any = [];
      value.map((item: any) => {
        const data = {
          articleId: item.article_id.toNumber(),
          coverImage: item.article_cover_image.toString(),
          title: item.article_title.toString(),
          twitter: item.article_twitter.toString(),
          authorName: item.article_author_name.toString(),
          content: item.article_content.toString(),
          submitter: item.article_submitter.toString(),
          postedTime: item.article_posted_time.toNumber(),
        };
        datas.push(data);
      });
      // console.log('all articles: ', datas);
      setArticles(datas);
    })();

    (async () => {
      if (!contract) {
        return;
      }
      const interaction = contract.methods.getAllTeams();
      const res: any = await sendQuery(contract, proxy, interaction);
      if (!res || !res.returnCode.isSuccess()) return;
      const value: any = res.firstValue.valueOf();

      const datas: any = [];
      value.map((item: any) => {
        const data = {
          teamId: item.team_id.toNumber(),
          teamIcon: item.team_icon.toString(),
          teamName: item.team_name.toString(),
          packageSize: item.team_package_price.toNumber(),
          emailContact: item.team_contact.toString(),
          teamDescription: item.team_description.toString(),
          teamCreator: item.team_creator.toString(),
          teamCreatedTime: item.team_created_time.toNumber(),
          teamMembers: item.team_members,
        };
        datas.push(data);
      });
      // console.log('all teams: ', datas);
      setTeams(datas);
    })();

  }, [address, contract, hasPendingTransactions, transactionHappen]);

  return (
    <AxiosInterceptorContext.Provider>
      <AxiosInterceptorContext.Interceptor
        authenticatedDomanis={sampleAuthenticatedDomains}
      >
        <ContractContext.Provider value={contract}>
          <JobProposalContext.Provider value={jobProposals}>
            <DirectTeamContext.Provider value={directTeams}>
              <DirectHireContext.Provider value={directHires}>
                <ReviewContext.Provider value={reviews}>
                  <JobContext.Provider value={jobs}>
                    <ProfileContext.Provider value={profiles}>
                      <ArticleContext.Provider value={articles}>
                        <TeamContext.Provider value={teams}>
                          <TransactionHappenContext.Provider value={{ transactionHappen, setTransactionHappen }}>
                            <RightMenuContext.Provider value={{ openRightMenu, setOpenRightMenu }}>
                              <SelectedAccountMenuContext.Provider value={{ selectedAccountMenu, setSelectedAccountMenu }}>
                                <Router>
                                  <DappProvider
                                    environment={EnvironmentsEnum.mainnet}
                                    customNetworkConfig={{
                                      name: 'customConfig',
                                      apiTimeout,
                                      walletConnectV2ProjectId
                                    }}
                                  >
                                    <Layout>
                                      <AxiosInterceptorContext.Listener />
                                      <TransactionsToastList />
                                      <NotificationModal />
                                      <SignTransactionsModals className='custom-class-for-modals' />
                                      <Routes>
                                        <Route path={routeNames.unlock} element={<Unlock />} />
                                        {routes.map((route, index) => (
                                          <Route
                                            path={route.path}
                                            key={'route-key-' + index}
                                            element={<route.component />}
                                          />
                                        ))}
                                        <Route path='*' element={<PageNotFound />} />
                                      </Routes>
                                    </Layout>
                                  </DappProvider>
                                </Router>
                              </SelectedAccountMenuContext.Provider>
                            </RightMenuContext.Provider>
                          </TransactionHappenContext.Provider>
                        </TeamContext.Provider>
                      </ArticleContext.Provider>
                    </ProfileContext.Provider>
                  </JobContext.Provider>
                </ReviewContext.Provider>
              </DirectHireContext.Provider>
            </DirectTeamContext.Provider>
          </JobProposalContext.Provider>
        </ContractContext.Provider>
      </AxiosInterceptorContext.Interceptor>
    </AxiosInterceptorContext.Provider>
  );
};
