import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetIsLoggedIn, useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

import { BsLightningChargeFill } from 'react-icons/bs';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';

import { RightMenuContext } from 'App';
import { routeNames } from 'routes';
import './index.scss';

export const Navbar = () => {

    const navigate = useNavigate();
    const isLoggedIn = useGetIsLoggedIn();
    const { address } = useGetAccountInfo();
    
    const { setOpenRightMenu } = useContext(RightMenuContext);
    const [showMobileNavbar, setShowMobileNavbar] = useState(false);

    const handleBackHome = () => {
        navigate(routeNames.home, { replace: true });
    };

    const handleShowMobileNavbar = () => {
        setShowMobileNavbar(true);
    };

    const handleCloseMobileNavbar = () => {
        setShowMobileNavbar(false);
    };

    const handleSingleMenu = () => {
        window.scrollTo(0, 0);
        setShowMobileNavbar(false);
    };

    const handleOpenUserMenu = () => {
        document.body.style.overflowY = 'hidden';
        setShowMobileNavbar(false);
        setOpenRightMenu(true);
    };

    const handleConnectWallet = () => {
        window.scrollTo(0, 0);
        navigate('/unlock', { replace: true });
        setShowMobileNavbar(false);
    };

    return (
        <header className='app-header'>
            <nav>
                <div className='nav-desktop'>
                    <div
                        className='xemployed-logo'
                        onClick={handleBackHome}
                    >
                        <p> xEmployed
                            <span>
                                .io
                            </span>
                        </p>
                    </div>
                    <div className='xemployed-menu-container'>
                        <div
                            className='single-menu'
                            onClick={() => window.scrollTo(0, 0)}
                        >
                            <Link to={'/hire'}>
                                Hire
                            </Link>
                        </div>
                        <div
                            className='single-menu'
                            onClick={() => window.scrollTo(0, 0)}
                        >
                            <Link to={'/employ'}>
                                Employ
                            </Link>
                        </div>
                        <div
                            className='single-menu'
                            onClick={() => window.scrollTo(0, 0)}
                        >
                            <Link to={'/avenue'}>
                                Avenue
                            </Link>
                        </div>
                        <div
                            className='single-menu'
                            onClick={() => window.scrollTo(0, 0)}
                        >
                            <Link to={'/teams'}>
                                Teams
                            </Link>
                        </div>
                    </div>
                    {
                        isLoggedIn ? (
                            <div
                                className='wallet-already-connected'
                                onClick={handleOpenUserMenu}
                            >
                                {address.slice(0, 5) + '...' + address.slice(address.length - 5)}
                            </div>
                        ) : (
                            <div
                                className='wallet-connect-btn'
                                onClick={handleConnectWallet}
                            >
                                Connect
                                <div className='lightening-container'>
                                    <BsLightningChargeFill className='lightening-icon' />
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className='nav-mobile'>
                    <div
                        className='xemployed-logo'
                        onClick={handleBackHome}
                    >
                        <p> xEmployed
                            <span>
                                .io
                            </span>
                        </p>
                    </div>
                    <div
                        className='nav-expand-btn'
                        onClick={handleShowMobileNavbar}
                    >
                        <HiOutlineMenuAlt2 />
                    </div>
                </div>
                <div
                    className={
                        `nav-mobile-menu ${showMobileNavbar ? 'show' : 'hide'}`
                    }
                >
                    <div className='top-menu-container'>
                        <div className='close-nav-menu'>
                            <MdClose onClick={handleCloseMobileNavbar} />
                        </div>
                        <div
                            className='single-menu'
                            onClick={handleSingleMenu}
                        >
                            <Link to={'/hire'}>
                                Hire
                            </Link>
                        </div>
                        <div
                            className='single-menu'
                            onClick={handleSingleMenu}
                        >
                            <Link to={'/employ'}>
                                Employ
                            </Link>
                        </div>
                        <div
                            className='single-menu'
                            onClick={handleSingleMenu}
                        >
                            <Link to={'/avenue'}>
                                Avenue
                            </Link>
                        </div>
                        <div
                            className='single-menu'
                            onClick={handleSingleMenu}
                        >
                            <Link to={'/teams'}>
                                Teams
                            </Link>
                        </div>
                        {
                            isLoggedIn ? (
                                <div
                                    className='wallet-already-connected'
                                    onClick={handleOpenUserMenu}
                                >
                                    {address.slice(0, 5) + '...' + address.slice(address.length - 5)}
                                </div>
                            ) : (
                                <div
                                    className='wallet-connect-btn'
                                    onClick={handleConnectWallet}
                                >
                                    Connect
                                    <div className='lightening-container'>
                                        <BsLightningChargeFill className='lightening-icon' />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div
                        className='bottom-blank-container'
                        onClick={handleCloseMobileNavbar}
                    />
                </div>
            </nav>
        </header>
    );
};