import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';

import { IoWallet } from 'react-icons/io5';
import { FaUserAlt } from 'react-icons/fa';
import { AiFillMessage } from 'react-icons/ai';
import { RiMagicFill, RiTeamFill, RiLogoutCircleLine, RiArticleFill } from 'react-icons/ri';

import { RightMenuContext, SelectedAccountMenuContext } from 'App';
import './index.scss';

export const RightMenu = () => {
    const navigate = useNavigate();
    const { address } = useGetAccountInfo();
    const { openRightMenu, setOpenRightMenu } = useContext(RightMenuContext);
    const { setSelectedAccountMenu } = useContext(SelectedAccountMenuContext);

    const handleCloseRightMenu = () => {
        setOpenRightMenu(false);
        document.body.style.overflowY = 'auto';
    };

    const handleSingleMenu = (currentMenu: number) => {
        window.scrollTo(0, 0);
        document.body.style.overflowY = 'auto';
        setOpenRightMenu(false);
        setSelectedAccountMenu(currentMenu);
        navigate('/account', { replace: true });
    };

    const handleDisconnectWallet = () => {
        window.scrollTo(0, 0);
        logout(`${window.location.origin}`);
    };

    return (
        <div className={`right-menu-bar ${openRightMenu ? 'active' : ''
            }`}>
            <div
                className='left-blank-menu'
                onClick={handleCloseRightMenu}
            />
            <div className='right-main-menu'>
                <div className='top-user-address'>
                    <IoWallet className='wallet-icon' />
                    {address.slice(0, 20) + '...'}
                </div>
                <div
                    className='single-normal-menu'
                    onClick={() => handleSingleMenu(0)}
                >
                    <FaUserAlt className='menu-icon' />
                    Profile
                </div>
                <div
                    className='single-normal-menu'
                    onClick={() => handleSingleMenu(1)}
                >
                    <AiFillMessage className='menu-icon' />
                    Proposals
                </div>
                <div
                    className='single-normal-menu'
                    onClick={() => handleSingleMenu(2)}
                >
                    <RiMagicFill className='menu-icon' />
                    Post a Job
                </div>
                <div
                    className='single-normal-menu'
                    onClick={() => handleSingleMenu(3)}
                >
                    <RiTeamFill className='menu-icon' />
                    Create a Team
                </div>
                <div
                    className='single-normal-menu'
                    onClick={() => handleSingleMenu(4)}
                >
                    <RiArticleFill className='menu-icon' />
                    Post an Article
                </div>
                <div
                    className='single-logout-menu'
                    onClick={handleDisconnectWallet}
                >
                    <RiLogoutCircleLine className='menu-icon' />
                    Log Out
                </div>
            </div>
        </div>
    );
};
