import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

import { HiOutlineArrowRight, HiOutlineArrowLeft } from 'react-icons/hi';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import AvatarEffect from 'assets/img/avatar-effect.svg';
import HelpRanking from 'assets/img/help-ranking.svg';
import HelpVerify from 'assets/img/help-verify.svg';
import HelpSkill from 'assets/img/help-skill.svg';
import HelpStory from 'assets/img/help-story.png';
import GBirdyAvatar from 'assets/img/gbirdy-avatar.svg';
import ReviewEffect from 'assets/img/review-effect.svg';
import XWomenNormal from 'assets/img/xwomen-normal.png';
import XWomenHover from 'assets/img/xwomen-hover.png';
import MultiversXNormal from 'assets/img/multiversx-normal.png';
import MultiversXHover from 'assets/img/multiversx-hover.png';
import XPortalNormal from 'assets/img/xportal-normal.png';
import XPortalHover from 'assets/img/xportal-hover.png';
import BLXCNormal from 'assets/img/blxc-normal.png';
import BLXCHover from 'assets/img/blxc-hover.png';
import NFTRNormal from 'assets/img/nftr-normal.png';
import NFTRHover from 'assets/img/nftr-hover.png';

import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import HexagonIcon from '@mui/icons-material/Hexagon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Rating, Accordion, AccordionSummary, AccordionDetails, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TwitterEmbed } from 'react-social-media-embed';
import { AuthRedirectWrapper } from 'components';
import { routeNames } from 'routes';
import { ProfileContext } from 'App';
import './index.scss';

const allTalentsResponsive = {
    desktopBig: {
        breakpoint: { max: 3000, min: 1800 },
        items: 5,
    },
    desktopMiddle: {
        breakpoint: { max: 1800, min: 1500 },
        items: 4,
    },
    desktopNormal: {
        breakpoint: { max: 1500, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 600 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
    }
};

interface ITalentInfo {
    profileId: number;
    firstName: string;
    lastName: string;
    jobTitle: string;
    avatar: string;
}

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
        color: '#ff3d47',
    },
});

export const Dashboard = () => {

    const navigate = useNavigate();
    const profiles = useContext(ProfileContext);

    const handleExploreTalents = () => {
        window.scrollTo(0, 0);
        navigate('/hire', { replace: true });
    };

    const handleReadMore = () => {
        window.scrollTo(0, 0);
        navigate('/avenue', { replace: true });
    };

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className='xemployed-dashboard'>
            <div className='xemployed-description row'>
                <div className='left-title-container col-sm-12 col-md-12 col-lg-6'>
                    <div className='title-effect'>
                        <p> UNLOCK &nbsp;
                            <span>
                                THE POWER
                                OF YOUR WORKFORCE
                                WITH OUR
                            </span> &nbsp;
                            WEB3-ENABLED &nbsp;
                            <span>
                                EMPLOYEE PLATFORM
                            </span>
                        </p>
                    </div>
                </div>
                <div className='right-description-container col-sm-12 col-md-12 col-lg-6'>
                    <div className='up-description'>
                        Revolutionize your HR management and unlock the full potential of your workforce with our cutting-edge Web3 employee platform.
                        Join the future of work with our Web3 employee platform.
                    </div>
                    <div className='down-explore'>
                        <div
                            className='explore-btn'
                            onClick={handleExploreTalents}
                        >
                            Explore
                            <HiOutlineArrowRight className='explore-icon' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='explore-talent'>
                <Carousel
                    swipeable={false}
                    draggable={true}
                    showDots={false}
                    responsive={allTalentsResponsive}
                >
                    {
                        profiles.map((talent: ITalentInfo, index: number) => {
                            return (
                                <div key={index} className='inner-card'>
                                    <img
                                        src={talent.avatar}
                                        className='avatar-image'
                                    />
                                    <img
                                        src={AvatarEffect}
                                        className='avatar-effect'
                                    />
                                    <div className='talent-info'>
                                        {talent.jobTitle} <br />
                                        {talent.firstName + ' ' + talent.lastName}
                                    </div>
                                </div>
                            );
                        })
                    }
                </Carousel>
            </div>
            <div className='xemployed-streamline row'>
                <div className='left-streamline-container col-sm-12 col-md-12 col-lg-6 col-xl-5'>
                    <div className='title-effect'>
                        <p> STREAMLINING &nbsp;
                            <span>
                                YOUR PROCESS AND
                            </span> &nbsp;
                            EMPOWERING &nbsp;
                            <span>
                                YOUR PROJECT
                            </span>
                        </p>
                    </div>
                    <div className='streamline-description'>
                        Join the future of work with our innovative employee platform,
                        offering a decentralized ecosystem where employees
                        can access next-gen benefits and participate in the gig economy.
                        <br /> <br />
                        Transform your HR management with our blockchain-based employee platform,
                        empowering your employees with ownership of their data and careers.
                    </div>
                </div>
                <div className='middle-blank col-xl-2' />
                <div className='right-video-container col-sm-12 col-md-12 col-lg-6 col-xl-5'>
                    <video controls>
                        <source src={require('assets/video/women-multiversx.mp4')} type='video/mp4' />
                    </video>
                </div>
            </div>
            <div className='xemployed-help row'>
                <div className='left-help-items col-sm-12 col-md-7 col-lg-8 col-xl-9 row'>
                    <div className='help-item-container col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                        <div className='single-help-item'>
                            <div className='top-container'>
                                <div className='up-title'>
                                    Get greater exposure
                                </div>
                                <div className='down-description'>
                                    Unleash your potential and get the exposure you deserve with our powerful employee platform.
                                    Take your career to the next level and showcase your skills
                                </div>
                            </div>
                            <div className='bottom-container'>
                                <img src={HelpRanking} />
                            </div>
                        </div>
                    </div>
                    <div className='help-item-container col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                        <div className='single-help-item'>
                            <div className='top-container'>
                                <div className='up-title'>
                                    Discover qualified candidates
                                </div>
                                <div className='down-description'>
                                    Find the perfect fit for your team and unlock the power of talent discovery
                                    with our advanced employee platform, offering unparalleled access to a diverse pool of qualified candidates.
                                </div>
                            </div>
                            <div className='bottom-container'>
                                <img src={HelpVerify} />
                            </div>
                        </div>
                    </div>
                    <div className='help-item-container col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                        <div className='single-help-item'>
                            <div className='top-container'>
                                <div className='up-title'>
                                    Check their skills
                                </div>
                                <div className='down-description'>
                                    Make informed hiring decisions and evaluate candidates with confidence
                                    using our innovative employee platform, featuring cutting-edge tools to assess their skills and qualifications.
                                </div>
                            </div>
                            <div className='bottom-container'>
                                <img src={HelpSkill} />
                            </div>
                        </div>
                    </div>
                    <div className='help-item-container col-sm-12 col-md-12 col-lg-12 col-xl-6'>
                        <div className='single-help-item'>
                            <div className='top-container'>
                                <div className='up-title'>
                                    Learn about stories and insights
                                </div>
                                <div className='down-description'>
                                    Explore a world of knowledge and connect with inspiring individuals,
                                    while gaining valuable insights into industry trends and best practices, with our advanced employee platform.
                                </div>
                            </div>
                            <div className='bottom-container'>
                                <img src={HelpStory} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='right-read-more col-sm-12 col-md-5 col-lg-4 col-xl-3'>
                    <div className='title-effect'>
                        <p>
                            <span>
                                IT SHOULD <br />
                                BE &nbsp;
                            </span>
                            EASY <br />
                            <span>
                                TO CONNECT <br />
                                IN &nbsp;
                            </span>
                            WEB3
                        </p>
                    </div>
                    <div className='down-read-more'>
                        <div
                            className='read-more-btn'
                            onClick={handleReadMore}
                        >
                            <HiOutlineArrowLeft className='read-more-icon' />
                            Read More
                        </div>
                    </div>
                </div>
            </div>
            <div className='xemployed-partner row'>
                <div className='left-partner-container col-sm-12 col-md-12 col-lg-4 col-xl-5'>
                    <img src={XWomenNormal} />
                    <img src={MultiversXNormal} />
                </div>
                <div className='right-partner-container col-sm-12 col-md-12 col-lg-8 col-xl-7'>
                    <img src={XPortalNormal} />
                    <img src={BLXCNormal} />
                    <img src={NFTRNormal} />
                </div>
            </div>
            <div className='xemployed-reviews row'>
                <div className='left-review-text col-sm-12 col-md-12 col-lg-12 col-xl-4'>
                    <div className='title-effect'>
                        <p> SORT &nbsp;
                            <span>
                                POTENTIAL <br />
                                CANDIDATES ACCORDING TO THEIR
                            </span> &nbsp;
                            REVIEWS
                        </p>
                    </div>
                    <div className='review-description'>
                        Our platform offers a powerful feature that allows you to sort potential candidates according to their reviews.
                        This feature allows you to easily identify the top-rated candidates and prioritize them in your hiring process.
                        <br /><br />
                        By leveraging the power of reviews, you can gain valuable insights into the quality of work and level of professionalism of each candidate,
                        and make more informed hiring decisions.
                    </div>
                </div>
                <div className='right-review-box col-sm-12 col-md-12 col-lg-12 col-xl-8'>
                    <div className='single-review-box row'>
                        <div className='left-text-container col-sm-12 col-md-8 col-lg-9'>
                            <div className='review-address'>
                                erd1rt2788zkphpcmp8xje...
                            </div>
                            <div className='review-category'>
                                Social Media Marketing
                            </div>
                            <div className='review-detail'>
                                She knows how to create content that resonates with my target audience
                                and my social media presence has grown significantly as a result. Highly recommend!
                            </div>
                        </div>
                        <div className='right-rating-container col-sm-12 col-md-4 col-lg-3'>
                            <StyledRating
                                defaultValue={4}
                                precision={1}
                                icon={<HexagonIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                emptyIcon={<HexagonOutlinedIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className='single-review-box row'>
                        <div className='left-text-container col-sm-12 col-md-8 col-lg-9'>
                            <div className='review-address'>
                                erd1f330mdg9qwxa9q2ds...
                            </div>
                            <div className='review-category'>
                                Rebranding my Brand
                            </div>
                            <div className='review-detail'>
                                Working with him to rebrand my business was a fantastic experience.
                                He truly understood my vision and developed a fresh and modern brand identity that perfectly represents my business.
                                I highly recommend his services!
                            </div>
                        </div>
                        <div className='right-rating-container col-sm-12 col-md-4 col-lg-3'>
                            <StyledRating
                                defaultValue={5}
                                precision={1}
                                icon={<HexagonIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                emptyIcon={<HexagonOutlinedIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className='single-review-box row'>
                        <div className='left-text-container col-sm-12 col-md-8 col-lg-9'>
                            <div className='review-address'>
                                erd1s2m5np8hg3z33vlktv...
                            </div>
                            <div className='review-category'>
                                Video Editing
                            </div>
                            <div className='review-detail'>
                                He transformed my raw footage into a polished, professional-looking video that exceeded my expectations.
                                Highly recommend their video editing services!
                            </div>
                        </div>
                        <div className='right-rating-container col-sm-12 col-md-4 col-lg-3'>
                            <StyledRating
                                defaultValue={5}
                                precision={1}
                                icon={<HexagonIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                emptyIcon={<HexagonOutlinedIcon fontSize='inherit' sx={{ color: '#15E800' }} />}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className='review-effect'>
                        <img src={ReviewEffect} />
                    </div>
                </div>
            </div>
            <div className='xemployed-owner'>
                <div className='owner-container row'>
                    <div className='left-description col-sm-12 col-md-12 col-lg-8 col-xl-9'>
                        <div className='title-effect'>
                            <p> EMPOWERING &nbsp;
                                <span>
                                    YOUR DIGITAL JOURNEY IN THE
                                </span> &nbsp;
                                WEB3 &nbsp;
                                <span>
                                    WORLD
                                </span>
                            </p>
                        </div>
                        <div className='owner-description'>
                            At xEmployed, we believe that the future of the digital world lies in Web3.
                            That&apos;s why we&apos;re dedicated to empowering individuals and businesses alike to unlock the full potential of this revolutionary technology.
                        </div>
                    </div>
                    <div className='right-contact-container col-sm-12 col-md-12 col-lg-4 col-xl-3'>
                        <a
                            href='https://t.me/egldwomen'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <div className='contact-us-btn'>
                                Contact Us
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className='xemployed-follow-us'>
                <div className='follow-title-container'>
                    <div className='title-effect'>
                        <p> FOLLOW US &nbsp;
                            <span>
                                ON TWITTER TO KEEP
                            </span> &nbsp;
                            UPDATED &nbsp;
                            <span>
                                WITH THE LAST &nbsp;
                            </span>
                            EVENTS
                        </p>
                    </div>
                </div>
                <div className='twitter-container row'>
                    <div className='single-container col-sm-12 col-md-12 col-lg-6'>
                        <TwitterEmbed url='https://twitter.com/egldwomen/status/1634097348363300869' width={550} height={500} />
                    </div>
                    <div className='single-container col-sm-12 col-md-12 col-lg-6'>
                        <TwitterEmbed url='https://twitter.com/egldwomen/status/1633545037123387393' width={550} height={500} />
                    </div>
                </div>
            </div>
            <div className='xemployed-questions'>
                <div className='questions-title-container'>
                    <div className='title-effect'>
                        <p> QUESTIONS? &nbsp;
                            <span>
                                MAYBE YOU CAN FIND AN
                            </span> &nbsp;
                            ANSWER &nbsp;
                            <span>
                                HERE
                            </span>
                        </p>
                    </div>
                </div>
                <div className='questions-accordions'>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='question-summary'>
                                What is xEmployed?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <Divider className='question-detail-divider' />
                            <Typography className='question-detail-text'>
                                xEmployed is an innovative web platform designed to revolutionize the way job seekers and employers connect.
                                <br /> <br />
                                With features such as candidate sorting based on reviews and personalized job recommendations,
                                xEmployed streamlines the hiring process and empowers job seekers to find their ideal position.
                                <br /> <br />
                                With xEmployed, finding the right candidate or the perfect job has never been easier.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='question-summary'>
                                What is Avenue?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <Divider className='question-detail-divider' />
                            <Typography className='question-detail-text'>
                                The Avenue function was introduced to guarantee the greatest MultiversX Network authors very high visibility.
                                <br /> <br />
                                Showcasing their talents, their contributions to the area and the community, and telling in-depth tales about their life,
                                work, and creation in MultiversX
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='question-summary'>
                                How can I post an article on Avenue?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <Divider className='question-detail-divider' />
                            <Typography className='question-detail-text'>
                                Avenue section of our platform is a place designed for creators that want to showcase their writing skills. Posting an article on Avenue is free for everyone.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='question-summary'>
                                How can I post my Skills?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <Divider className='question-detail-divider' />
                            <Typography className='question-detail-text'>
                                Once you&apos;ve connected to your profile, you can customize it with relevant experience information. You can also use the Hire Me feature to increase your visibility.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='question-summary'>
                                How can I post a Job?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <Divider className='question-detail-divider' />
                            <Typography className='question-detail-text'>
                                If you are looking for your next talent to join your team, you can post a job. You will be able to see the option in the menu after you connect your wallet to xemployed.io.
                                <br /><br /> You can choose between normal and featured job announcement.
                                <br /><br /> Normal means that your job announcement will stay under the featured job announcements for a period of 30 days.
                                <br /><br /> After the regular period expires, the job announcement will be automatically deleted. At the moment, this job listing is free for everyone.
                                <br /><br /> Promotion available between 15 - 29 March 2023.
                                <br /><br /> Featured means that your job announcement will be more visible by sticky your post to the top for a period of 45 days.
                                <br /><br /> After the featured period expires, the job announcement will be automatically deleted.
                                <br /><br /> At the moment, this job listing is discounted at a price of 50 USDC. Promotion available between 15 - 29 March 2023.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='question-summary'>
                                What is Hire Me feature?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <Divider className='question-detail-divider' />
                            <Typography className='question-detail-text'>
                                This feature will offer you the possibility to highlight that you are open for work and also will make you eligible to be added in Teams.
                                <br /><br /> You can get the Hire Me feature by paying a fee of 15 USDC or by holding one of these two: 8 Tyche NFTs or 8 BLXC NFTs.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='question-summary'>
                                What is Teams?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <Divider className='question-detail-divider' />
                            <Typography className='question-detail-text'>
                                A diverse team is the key to successful brainstorming session & ideas implementation. In order to provide equal exploration chances to each user, we created a premium feature called &apos;Create a Team&apos;.
                                <br /><br /> This is a premium feature (300 USDC) that will offer you consultancy from us and a place in which you can create your team all in one place and start building.
                                <br /><br /> Please, note that this feature will be fully functional after we surpass the MVP stage.
                                We are preparing something that will help you best manage your communication with the team.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};