import React, { useState, useEffect, useContext } from 'react';
import swal from 'sweetalert';

import { MdAccessTimeFilled } from 'react-icons/md';
import { useGetAccountInfo, useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { ArgSerializer, BigUIntValue, BytesValue, TransactionPayload, U32Value, BooleanValue, TokenPayment } from '@multiversx/sdk-core/out';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions/useGetPendingTransactions';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import BigNumber from 'bignumber.js/bignumber.js';

import EmptyProposals from 'assets/img/empty-proposals.svg';
import { DirectHireContext, DirectTeamContext, TeamContext, TransactionHappenContext, ProfileContext, JobProposalContext, JobContext } from 'App';
import { CONTRACT_ADDRESS } from 'config';
import { activeEmojis, departmentType } from 'mockdata';

import './index.scss';

interface ITeamInfo {
    teamId: number;
    teamIcon: number;
    teamName: string;
    packageSize: number;
    emailContact: string;
    teamDescription: string;
    teamCreator: string;
    teamCreatedTime: number;
    teamMembers: number[];
}

interface IJobInfo {
    jobId: number;
    companyName: string;
    companyLogo: string;
    jobDescription: string;
    jobTitle: string;
    salary: number;
    department: number;
    jobType: string;
    twitter: string;
    discord: string;
    featured: boolean;
    creator: string;
    createdTime: number;
}
interface ITalentInfo {
    profileId: number;
    firstName: string;
    lastName: string;
    summary: string;
    avatar: string;
    jobTitle: string;
    department: number;
    openToWork: boolean;
    lastPaidTime: number;
    minBudget: number;
    skills: string;
    twitter: string;
    discord: string;
    linkedin: string;
    behance: string;
    github: string;
    medium: string;
    pinterest: string;
    facebook: string;
    youtube: string;
    experience: string[];
    creator: string;
    createdTime: number;
}
interface IDirectHire {
    directHireId: number;
    jobTitle: string;
    contact: string;
    content: string;
    receiver: string;
    sender: string;
    sendTime: number;
}

interface IDirectTeam {
    directTeamId: number;
    invitedTeam: number;
    requestReceiver: string;
    requestSender: string;
    requestTime: number;
}

interface IJobProposal {
    proposalId: number;
    jobId: number;
    receiver: string;
    submitter: string;
    submittedTime: number;
}

export const Proposals = () => {

    const { address } = useGetAccountInfo();

    const directHires = useContext(DirectHireContext);
    const directTeams = useContext(DirectTeamContext);
    const teams = useContext(TeamContext);
    const profiles = useContext(ProfileContext);
    const proposals = useContext(JobProposalContext);
    const jobs = useContext(JobContext);

    const [directHireProposals, setDirectHireProposals] = useState<IDirectHire[]>([]);
    const [directTeamProposals, setDirectTeamProposals] = useState<IDirectTeam[]>([]);
    const [jobProposals, setJobProposals] = useState<IJobProposal[]>([]);

    const { hasPendingTransactions } = useGetPendingTransactions();
    const { transactionHappen, setTransactionHappen } = useContext(TransactionHappenContext);
    const [transactionType, setTransactionType] = useState('');
    const [sessionId, setSessionId] = useState<string>('');

    const transactionStatus = useTrackTransactionStatus({ transactionId: sessionId });
    const handleRemoveDirectHireProposal = async (proposalId: number) => {
        setTransactionType('remove-direct-hire');

        const args = [
            new U32Value(proposalId),
        ];

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`removeDirectHireRequest@${argumentsString}`);

        const tx = {
            value: 0,
            receiver: CONTRACT_ADDRESS,
            data: data.toString(),
            gasLimit: 20000000,
        };
        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        console.log(result);
        setSessionId(result.sessionId);
    };

    const handleRejectTeam = async (directTeamId: number) => {
        setTransactionType('reject-direct-team');

        const args = [
            new U32Value(directTeamId),
        ];

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`declineDirectTeamRequest@${argumentsString}`);

        const tx = {
            value: 0,
            receiver: CONTRACT_ADDRESS,
            data: data.toString(),
            gasLimit: 20000000,
        };
        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        console.log(result);
        setSessionId(result.sessionId);
    };

    const handleJoinTeam = async (directTeamId: number, team_id: number, my_profile_id: number) => {
        setTransactionType('accept-direct-team');

        const args = [
            new U32Value(directTeamId),
            new U32Value(team_id),
            new U32Value(my_profile_id),
        ];

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`acceptDirectTeamRequest@${argumentsString}`);

        const tx = {
            value: 0,
            receiver: CONTRACT_ADDRESS,
            data: data.toString(),
            gasLimit: 20000000,
        };
        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        console.log(result);
        setSessionId(result.sessionId);
    };

    const handleDeleteProposal = async (proposalId: number) => {
        setTransactionType('remove-job-proposal');

        const args = [
            new U32Value(proposalId),
        ];

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`removeJobProposal@${argumentsString}`);

        const tx = {
            value: 0,
            receiver: CONTRACT_ADDRESS,
            data: data.toString(),
            gasLimit: 20000000,
        };
        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        console.log(result);
        setSessionId(result.sessionId);
    };

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            if (transactionType === 'remove-direct-hire') {
                setTransactionHappen(!transactionHappen);
                swal('Success', 'You successfully removed the direct hire request!', 'success');
            } else if (transactionType === 'accept-direct-team') {
                setTransactionHappen(!transactionHappen);
                swal('Success', 'You successfully joined the team!', 'success');
            } else if (transactionType === 'reject-direct-team') {
                setTransactionHappen(!transactionHappen);
                swal('Success', 'You successfully rejected join to team!', 'success');
            } else if (transactionType === 'remove-job-proposal') {
                setTransactionHappen(!transactionHappen);
                swal('Success', 'You successfully removed a job proposal!', 'success');
            }
        }
    }, [sessionId, hasPendingTransactions]);

    useEffect(() => {
        setDirectHireProposals(directHires.filter((item: IDirectHire) => item.receiver === address));
        setDirectTeamProposals(directTeams.filter((item: IDirectTeam) => item.requestReceiver === address));
        setJobProposals(proposals.filter((item: IJobProposal) => item.receiver === address));
        // console.log('My Proposals', proposals.filter((item: IJobProposal) => item.receiver === address));
    }, [directHires, directTeams]);

    return (
        <div className='all-proposals'>
            {
                (directHireProposals.length === 0 && directTeamProposals.length === 0 && jobProposals.length === 0) ? (
                    <div className='proposals-empty'>
                        <img src={EmptyProposals} />
                        <p> No Proposals Received Yet... </p>
                    </div>
                ) : (
                    <div className='proposals-container'>
                        {
                            (directHireProposals.length === 0) ? (
                                <></>
                            ) : (
                                <div className='collaboration-requests'>
                                    <div className='up-request-type'>
                                        Collaborations Requests
                                    </div>
                                    <div className='requests-container'>
                                        {
                                            directHireProposals.map((proposal: IDirectHire, index: number) => {
                                                return (
                                                    <div key={index} className='single-request'>
                                                        <div className='department-type'>
                                                            <div className='left-title'>
                                                                Department
                                                            </div>
                                                            <div className='right-detail'>
                                                                {proposal.jobTitle}
                                                            </div>
                                                        </div>
                                                        <div className='contact-info'>
                                                            <div className='left-title'>
                                                                Contact
                                                            </div>
                                                            <div className='right-detail'>
                                                                {proposal.contact}
                                                            </div>
                                                        </div>
                                                        <div className='proposal-detail'>
                                                            {proposal.content}
                                                        </div>
                                                        <div className='remove-container'>
                                                            <div
                                                                className='remove-btn'
                                                                onClick={() => handleRemoveDirectHireProposal(proposal.directHireId)}
                                                            >
                                                                Delete
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }

                        {
                            directTeamProposals.length === 0 ? (
                                <></>
                            ) : (
                                <div className='team-requests'>
                                    <div className='up-request-type'>
                                        Team Requests
                                    </div>
                                    <div className='requests-container'>
                                        {
                                            directTeamProposals?.map((proposal: IDirectTeam, index: number) => {
                                                const requestedTeam: ITeamInfo = teams?.filter((teamItem: ITeamInfo) => teamItem.teamId === proposal.invitedTeam)[0];
                                                // console.log(requestedTeam);

                                                return (
                                                    <div key={index} className='single-request row'>
                                                        <div className='left-team-members col-sm-12 col-md-12 col-lg-5 col-xl-4'>
                                                            <img src={activeEmojis[requestedTeam?.teamIcon]} />
                                                            <div className='team-members'>
                                                                <div className='top-title'>
                                                                    {requestedTeam?.teamName}
                                                                </div>
                                                                <div className='bottom-photos'>
                                                                    {
                                                                        requestedTeam?.teamMembers?.map((teamMember: any, teamIndex: number) => {
                                                                            return (
                                                                                <img key={teamIndex} src={profiles?.filter((talent: ITalentInfo) => talent.profileId === teamMember.toNumber())[0].avatar} />
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='right-team-detail col-sm-12 col-md-12 col-lg-7 col-xl-8'>
                                                            <div className='top-team-description'>
                                                                {requestedTeam?.teamDescription}
                                                            </div>
                                                            <div className='bottom-btns'>
                                                                <div
                                                                    className='left-join-btn'
                                                                    onClick={() => handleJoinTeam(proposal.directTeamId, requestedTeam?.teamId, profiles?.filter((talent: ITalentInfo) => talent.creator === address)[0].profileId)}
                                                                >
                                                                    Join
                                                                </div>
                                                                <div
                                                                    className='right-reject-btn'
                                                                    onClick={() => handleRejectTeam(proposal.directTeamId)}
                                                                >
                                                                    Reject
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }

                        {
                            jobProposals.length === 0 ? (
                                <></>
                            ) : (
                                <div className='job-proposals'>
                                    <div className='up-request-type'>
                                        Job Candidates
                                    </div>
                                    <div className='requests-container'>
                                        {
                                            jobProposals?.map((proposal: IJobProposal, proposalIndex: number) => {
                                                const candidateInfo: ITalentInfo = profiles?.filter((talent: ITalentInfo) => talent.creator === proposal.submitter)[0];
                                                const appliedJob: IJobInfo = jobs?.filter((job: IJobInfo) => job.jobId === proposal.jobId)[0];
                                                // console.log('candidate', candidateInfo);

                                                return (
                                                    <div key={proposalIndex} className='single-request row'>
                                                        <div className='left-candidate-info col-sm-12 col-md-12 col-lg-6 col-xl-4'>
                                                            <img src={candidateInfo.avatar} />
                                                            <div className='right-info'>
                                                                <div className='top-name'>
                                                                    {candidateInfo.firstName + ' ' + candidateInfo.lastName}
                                                                </div>
                                                                <div className='bottom-applied'>
                                                                    Applied For {appliedJob?.jobTitle}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='right-job-info col-sm-12 col-md-12 col-lg-6 col-xl-8'>
                                                            <div className='job-title'>
                                                                {appliedJob?.jobTitle} / {departmentType[appliedJob?.department]}
                                                            </div>
                                                            <div className='job-type'>
                                                                <MdAccessTimeFilled className='time-icon' />
                                                                {appliedJob?.jobType}
                                                            </div>
                                                            <div className='delete-container'>
                                                                <div
                                                                    className='delete-btn'
                                                                    onClick={() => handleDeleteProposal(proposal.proposalId)}
                                                                >
                                                                    Delete
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};