import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

import DefiWalletIcon from 'assets/img/defi-wallet-icon.svg';
import AppWalletIcon from 'assets/img/app-wallet-icon.svg';
import WebWalletIcon from 'assets/img/web-wallet-icon.svg';
import LedgerWalletIcon from 'assets/img/ledger-wallet-icon.svg';

import {
    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton
} from '@multiversx/sdk-dapp/UI';

import { AuthRedirectWrapper } from 'components';
import { walletConnectV2ProjectId } from 'config';
import { routeNames } from 'routes';

import './Unlock.scss';

const UnlockPage = () => {

    const commonProps = {
        callbackRoute: routeNames.home,
        nativeAuth: true
    };

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    

    return (
        <div className='home d-flex flex-fill align-items-center'>
            <div className='main-connection-card'>
                <h4 className='mb-15 connection-label'>
                    Connect your wallet
                </h4>
                <div className='wallet-connection-description'>
                    To continue, you&apos;ll need a wallet on MultiversX; choose your favorite.
                </div>
                <div className='wallet-connect-container row'>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='connection-btn'>
                            <div className='flex-div'>
                                <img
                                    src={DefiWalletIcon}
                                    alt='Maiar Defi Wallet'
                                />
                                <span>MultiversX DeFi Wallet</span>
                            </div>
                            <ExtensionLoginButton
                                loginButtonText='Extension'
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='connection-btn'>
                            <div className='flex-div'>
                                <img
                                    src={AppWalletIcon}
                                    alt='Maiar Defi Wallet'
                                    style={{
                                        width: '22px'
                                    }}
                                />
                                <span>xPortal Mobile Wallet</span>
                            </div>
                            <WalletConnectLoginButton
                                loginButtonText='Maiar'
                                {...commonProps}
                                {...(walletConnectV2ProjectId
                                    ? {
                                        isWalletConnectV2: true
                                    }
                                    : {})}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='connection-btn'>
                            <div className='flex-div'>
                                <img
                                    src={WebWalletIcon}
                                    alt='Maiar Defi Wallet'
                                />
                                <span>MultiversX Web Wallet</span>
                            </div>
                            <WebWalletLoginButton
                                loginButtonText='Web wallet'
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='connection-btn'>
                            <div className='flex-div'>
                                <img
                                    src={LedgerWalletIcon}
                                    alt='Maiar Defi Wallet'
                                />
                                <span>Ledger</span>
                            </div>
                            <LedgerLoginButton
                                loginButtonText='Ledger'
                                className='test-class_name'
                                {...commonProps}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Unlock = () => (
    <AuthRedirectWrapper>
        <UnlockPage />
    </AuthRedirectWrapper>
);
