import emoji_1_disable from 'assets/img/xMojis/xmoji-1-disable.png';
import emoji_2_disable from 'assets/img/xMojis/xmoji-2-disable.png';
import emoji_3_disable from 'assets/img/xMojis/xmoji-3-disable.png';
import emoji_4_disable from 'assets/img/xMojis/xmoji-4-disable.png';
import emoji_5_disable from 'assets/img/xMojis/xmoji-5-disable.png';
import emoji_6_disable from 'assets/img/xMojis/xmoji-6-disable.png';
import emoji_7_disable from 'assets/img/xMojis/xmoji-7-disable.png';
import emoji_8_disable from 'assets/img/xMojis/xmoji-8-disable.png';
import emoji_9_disable from 'assets/img/xMojis/xmoji-9-disable.png';
import emoji_10_disable from 'assets/img/xMojis/xmoji-10-disable.png';
import emoji_11_disable from 'assets/img/xMojis/xmoji-11-disable.png';
import emoji_12_disable from 'assets/img/xMojis/xmoji-12-disable.png';

import emoji_1_active from 'assets/img/xMojis/xmoji-1-active.png';
import emoji_2_active from 'assets/img/xMojis/xmoji-2-active.png';
import emoji_3_active from 'assets/img/xMojis/xmoji-3-active.png';
import emoji_4_active from 'assets/img/xMojis/xmoji-4-active.png';
import emoji_5_active from 'assets/img/xMojis/xmoji-5-active.png';
import emoji_6_active from 'assets/img/xMojis/xmoji-6-active.png';
import emoji_7_active from 'assets/img/xMojis/xmoji-7-active.png';
import emoji_8_active from 'assets/img/xMojis/xmoji-8-active.png';
import emoji_9_active from 'assets/img/xMojis/xmoji-9-active.png';
import emoji_10_active from 'assets/img/xMojis/xmoji-10-active.png';
import emoji_11_active from 'assets/img/xMojis/xmoji-11-active.png';
import emoji_12_active from 'assets/img/xMojis/xmoji-12-active.png';

export const disableEmojis = [
    emoji_1_disable,
    emoji_2_disable,
    emoji_3_disable,
    emoji_4_disable,
    emoji_5_disable,
    emoji_6_disable,
    emoji_7_disable,
    emoji_8_disable,
    emoji_9_disable,
    emoji_10_disable,
    emoji_11_disable,
    emoji_12_disable,
];

export const activeEmojis = [
    emoji_1_active,
    emoji_2_active,
    emoji_3_active,
    emoji_4_active,
    emoji_5_active,
    emoji_6_active,
    emoji_7_active,
    emoji_8_active,
    emoji_9_active,
    emoji_10_active,
    emoji_11_active,
    emoji_12_active,
];

export const departmentType = [
    'Graphic & Design',
    'Digital Marketing',
    'Writing & Translation',
    'Video & Animation',
    'Music & Audio',
    'Programming & Tech',
    'Business',
    'LifeStyle',
    'AI Services'
];

export const allJobs = [
    {
        jobId: 1,
        walletAddress: 'erd1yy09mx2ccx3tf5jehgxjv2xx0fhupp3w84easpur8jk34xlqaxrq7j7gyn',
        jobTitle: 'E-Commerce manager',
        department: 7,
        companyName: 'Bored Ladies X Club',
        companyLogo: 'https://gateway.pinata.cloud/ipfs/QmZ3yiCanDxCYPmaxEeV61oRhYBhUVStoefJAUzHBFCKJU',
        salary: 2000,
        jobType: 'Part Time',
        jobDescription: 'We are seeking an experienced E-commerce Manager to lead and grow our online sales channels. The successful candidate will be responsible for managing our e-commerce website, optimizing product listings, and creating digital marketing campaigns to increase online sales. The E-commerce Manager will also be responsible for monitoring online sales performance, analyzing customer data, and implementing strategies to improve customer retention.',
        twitter: 'https://twitter.com/xagendaio',
        discord: 'https://discord.com/invite/w2tpQqEk6X',
        createdTime: '2/14/2023',
    },
    {
        jobId: 2,
        walletAddress: 'erd1qfmka23vvxz7e30a294dj06cg0xqjwkpfp0hydmqnw6jqz3ye40qtvy75u',
        jobTitle: 'Social Media Manager',
        department: 2,
        companyName: 'xAgenda',
        companyLogo: 'https://gateway.pinata.cloud/ipfs/Qme3WVfA4CN6ofUqL9t2i6mAgnsYFbceBMc6poNBmUq8aq',
        salary: 1500,
        jobType: 'Full Time',
        jobDescription: 'We are seeking an experienced Social Media Manager to lead and grow our company social media presence. The successful candidate will be responsible for developing and executing social media strategies, managing social media accounts, and creating engaging content to drive brand awareness and customer engagement. The Social Media Manager will also be responsible for monitoring social media performance, analyzing customer data, and implementing strategies to improve social media presence and engagement.',
        twitter: 'https://twitter.com/xagendaio',
        discord: 'https://discord.com/invite/w2tpQqEk6X',
        createdTime: '1/19/2023',
    },
    {
        jobId: 3,
        walletAddress: 'erd1yy09mx2ccx3tf5jehgxjv2xx0fhupp3w84easpur8jk34xlqaxrq7j7gyn',
        jobTitle: 'E-Commerce manager',
        department: 7,
        companyName: 'Bored Ladies X Club',
        companyLogo: 'https://gateway.pinata.cloud/ipfs/QmZ3yiCanDxCYPmaxEeV61oRhYBhUVStoefJAUzHBFCKJU',
        salary: 4000,
        jobType: 'Full Time',
        jobDescription: 'We are seeking an experienced E-commerce Manager to lead and grow our online sales channels. The successful candidate will be responsible for managing our e-commerce website, optimizing product listings, and creating digital marketing campaigns to increase online sales. The E-commerce Manager will also be responsible for monitoring online sales performance, analyzing customer data, and implementing strategies to improve customer retention.',
        twitter: 'https://twitter.com/xagendaio',
        discord: 'https://discord.com/invite/w2tpQqEk6X',
        createdTime: '3/1/2023',
    },
    {
        jobId: 4,
        walletAddress: 'erd1gu3qaywzhj7npg56n6amj8glzup7d9f3ypcvalnmsjczhajsj57qlkrwla',
        jobTitle: 'Social Media Manager',
        department: 2,
        companyName: 'xAgenda',
        companyLogo: 'https://gateway.pinata.cloud/ipfs/Qme3WVfA4CN6ofUqL9t2i6mAgnsYFbceBMc6poNBmUq8aq',
        salary: 3000,
        jobType: 'Full Time',
        jobDescription: 'We are seeking an experienced E-commerce Manager to lead and grow our online sales channels. The successful candidate will be responsible for managing our e-commerce website, optimizing product listings, and creating digital marketing campaigns to increase online sales. The E-commerce Manager will also be responsible for monitoring online sales performance, analyzing customer data, and implementing strategies to improve customer retention.',
        twitter: 'https://twitter.com/xagendaio',
        discord: 'https://discord.com/invite/w2tpQqEk6X',
        createdTime: '2/15/2023',
    },
];

export const allTalents = [
    {
        profileId: 1,
        walletAddress: 'erd149axj8feledcw7zck5f3ecwrncgd0gemcr9q69yxqlk0zvnl5zvs065jqu',
        firstName: 'Sarah',
        lastName: 'Davis',
        summary: 'As an experienced Web Content Manager, I have a proven track record of developing and implementing successful content strategies across multiple industries. I possess a strong background in web development and SEO, which allows me to create engaging and optimized content that drives traffic and achieves business objectives. My skills in project management, content creation, and analytics enable me to deliver high-quality work that meets the needs of both internal and external stakeholders. I am a strategic thinker and a collaborative team player, committed to staying abreast of the latest trends and best practices in web content management. With my strong work ethic and dedication to excellence, I am confident in my ability to drive results and contribute to the success of any organization.',
        jobTitle: 'Data Engineering',
        department: 6,
        avatar: 'https://gateway.pinata.cloud/ipfs/QmPpr4u7wGq4epuGvTbEb4ceKZaGQPP5NvniMuLqBvqNKE',
        openToWork: true,
        reviews: 3,
        minBudget: 1500,
        skills: 'Content Creation;Search Engine Optimization;Project Management;Analytics;Collaboration;Attention to Detail;Communication;',
        twitter: 'https://twitter.com/marmosa',
        discord: '',
        linkedin: 'https://linkedin.com/in/marmosa',
        behance: '',
        github: '',
        medium: '',
        pinterest: '',
        facebook: '',
        youtube: '',
        experience: [
            'Web Content Manager;Full Time;Women Of MultiversX;2/11/2019;5/23/2020',
            'Content Strategist;Part Time;Bored Ladies X Club;7/20/2020;9/25/2022',
        ],
    },
    {
        profileId: 2,
        walletAddress: 'erd1yy09mx2ccx3tf5jehgxjv2xx0fhupp3w84easpur8jk34xlqaxrq7j7gyn',
        firstName: 'Jessica',
        lastName: 'Martin',
        summary: 'As an experienced Web Content Manager, I have a proven track record of developing and implementing successful content strategies across multiple industries. I possess a strong background in web development and SEO, which allows me to create engaging and optimized content that drives traffic and achieves business objectives. My skills in project management, content creation, and analytics enable me to deliver high-quality work that meets the needs of both internal and external stakeholders. I am a strategic thinker and a collaborative team player, committed to staying abreast of the latest trends and best practices in web content management. With my strong work ethic and dedication to excellence, I am confident in my ability to drive results and contribute to the success of any organization.',
        jobTitle: 'Tax Consulting',
        department: 7,
        avatar: 'https://gateway.pinata.cloud/ipfs/QmdA7kCSWhKWn63mJCkMDReZWwm1sF6GRTcrPmcbTi9sHC',
        openToWork: false,
        reviews: 4,
        minBudget: 500,
        skills: 'Content Creation;Search Engine Optimization;Project Management;Analytics;Collaboration;Attention to Detail;Communication;',
        twitter: 'https://twitter.com/urbanlover',
        discord: '',
        linkedin: 'https://linkedin.com/in/urbanlover',
        behance: '',
        github: '',
        medium: '',
        pinterest: '',
        facebook: '',
        youtube: '',
        experience: [
            'Web Content Manager;Full Time;Women Of MultiversX;2/11/2019;5/23/2020',
            'Content Strategist;Part Time;Bored Ladies X Club;7/20/2020;9/25/2022',
        ],
    },
    {
        profileId: 3,
        walletAddress: 'erd1tzjempmpvy7g69uwhadfttvaduwwh37ld3wq2vw0l3cuye94z3msfgp78w',
        firstName: 'Elizabeth',
        lastName: 'Taylor',
        summary: 'As an experienced Web Content Manager, I have a proven track record of developing and implementing successful content strategies across multiple industries. I possess a strong background in web development and SEO, which allows me to create engaging and optimized content that drives traffic and achieves business objectives. My skills in project management, content creation, and analytics enable me to deliver high-quality work that meets the needs of both internal and external stakeholders. I am a strategic thinker and a collaborative team player, committed to staying abreast of the latest trends and best practices in web content management. With my strong work ethic and dedication to excellence, I am confident in my ability to drive results and contribute to the success of any organization.',
        jobTitle: 'Social Media Manager',
        department: 2,
        avatar: 'https://gateway.pinata.cloud/ipfs/QmPVZ6pKEzPGJbAHe8zpjwL3iDXAZ53tNGW4e6wyoC77j1',
        openToWork: false,
        reviews: 5,
        minBudget: 1200,
        skills: 'Content Creation;Search Engine Optimization;Project Management;Analytics;Collaboration;Attention to Detail;Communication;',
        twitter: 'https://twitter.com/flowerpower',
        discord: '',
        linkedin: 'https://linkedin.com/in/flowerpower',
        behance: '',
        github: '',
        medium: '',
        pinterest: '',
        facebook: '',
        youtube: '',
        experience: [
            'Web Content Manager;Full Time;Women Of MultiversX;2/11/2019;5/23/2020',
            'Content Strategist;Part Time;Bored Ladies X Club;7/20/2020;9/25/2022',
        ],
    },
    {
        profileId: 4,
        walletAddress: 'erd1jlt0qpcr4rcdvuw20sw0afe3pnvl6k2n29xgjnwqk9uyywm3m9ks4nkmd6',
        firstName: 'James',
        lastName: 'Wilson',
        summary: 'As an experienced Web Content Manager, I have a proven track record of developing and implementing successful content strategies across multiple industries. I possess a strong background in web development and SEO, which allows me to create engaging and optimized content that drives traffic and achieves business objectives. My skills in project management, content creation, and analytics enable me to deliver high-quality work that meets the needs of both internal and external stakeholders. I am a strategic thinker and a collaborative team player, committed to staying abreast of the latest trends and best practices in web content management. With my strong work ethic and dedication to excellence, I am confident in my ability to drive results and contribute to the success of any organization.',
        jobTitle: 'Full Stack Developer',
        department: 6,
        avatar: 'https://gateway.pinata.cloud/ipfs/QmWqwMv8LHnb6zzXJa5rSMq3PvfXd76ufyp6F2W1YE3Nj6',
        openToWork: true,
        reviews: 3,
        minBudget: 800,
        skills: 'Content Creation;Search Engine Optimization;Project Management;Analytics;Collaboration;Attention to Detail;Communication;',
        twitter: 'https://twitter.com/dianosour',
        discord: '',
        linkedin: 'https://linkedin.com/in/dianosour',
        behance: '',
        github: '',
        medium: '',
        pinterest: '',
        facebook: '',
        youtube: '',
        experience: [
            'Web Content Manager;Full Time;Women Of MultiversX;2/11/2019;5/23/2020',
            'Content Strategist;Part Time;Bored Ladies X Club;7/20/2020;9/25/2022',
        ],
    },
    {
        profileId: 5,
        walletAddress: 'erd1qfmka23vvxz7e30a294dj06cg0xqjwkpfp0hydmqnw6jqz3ye40qtvy75u',
        firstName: 'Emily',
        lastName: 'Williams',
        summary: 'As an experienced Web Content Manager, I have a proven track record of developing and implementing successful content strategies across multiple industries. I possess a strong background in web development and SEO, which allows me to create engaging and optimized content that drives traffic and achieves business objectives. My skills in project management, content creation, and analytics enable me to deliver high-quality work that meets the needs of both internal and external stakeholders. I am a strategic thinker and a collaborative team player, committed to staying abreast of the latest trends and best practices in web content management. With my strong work ethic and dedication to excellence, I am confident in my ability to drive results and contribute to the success of any organization.',
        jobTitle: 'E-Commerce Manager',
        department: 7,
        avatar: 'https://gateway.pinata.cloud/ipfs/QmXCUwDEobC9FM1hromaFe5e6joPUQa6kPr7H1jDCMdPvN',
        openToWork: false,
        reviews: 4,
        minBudget: 3200,
        skills: 'Content Creation;Search Engine Optimization;Project Management;Analytics;Collaboration;Attention to Detail;Communication;',
        twitter: 'https://twitter.com/andreea',
        discord: '',
        linkedin: 'https://linkedin.com/in/andreea',
        behance: '',
        github: '',
        medium: '',
        pinterest: '',
        facebook: '',
        youtube: '',
        experience: [
            'Web Content Manager;Full Time;Women Of MultiversX;2/11/2019;5/23/2020',
            'Content Strategist;Part Time;Bored Ladies X Club;7/20/2020;9/25/2022',
        ],
    },
    {
        profileId: 6,
        walletAddress: 'erd1gu3qaywzhj7npg56n6amj8glzup7d9f3ypcvalnmsjczhajsj57qlkrwla',
        firstName: 'Michael',
        lastName: 'Johnson',
        summary: 'As an experienced Web Content Manager, I have a proven track record of developing and implementing successful content strategies across multiple industries. I possess a strong background in web development and SEO, which allows me to create engaging and optimized content that drives traffic and achieves business objectives. My skills in project management, content creation, and analytics enable me to deliver high-quality work that meets the needs of both internal and external stakeholders. I am a strategic thinker and a collaborative team player, committed to staying abreast of the latest trends and best practices in web content management. With my strong work ethic and dedication to excellence, I am confident in my ability to drive results and contribute to the success of any organization.',
        jobTitle: 'Web Content Creator',
        department: 2,
        avatar: 'https://gateway.pinata.cloud/ipfs/QmXuXUvs2nuRfXzRj7Zhb8FJ1d213H1uWYkhM6ScEN3u9d',
        openToWork: true,
        reviews: 5,
        minBudget: 200,
        skills: 'Content Creation;Search Engine Optimization;Project Management;Analytics;Collaboration;Attention to Detail;Communication;',
        twitter: 'https://twitter.com/abracadabra',
        discord: '',
        linkedin: 'https://linkedin.com/in/abracadabra',
        behance: '',
        github: '',
        medium: '',
        pinterest: '',
        facebook: '',
        youtube: '',
        experience: [
            'Web Content Manager;Full Time;Women Of MultiversX;2/11/2019;5/23/2020',
            'Content Strategist;Part Time;Bored Ladies X Club;7/20/2020;9/25/2022',
        ],
    }
];

export const allReviews = [
    {
        reviewId: 0,
        receiverAddress: 'erd149axj8feledcw7zck5f3ecwrncgd0gemcr9q69yxqlk0zvnl5zvs065jqu',
        reviewerAddress: 'erd1tzjempmpvy7g69uwhadfttvaduwwh37ld3wq2vw0l3cuye94z3msfgp78w',
        jobTitle: 'Social Media Marketing',
        reviewDetail: 'She knows how to create content that resonates with my target audience and my social media presence has grown significantly as a result. Highly recommend!',
        reviewPoint: 5,
    },
    {
        reviewId: 1,
        receiverAddress: 'erd149axj8feledcw7zck5f3ecwrncgd0gemcr9q69yxqlk0zvnl5zvs065jqu',
        reviewerAddress: 'erd1gu3qaywzhj7npg56n6amj8glzup7d9f3ypcvalnmsjczhajsj57qlkrwla',
        jobTitle: 'Rebranding my Brand',
        reviewDetail: 'She transformed my raw footage into a polished, professional-looking video that exceeded my expectations. Highly recommend their video editing services!',
        reviewPoint: 4,
    },
    {
        reviewId: 2,
        receiverAddress: 'erd1jlt0qpcr4rcdvuw20sw0afe3pnvl6k2n29xgjnwqk9uyywm3m9ks4nkmd6',
        reviewerAddress: 'erd1gu3qaywzhj7npg56n6amj8glzup7d9f3ypcvalnmsjczhajsj57qlkrwla',
        jobTitle: 'Rebranding my Brand',
        reviewDetail: 'She transformed my raw footage into a polished, professional-looking video that exceeded my expectations. Highly recommend their video editing services!',
        reviewPoint: 5,
    }
];