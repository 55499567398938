import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga';

import { RiArrowGoBackLine } from 'react-icons/ri';
import { MdAccessTimeFilled } from 'react-icons/md';
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import FeaturedIcon from 'assets/img/featured-icon.png';

import { useGetAccountInfo, useGetIsLoggedIn, useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { ArgSerializer, AddressValue, Address, TransactionPayload, U32Value, BooleanValue, TokenPayment } from '@multiversx/sdk-core/out';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions/useGetPendingTransactions';
import { sendTransactions } from '@multiversx/sdk-dapp/services';

import { JobContext, JobProposalContext, TransactionHappenContext, ProfileContext } from 'App';
import { CONTRACT_ADDRESS } from 'config';
import './index.scss';

interface IJobInfo {
    jobId: number;
    companyName: string;
    companyLogo: string;
    jobDescription: string;
    jobTitle: string;
    salary: number;
    department: number;
    jobType: string;
    twitter: string;
    discord: string;
    featured: boolean;
    creator: string;
    createdTime: number;
}

interface ITalentInfo {
    profileId: number;
    firstName: string;
    lastName: string;
    summary: string;
    avatar: string;
    jobTitle: string;
    department: number;
    openToWork: boolean;
    lastPaidTime: number;
    minBudget: number;
    skills: string;
    twitter: string;
    discord: string;
    linkedin: string;
    behance: string;
    github: string;
    medium: string;
    pinterest: string;
    facebook: string;
    youtube: string;
    experience: string[];
    creator: string;
    createdTime: number;
}
interface IJobProposal {
    proposalId: number;
    jobId: number;
    receiver: string;
    submitter: string;
    submittedTime: number;
}

export const JobView = () => {

    const { job_id } = useParams();
    const navigate = useNavigate();
    const { address } = useGetAccountInfo();
    const isLoggedIn = useGetIsLoggedIn();

    const jobs = useContext(JobContext);
    const proposals = useContext(JobProposalContext);
    const profiles = useContext(ProfileContext);
    const [jobData, setJobData] = useState<IJobInfo | null>(null);

    const { hasPendingTransactions } = useGetPendingTransactions();
    const { transactionHappen, setTransactionHappen } = useContext(TransactionHappenContext);
    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({ transactionId: sessionId });

    const handleBackExplore = () => {
        navigate('/employ', { replace: true });
    };

    const handleSubmitApply = async () => {
        if (jobData !== null) {
            const args = [
                new U32Value(jobData.jobId),
                new AddressValue(new Address(jobData.creator))
            ];

            const { argumentsString } = new ArgSerializer().valuesToString(args);
            const data = new TransactionPayload(`sendJobProposal@${argumentsString}`);

            const tx = {
                value: 0,
                receiver: CONTRACT_ADDRESS,
                data: data.toString(),
                gasLimit: 20000000,
            };
            await refreshAccount();
            const result = await sendTransactions({
                transactions: tx,
            });

            // console.log(result);
            setSessionId(result.sessionId);
        }
    };

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            setTransactionHappen(!transactionHappen);
            swal('Success', 'You successfully applied the job!', 'success');
        }
    }, [sessionId, hasPendingTransactions]);

    useEffect(() => {
        setJobData(jobs?.filter((item: IJobInfo) => item.jobId.toString() === job_id)[0]);
    }, [jobs]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className='job-view'>
            {
                jobData ? (
                    <div className='job-view-container'>
                        <div className='job-back'>
                            <div
                                className='left-go-back'
                                onClick={handleBackExplore}
                            >
                                <RiArrowGoBackLine />
                            </div>
                        </div>
                        <div className='project-primary-info'>
                            <div className='project-logo'>
                                <img src={jobData.companyLogo} />
                            </div>
                            {
                                jobData.featured ? (
                                    <div className='featured-icon'>
                                        <img src={FeaturedIcon} />
                                        FEATURED
                                    </div>
                                ) : (
                                    <></>
                                )
                            }
                            <div className='project-primary'>
                                <div className='project-name'>
                                    {jobData.companyName.toUpperCase()}
                                </div>
                                <div className='project-job-title'>
                                    {jobData.jobTitle}
                                </div>
                                <div className='project-min-budget'>
                                    Min.Package {jobData.salary} USD / Month
                                </div>
                                <div className='project-employ-type'>
                                    <MdAccessTimeFilled className='time-icon' />
                                    {jobData.jobType}
                                </div>
                                <div className='project-socials'>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href={jobData.twitter}
                                    >
                                        <FaTwitter className='social-icon' />
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href={jobData.discord}
                                    >
                                        <FaDiscord className='social-icon' />
                                    </a>
                                </div>
                                <div className='project-posted-date'>
                                    {new Date(jobData.createdTime * 1000).toDateString()}
                                </div>
                            </div>
                            <div className='project-poster'>
                                {jobData.creator.slice(0, 10) + ' ... ' + jobData.creator.slice(jobData.creator.length - 10, jobData.creator.length)}
                            </div>
                            <div className='project-description'>
                                {jobData.jobDescription}
                            </div>
                            {
                                (jobData.creator !== address && isLoggedIn && profiles?.filter((profile: ITalentInfo) => profile.creator === address).length !== 0) ? (
                                    <div>
                                        {
                                            proposals?.filter((proposal: IJobProposal) => proposal.jobId === jobData.jobId && proposal.submitter === address).length === 0 ? (
                                                < div
                                                    className='apply-btn active'
                                                    onClick={handleSubmitApply}
                                                >
                                                    Apply
                                                </div>
                                            ) : (
                                                < div className='apply-btn disable'>
                                                    Applied
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : (
                                    <div className='apply-btn disable'>
                                        Apply
                                    </div>
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <></>
                )
            }
        </div >
    );
};