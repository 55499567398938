import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';

export const API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDgzNEY2ZUEyNzFGNEUxNjlmN0UzMDMxQzMxNjU0RjFjQzE3NDc1MDEiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3ODIwNjczNTQwMiwibmFtZSI6InhlbSJ9._OD1sZMi00JiN3MuWzyKu_fvI7BHiufCouV56JAQqNs';
export const dAppName = 'xEmployed | Web3 Employee Platform';
export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';
export const apiTimeout = 6000;
export const transactionSize = 15;
export const TOOLS_API_URL = 'https://tools.multiversx.com';

export const isDev = false;
export const CONTRACT_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqgsw00peth8pzk2h4yrse2v5grhph50m48vyqa2x4z5' : 'erd1qqqqqqqqqqqqqpgqyuxedjzgkfyyct2eqly6g79elvwf98etaxrqnyaeqk';
export const CONTRACT_NAME = 'XEmployed';
export const POST_ARTICLE_FEE = 10;
export const API_URL = isDev ? 'https://devnet-api.multiversx.com' : 'https://api.multiversx.com';
export const TOKEN_IDENTIFIER = isDev ? 'USDC-8d4068' : 'USDC-c76f1f';
export const dAppUrl = 'https://xemployed.io';
export const environment = isDev ? EnvironmentsEnum.devnet : EnvironmentsEnum.mainnet;

export const REACT_APP_API_KEY = '82ee48560023af34a2c6';
export const REACT_APP_API_SECRET = '84fa22b8d54ccfbdae82637e6dd6e0b8f3918085e1010bb0638020524550b0a3';
export const PINATA_URL = 'https://api.pinata.cloud/pinning/pinFileToIPFS';
export const PINATA_GATEWAY = 'https://pink-broad-lynx-839.mypinata.cloud/ipfs/';

/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];
