import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { BsTwitter } from 'react-icons/bs';
import { RiInstagramFill } from 'react-icons/ri';
import { FaMediumM, FaFacebookF, FaDiscord } from 'react-icons/fa';

import BlxcLogo from 'assets/img/blxc_logo.svg';
import { SelectedAccountMenuContext } from 'App';
import './index.scss';

export const Footer = () => {

    const navigate = useNavigate();
    const { setSelectedAccountMenu } = useContext(SelectedAccountMenuContext);

    const handlePostNewJob = () => {
        window.scrollTo(0, 0);
        setSelectedAccountMenu(2);
        navigate('/account', { replace: true });
    };

    const handleCreateNewTeam = () => {
        window.scrollTo(0, 0);
        setSelectedAccountMenu(3);
        navigate('/account', { replace: true });
    };

    const handleHireSpecialists = () => {
        window.scrollTo(0, 0);
        navigate('/hire', { replace: true });
    };

    return (
        <footer className='app-footer'>
            <div className='top-footer row'>
                <div className='about-project col-lg-4 col-md-6 d-flex flex-column mb-4'>
                    <div className='arranged-description'>
                        <div className='xemployed-logo'>
                            <p> xEmployed
                                <span>
                                    .io
                                </span>
                            </p>
                        </div>
                        <span>
                            xEmployed is a cutting-edge employment platform that harnesses the power of technology to connect job seekers with top employers.
                            With its intuitive interface and advanced features, xEmployed simplifies the job search process and makes it easy for employers to find the best talent.
                        </span>
                        <div className='blxc-logo'>
                            Designed By
                            <img src={BlxcLogo} />
                        </div>
                    </div>
                </div>
                <div className='essential-link col-lg-4 col-md-6 d-flex flex-column mb-4'>
                    <div className='arranged-menus'>
                        <h4> Essentials </h4>
                        <a
                            href='https://t.me/egldwomen'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Contact Us
                        </a>
                        <a
                            onClick={handlePostNewJob}
                        >
                            Post A Job
                        </a>
                        <a
                            onClick={handleHireSpecialists}
                        >
                            Hire A Specialist
                        </a>
                        <a
                            onClick={handleCreateNewTeam}
                        >
                            Create A Team
                        </a>
                    </div>
                </div>
                <div className='social-link col-lg-4 col-md-12 d-flex flex-column mb-4'>
                    <div className='arranged-menus'>
                        <a
                            href='https://twitter.com/egldwomen'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <BsTwitter />
                        </a>
                        <a
                            href='https://instagram.com/egldwomen'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <RiInstagramFill />
                        </a>
                        <a
                            href=''
                            target='_blank'
                            rel='noreferrer'
                        >
                            <FaMediumM />
                        </a>
                        <a
                            href=''
                            target='_blank'
                            rel='noreferrer'
                        >
                            <FaFacebookF />
                        </a>
                        <a
                            href=''
                            target='_blank'
                            rel='noreferrer'
                        >
                            <FaDiscord />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};
