import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ImCloudUpload } from 'react-icons/im';
import { FaPencilAlt } from 'react-icons/fa';
import { HiTrash } from 'react-icons/hi2';
import { Form } from 'react-bootstrap';

import { useGetAccountInfo, useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { ArgSerializer, BigUIntValue, BytesValue, TransactionPayload, U32Value, U64Value, TokenPayment } from '@multiversx/sdk-core/out';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions/useGetPendingTransactions';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import BigNumber from 'bignumber.js/bignumber.js';

import { Backdrop, CircularProgress } from '@mui/material';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import swal from 'sweetalert';
import axios from 'axios';

import EmptyArticles from 'assets/img/empty-articles.svg';
import { REACT_APP_API_KEY, REACT_APP_API_SECRET, PINATA_URL, PINATA_GATEWAY, CONTRACT_ADDRESS, TOKEN_IDENTIFIER } from 'config';
import { ArticleContext, TransactionHappenContext } from 'App';
import './index.scss';
import { getBlxcNftCount, getTycheNftCount } from 'utils/getnfts';

const regex = /(<([^>]+)>)/gi;

interface IArticleInfo {
    articleId: number;
    coverImage: string;
    title: string;
    twitter: string;
    authorName: string;
    content: string;
    submitter: string;
    postedTime: number;
}

interface ICreateArticleInfo {
    coverImage: string;
    title: string;
    twitter: string;
    authorName: string;
    content: string;
}

export const PostArticle = () => {

    const navigate = useNavigate();

    const { address } = useGetAccountInfo();
    const articles = useContext(ArticleContext);

    const { hasPendingTransactions } = useGetPendingTransactions();
    const { transactionHappen, setTransactionHappen } = useContext(TransactionHappenContext);
    const [transactionType, setTransactionType] = useState('');
    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({ transactionId: sessionId });

    const createInputImage: any = useRef();
    const editInputImage: any = useRef();

    const [myArticleData, setMyArticleData] = useState<IArticleInfo[]>();
    const [articleEventHandler, setArticleEventHandler] = useState(0);

    const [uploadingNow, setUploadingNow] = useState(false);

    const [createArticleEditorState, setCreateArticleEditorState] = useState(EditorState.createEmpty());
    const [createArticleData, setCreateArticleData] = useState<ICreateArticleInfo>({
        coverImage: '',
        title: '',
        twitter: '',
        authorName: '',
        content: '',
    });

    const [editArticleEditorState, setEditArticleEditorState] = useState(EditorState.createEmpty());
    const [editArticleData, setEditArticleData] = useState<IArticleInfo | null>(null);

    const handleSingleArticle = (articleId: number) => {
        window.scrollTo(0, 0);
        navigate(`/article-view?${articleId}`, { replace: true });
    };

    const handlePostNewArticle = () => {
        setArticleEventHandler(1);
    };

    const handleEditExistingPost = (articleId: number) => {
        setArticleEventHandler(2);
        setEditArticleData(articles.filter((article: IArticleInfo) => article.articleId === articleId)[0]);
        setEditArticleEditorState(EditorState.createWithContent(stateFromHTML(articles.filter((article: IArticleInfo) => article.articleId === articleId)[0].content)));
    };

    const handleArticleTitleChange = (e: any) => {
        setCreateArticleData({ ...createArticleData, title: e.target.value });
    };

    const handleArticleTwitterChange = (e: any) => {
        setCreateArticleData({ ...createArticleData, twitter: e.target.value });
    };

    const handleArticleAuthorNameChange = (e: any) => {
        setCreateArticleData({ ...createArticleData, authorName: e.target.value });
    };

    const handleArticleContentChange = (editorState: any) => {
        setCreateArticleData({ ...createArticleData, content: stateToHTML(editorState.getCurrentContent()) });
        setCreateArticleEditorState(editorState);
    };

    const handleCreateArticleImage = async (event: any) => {
        const fileToHandle = event.target.files[0];

        if (fileToHandle) {
            try {
                setUploadingNow(true);
                const formData: any = new FormData();
                formData.append('file', fileToHandle);

                const API_KEY = REACT_APP_API_KEY;
                const API_SECRET = REACT_APP_API_SECRET;

                const url = PINATA_URL;

                const response = await axios.post(url, formData, {
                    headers: {
                        'Content-Type': `multipart/form-data;boundary=${formData._boundary}`,
                        pinata_api_key: API_KEY,
                        pinata_secret_api_key: API_SECRET,
                    },
                });

                const photoUrl = response.data.IpfsHash;
                setCreateArticleData({ ...createArticleData, coverImage: (PINATA_GATEWAY + photoUrl) });
                setUploadingNow(false);

            } catch (error) {
                console.log('Error sending File to IPFS: ');
                console.log(error);
            }
        }
    };

    // edit article part

    const handleEditArticleTitleChange = (e: any) => {
        if (editArticleData !== null) {
            setEditArticleData({ ...editArticleData, title: e.target.value });
        }
    };

    const handleEditArticleTwitterChange = (e: any) => {
        if (editArticleData !== null) {
            setEditArticleData({ ...editArticleData, twitter: e.target.value });
        }
    };

    const handleEditArticleAuthorNameChange = (e: any) => {
        if (editArticleData !== null) {
            setEditArticleData({ ...editArticleData, authorName: e.target.value });
        }
    };

    const handleEditArticleContentChange = (editorState: any) => {
        if (editArticleData !== null) {
            setEditArticleData({ ...editArticleData, content: stateToHTML(editorState.getCurrentContent()) });
            setEditArticleEditorState(editorState);
        }
    };

    const handleEditArticleImage = async (event: any) => {
        const fileToHandle = event.target.files[0];

        if (fileToHandle) {
            try {
                setUploadingNow(true);
                const formData: any = new FormData();
                formData.append('file', fileToHandle);

                const API_KEY = REACT_APP_API_KEY;
                const API_SECRET = REACT_APP_API_SECRET;

                const url = PINATA_URL;

                const response = await axios.post(url, formData, {
                    headers: {
                        'Content-Type': `multipart/form-data;boundary=${formData._boundary}`,
                        pinata_api_key: API_KEY,
                        pinata_secret_api_key: API_SECRET,
                    },
                });

                const photoUrl = response.data.IpfsHash;

                if (editArticleData !== null) {
                    setEditArticleData({ ...editArticleData, coverImage: (PINATA_GATEWAY + photoUrl) });
                }
                setUploadingNow(false);

            } catch (error) {
                console.log('Error sending File to IPFS: ');
                console.log(error);
            }
        }
    };

    const handleSubmitNewPost = async () => {

        // const blxcAmount = await getBlxcNftCount(address);
        // const tycheAmount = await getTycheNftCount(address);

        // if (blxcAmount >= 8 || tycheAmount >= 8) {

        const args = [
            BytesValue.fromUTF8(createArticleData.coverImage),
            BytesValue.fromUTF8(createArticleData.title),
            BytesValue.fromUTF8(createArticleData.twitter),
            BytesValue.fromUTF8(createArticleData.authorName),
            BytesValue.fromUTF8(createArticleData.content),
        ];

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`postNewArticle@${argumentsString}`);
        const gas = (70000000 + createArticleData.content.length * 15000) > 600000000 ? 600000000 : (70000000 + createArticleData.content.length * 15000);

        const tx = {
            value: 0,
            receiver: CONTRACT_ADDRESS,
            data: data.toString(),
            gasLimit: gas,
        };
        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        setSessionId(result.sessionId);
        // }

        // else {
        //     const value = new BigNumber(10).multipliedBy(
        //         Math.pow(10, 6)
        //     );

        //     const args = [
        //         BytesValue.fromUTF8(TOKEN_IDENTIFIER),
        //         new BigUIntValue(TokenPayment.fungibleFromBigInteger(TOKEN_IDENTIFIER, value, 6).valueOf()),
        //         BytesValue.fromUTF8('postNewArticle'),
        //         BytesValue.fromUTF8(createArticleData.coverImage),
        //         BytesValue.fromUTF8(createArticleData.title),
        //         BytesValue.fromUTF8(createArticleData.twitter),
        //         BytesValue.fromUTF8(createArticleData.authorName),
        //         BytesValue.fromUTF8(createArticleData.content),
        //     ];

        //     const { argumentsString } = new ArgSerializer().valuesToString(args);
        //     const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);
        //     const gas = (55000000 + createArticleData.content.length * 1500) > 600000000 ? 600000000 : (55000000 + createArticleData.content.length * 1500);

        //     const tx = {
        //         value: 0,
        //         receiver: CONTRACT_ADDRESS,
        //         data: data.toString(),
        //         gasLimit: gas,
        //     };
        //     await refreshAccount();
        //     const result = await sendTransactions({
        //         transactions: tx,
        //     });

        //     setSessionId(result.sessionId);
        // }
    };

    const handleCancelNewPost = () => {
        setCreateArticleData({
            coverImage: '',
            title: '',
            twitter: '',
            authorName: '',
            content: '',
        });
        setArticleEventHandler(0);
    };

    const handleCancelEditPost = () => {
        setArticleEventHandler(0);
    };

    const handleSubmitEditPost = async () => {
        if (editArticleData !== null) {
            const args = [
                new U32Value(editArticleData.articleId),
                BytesValue.fromUTF8(editArticleData.coverImage),
                BytesValue.fromUTF8(editArticleData.title),
                BytesValue.fromUTF8(editArticleData.twitter),
                BytesValue.fromUTF8(editArticleData.authorName),
                BytesValue.fromUTF8(editArticleData.content),
            ];

            const { argumentsString } = new ArgSerializer().valuesToString(args);
            const data = new TransactionPayload(`updateArticle@${argumentsString}`);

            const gas = (70000000 + editArticleData.content.length * 15000) > 600000000 ?
                600000000 : (70000000 + editArticleData.content.length * 15000);

            const tx = {
                value: 0,
                receiver: CONTRACT_ADDRESS,
                data: data.toString(),
                gasLimit: gas,
            };
            await refreshAccount();
            const result = await sendTransactions({
                transactions: tx,
            });

            setSessionId(result.sessionId);
        }
    };

    const handleRemoveExistingPost = async (deleteId: number) => {
        const args = [
            new U32Value(deleteId),
        ];

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`removeArticle@${argumentsString}`);

        const tx = {
            value: 0,
            receiver: CONTRACT_ADDRESS,
            data: data.toString(),
            gasLimit: 20000000,
        };

        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        setSessionId(result.sessionId);
    };

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            if (transactionType === 'post-article') {
                setTransactionHappen(!transactionHappen);
                setArticleEventHandler(0);
                swal('Success', 'You successfully posted a new article!', 'success');
            } else if (transactionType === 'edit-article') {
                setTransactionHappen(!transactionHappen);
                setArticleEventHandler(0);
                swal('Success', 'You successfully updated article!', 'success');
            } else if (transactionType === 'remove-article') {
                setTransactionHappen(!transactionHappen);
                swal('Success', 'You successfully updated article!', 'success');
            }
        }
    }, [sessionId, hasPendingTransactions]);

    useEffect(() => {
        setMyArticleData(articles.filter((item: IArticleInfo) => item.submitter === address));
    }, []);

    return (
        <div className='post-articles'>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={uploadingNow}
            >
                <CircularProgress
                    color='inherit' />
            </Backdrop>
            <div className='top-already-posted'>
                <div className='posted-label'>
                    My Posted Articles
                </div>
                {
                    myArticleData?.length === 0 ? (
                        <div className='posted-articles-empty'>
                            <img src={EmptyArticles} />
                            <p> No Articles Posted Yet... </p>
                        </div>
                    ) : (
                        <div className='posted-articles-container'>
                            {
                                myArticleData?.map((articleItem: IArticleInfo, index: number) => {
                                    return (
                                        <div key={index} className='row single-article-container'>
                                            <div className='left-cover-image col-sm-12 col-md-12 col-lg-12 col-xl-5'>
                                                <img src={articleItem.coverImage} />
                                            </div>
                                            <div className='right-article-detail col-sm-12 col-md-12 col-lg-12 col-xl-7'>
                                                <div className='top-main-info'>
                                                    <div className='top-title'>
                                                        {articleItem.title}
                                                    </div>
                                                    <div className='middle-author'>
                                                        Written By &nbsp;
                                                        <strong>
                                                            {articleItem.authorName}
                                                        </strong>
                                                    </div>
                                                    <div className='bottom-detail'>
                                                        {articleItem.content.replace(regex, '').replace('&nbsp', '\n').slice(0, 300) + '...'}
                                                    </div>
                                                    <div
                                                        className='read-more'
                                                        onClick={() => handleSingleArticle(articleItem.articleId)}
                                                    >
                                                        Read More ...
                                                    </div>
                                                </div>
                                                <div className='right-job-control'>
                                                    <div
                                                        className='edit-job-btn'
                                                        onClick={() => handleEditExistingPost(articleItem.articleId)}
                                                    >
                                                        <div className='icon-container'>
                                                            <FaPencilAlt />
                                                        </div>
                                                        <div className='edit-text'>
                                                            Edit
                                                        </div>
                                                    </div>
                                                    <div
                                                        className='delete-job-btn'
                                                        onClick={
                                                            () => {
                                                                handleRemoveExistingPost(articleItem.articleId);
                                                                setTransactionType('remove-article');
                                                            }
                                                        }
                                                    >
                                                        <div className='icon-container'>
                                                            <HiTrash />
                                                        </div>
                                                        <div className='delete-text'>
                                                            Delete
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                }
                {
                    articleEventHandler === 0 ? (
                        <div className='new-article-container'>
                            <div
                                className='new-article-btn'
                                onClick={handlePostNewArticle}
                            >
                                Post New Article
                            </div>
                        </div>
                    ) : (
                        <div className='article-detail-container'>
                            {
                                articleEventHandler === 1 ? (
                                    <div className='post-new-article'>
                                        <div className='top-avatar-summary row'>
                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-4'>
                                                <div
                                                    className='avatar-upload'
                                                    style={{
                                                        backgroundImage: `url(${createArticleData?.coverImage})`
                                                    }}
                                                >
                                                    <div
                                                        className='upload-image-btn'
                                                        onClick={() => createInputImage.current.click()}
                                                    >
                                                        <div className='icon-container'>
                                                            <ImCloudUpload />
                                                        </div>
                                                        <div className='upload-text'>
                                                            <input hidden accept='image/*' type='file' onChange={handleCreateArticleImage} ref={createInputImage} />
                                                            Upload
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='right-detail-container col-sm-12 col-md-12 col-lg-12 col-xl-8'>
                                                <div className='input-container'>
                                                    <div className='up-title'>
                                                        Article Title *
                                                    </div>
                                                    <div className='down-edit'>
                                                        <Form.Control
                                                            placeholder='Input title...'
                                                            value={createArticleData?.title}
                                                            onChange={handleArticleTitleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='input-container'>
                                                    <div className='up-title'>
                                                        Twitter Profile Link *
                                                    </div>
                                                    <div className='down-edit'>
                                                        <Form.Control
                                                            placeholder='Add link...'
                                                            value={createArticleData?.twitter}
                                                            onChange={handleArticleTwitterChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='input-container'>
                                                    <div className='up-title'>
                                                        Author Name *
                                                    </div>
                                                    <div className='down-edit'>
                                                        <Form.Control
                                                            placeholder='Input author name...'
                                                            value={createArticleData?.authorName}
                                                            onChange={handleArticleAuthorNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='article-content'>
                                            <Editor
                                                editorState={createArticleEditorState}
                                                toolbarClassName='editor-toolbar'
                                                wrapperClassName='editor-wrapper'
                                                editorClassName='edit-project-editor'
                                                onEditorStateChange={handleArticleContentChange}
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'fontSize', 'list', 'image', 'link', 'emoji',],
                                                    inline: {
                                                        options: ['bold', 'italic', 'underline'],
                                                    },
                                                    blockType: {
                                                        inDropdown: true,
                                                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                    },
                                                    fontSize: {
                                                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className='discard-create-container row'>
                                            <div className='create-container col-sm-12 col-md-12 col-lg-6'>
                                                <div
                                                    className='create-btn'
                                                    onClick={
                                                        () => {
                                                            setTransactionType('post-article');
                                                            handleSubmitNewPost();
                                                        }
                                                    }
                                                >
                                                    Submit
                                                </div>
                                            </div>
                                            <div className='discard-container col-sm-12 col-md-12 col-lg-6'>
                                                <div
                                                    className='discard-btn'
                                                    onClick={handleCancelNewPost}
                                                >
                                                    Cancel
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='edit-existing-article'>
                                        <div className='top-avatar-summary row'>
                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-4'>
                                                <div
                                                    className='avatar-upload'
                                                    style={{
                                                        backgroundImage: `url(${editArticleData?.coverImage})`
                                                    }}
                                                >
                                                    <div
                                                        className='upload-image-btn'
                                                        onClick={() => editInputImage.current.click()}
                                                    >
                                                        <div className='icon-container'>
                                                            <ImCloudUpload />
                                                        </div>
                                                        <div className='upload-text'>
                                                            <input hidden accept='image/*' type='file' onChange={handleEditArticleImage} ref={editInputImage} />
                                                            Upload
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='right-detail-container col-sm-12 col-md-12 col-lg-12 col-xl-8'>
                                                <div className='input-container'>
                                                    <div className='up-title'>
                                                        Article Title *
                                                    </div>
                                                    <div className='down-edit'>
                                                        <Form.Control
                                                            placeholder='Input title...'
                                                            value={editArticleData?.title}
                                                            onChange={handleEditArticleTitleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='input-container'>
                                                    <div className='up-title'>
                                                        Twitter Profile Link *
                                                    </div>
                                                    <div className='down-edit'>
                                                        <Form.Control
                                                            placeholder='Add link...'
                                                            value={editArticleData?.twitter}
                                                            onChange={handleEditArticleTwitterChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='input-container'>
                                                    <div className='up-title'>
                                                        Author Name *
                                                    </div>
                                                    <div className='down-edit'>
                                                        <Form.Control
                                                            placeholder='Input author name...'
                                                            value={editArticleData?.authorName}
                                                            onChange={handleEditArticleAuthorNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='article-content'>
                                            <Editor
                                                editorState={editArticleEditorState}
                                                toolbarClassName='editor-toolbar'
                                                wrapperClassName='editor-wrapper'
                                                editorClassName='edit-project-editor'
                                                onEditorStateChange={handleEditArticleContentChange}
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'fontSize', 'list', 'image', 'link', 'emoji',],
                                                    inline: {
                                                        options: ['bold', 'italic', 'underline'],
                                                    },
                                                    blockType: {
                                                        inDropdown: true,
                                                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                    },
                                                    fontSize: {
                                                        options: [8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 24, 30, 36],
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className='discard-edit-container row'>
                                            <div className='edit-container col-sm-12 col-md-12 col-lg-6'>
                                                <div
                                                    className='edit-btn'
                                                    onClick={
                                                        () => {
                                                            setTransactionType('edit-article');
                                                            handleSubmitEditPost();
                                                        }
                                                    }
                                                >
                                                    Submit
                                                </div>
                                            </div>
                                            <div className='discard-container col-sm-12 col-md-12 col-lg-6'>
                                                <div
                                                    className='discard-btn'
                                                    onClick={handleCancelEditPost}
                                                >
                                                    Cancel
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
};