import React, { useState, useEffect, useContext, useRef } from 'react';
import { Form, NavDropdown } from 'react-bootstrap';
import swal from 'sweetalert';
import axios from 'axios';

import { FaTwitter, FaPencilAlt, FaDiscord, FaChevronDown, FaCrown } from 'react-icons/fa';
import { HiCurrencyDollar, HiTrash } from 'react-icons/hi2';
import { MdGroup, MdAccessTimeFilled } from 'react-icons/md';
import { ImCloudUpload } from 'react-icons/im';
import { MdClose } from 'react-icons/md';

import { useGetAccountInfo, useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { ArgSerializer, BigUIntValue, BooleanValue, BytesValue, TransactionPayload, U32Value, U64Value, TokenPayment } from '@multiversx/sdk-core/out';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions/useGetPendingTransactions';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import BigNumber from 'bignumber.js/bignumber.js';
import { Divider, Backdrop, CircularProgress } from '@mui/material';

import EmptyJobs from 'assets/img/empty-jobs.svg';
import { departmentType } from 'mockdata';
import { REACT_APP_API_KEY, REACT_APP_API_SECRET, PINATA_URL, PINATA_GATEWAY, CONTRACT_ADDRESS, TOKEN_IDENTIFIER } from 'config';
import { JobContext, TransactionHappenContext } from 'App';
import './index.scss';

interface IJobInfo {
    jobId: number;
    companyName: string;
    companyLogo: string;
    jobDescription: string;
    jobTitle: string;
    salary: number;
    department: number;
    jobType: string;
    twitter: string;
    discord: string;
    featured: boolean;
    creator: string;
    createdTime: number;
}

interface ICreateJobInfo {
    companyName: string;
    companyLogo: string;
    jobDescription: string;
    jobTitle: string;
    salary: number;
    department: number;
    jobType: string;
    twitter: string;
    discord: string;
}

export const PostJob = () => {

    const { address } = useGetAccountInfo();
    const [jobData, setJobData] = useState<IJobInfo[]>();
    const [jobEventHandler, setJobEventHandler] = useState(0);
    const jobs = useContext(JobContext);

    const [createJobData, setCreateJobData] = useState<ICreateJobInfo>({
        jobTitle: '',
        department: 0,
        companyName: '',
        companyLogo: '',
        salary: 0,
        jobType: '',
        jobDescription: '',
        twitter: '',
        discord: '',
    });
    const [editJobData, setEditJobData] = useState<IJobInfo | null>(null);
    const [editDefaultJobData, setEditDefaultJobData] = useState<IJobInfo | null>(null);
    const [openSelectFeatured, setOpenSelectFeatured] = useState(false);

    const { hasPendingTransactions } = useGetPendingTransactions();
    const { transactionHappen, setTransactionHappen } = useContext(TransactionHappenContext);
    const [transactionType, setTransactionType] = useState('');
    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({ transactionId: sessionId });

    const [uploadingNow, setUploadingNow] = useState(false);
    const createInputImage: any = useRef();
    const editInputImage: any = useRef();

    const handleCreateNewJob = () => {
        setJobEventHandler(1);
    };

    const handleEditExistingJob = (jobId: number) => {
        setJobEventHandler(2);
        setEditJobData(jobs.filter((item: IJobInfo) => item.jobId === jobId)[0]);
        setEditDefaultJobData(jobs.filter((item: IJobInfo) => item.jobId === jobId)[0]);
    };

    const handleRemoveExistingJob = async (jobId: number) => {
        const args = [
            new U32Value(jobId),
        ];

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`removeProject@${argumentsString}`);

        const tx = {
            value: 0,
            receiver: CONTRACT_ADDRESS,
            data: data.toString(),
            gasLimit: 20000000,
        };

        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        setSessionId(result.sessionId);
    };

    /************************* Edit Existing Job **************************/

    const handleJobTitleChange = (e: any) => {
        if (editJobData !== null) {
            setEditJobData({ ...editJobData, jobTitle: e.target.value });
        }
    };

    const handleCompanyNameChange = (e: any) => {
        if (editJobData !== null) {
            setEditJobData({ ...editJobData, companyName: e.target.value });
        }
    };

    const handleSalaryChange = (e: any) => {
        if (editJobData !== null) {
            setEditJobData({ ...editJobData, salary: e.target.value });
        }
    };

    const handleJobDescriptionChange = (e: any) => {
        if (editJobData !== null) {
            setEditJobData({ ...editJobData, jobDescription: e.target.value });
        }
    };

    const handleTwitterChange = (e: any) => {
        if (editJobData !== null) {
            setEditJobData({ ...editJobData, twitter: e.target.value });
        }
    };

    const handleDiscordChange = (e: any) => {
        if (editJobData !== null) {
            setEditJobData({ ...editJobData, discord: e.target.value });
        }
    };

    const handleDepartmentChange = (index: number) => {
        if (editJobData !== null) {
            setEditJobData({ ...editJobData, department: index });
        }
    };

    const handleEditJobType = (jobType: string) => {
        if (editJobData !== null) {
            setEditJobData({ ...editJobData, jobType: jobType });
        }
    };

    const handleSaveChangeJob = async () => {

        setTransactionType('edit-project');

        if (editJobData !== null) {
            const args = [
                new U32Value(editJobData.jobId),
                BytesValue.fromUTF8(editJobData.companyName),
                BytesValue.fromUTF8(editJobData.companyLogo),
                BytesValue.fromUTF8(editJobData.jobDescription),
                BytesValue.fromUTF8(editJobData.jobTitle),
                new U32Value(editJobData.salary),
                new U32Value(editJobData.department),
                BytesValue.fromUTF8(editJobData.jobType),
                BytesValue.fromUTF8(editJobData.twitter),
                BytesValue.fromUTF8(editJobData.discord),
            ];

            const { argumentsString } = new ArgSerializer().valuesToString(args);
            const data = new TransactionPayload(`updateProject@${argumentsString}`);
            const gas = (55000000 + createJobData.jobDescription.length * 1500) > 600000000 ? 600000000 : (55000000 + createJobData.jobDescription.length * 1500);

            const tx = {
                value: 0,
                receiver: CONTRACT_ADDRESS,
                data: data.toString(),
                gasLimit: gas,
            };

            await refreshAccount();
            const result = await sendTransactions({
                transactions: tx,
            });

            setSessionId(result.sessionId);
        }
    };

    const handleDiscardChangeJob = () => {
        setJobEventHandler(0);
    };

    /************************************************************/

    /********************* Add New Job **************************/

    const handleCreateJobTitleChange = (e: any) => {
        setCreateJobData({ ...createJobData, jobTitle: e.target.value });
    };

    const handleCreateCompanyNameChange = (e: any) => {
        setCreateJobData({ ...createJobData, companyName: e.target.value });
    };

    const handleCreateSalaryChange = (e: any) => {
        setCreateJobData({ ...createJobData, salary: e.target.value });
    };

    const handleCreateJobDescriptionChange = (e: any) => {
        setCreateJobData({ ...createJobData, jobDescription: e.target.value });
    };

    const handleCreateTwitterChange = (e: any) => {
        setCreateJobData({ ...createJobData, twitter: e.target.value });
    };

    const handleCreateDiscordChange = (e: any) => {
        setCreateJobData({ ...createJobData, discord: e.target.value });
    };

    const handleCreateDepartmentChange = (index: number) => {
        setCreateJobData({ ...createJobData, department: index });
    };

    const handleCreateJobType = (jobType: string) => {
        setCreateJobData({ ...createJobData, jobType: jobType });
    };

    const handleSubmitAsFeatured = async () => {

        setTransactionType('create-project');

        const value = new BigNumber(50).multipliedBy(
            Math.pow(10, 6)
        );

        const args = [
            BytesValue.fromUTF8(TOKEN_IDENTIFIER),
            new BigUIntValue(TokenPayment.fungibleFromBigInteger(TOKEN_IDENTIFIER, value, 6).valueOf()),
            BytesValue.fromUTF8('createNewProject'),
            BytesValue.fromUTF8(createJobData.companyName),
            BytesValue.fromUTF8(createJobData.companyLogo),
            BytesValue.fromUTF8(createJobData.jobDescription),
            BytesValue.fromUTF8(createJobData.jobTitle),
            new U32Value(createJobData.salary),
            new U32Value(createJobData.department),
            BytesValue.fromUTF8(createJobData.jobType),
            BytesValue.fromUTF8(createJobData.twitter),
            BytesValue.fromUTF8(createJobData.discord),
            new BooleanValue(true)
        ];

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);
        const gas = (55000000 + createJobData.jobDescription.length * 1500) > 600000000 ? 600000000 : (55000000 + createJobData.jobDescription.length * 1500);

        const tx = {
            value: 0,
            receiver: CONTRACT_ADDRESS,
            data: data.toString(),
            gasLimit: gas,
        };

        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        setSessionId(result.sessionId);
    };

    const handleSubmitAsNormal = async () => {

        setTransactionType('create-project');

        // const value = new BigNumber(100).multipliedBy(
        //     Math.pow(10, 6)
        // );

        const args = [
            // BytesValue.fromUTF8(TOKEN_IDENTIFIER),
            // new BigUIntValue(TokenPayment.fungibleFromBigInteger(TOKEN_IDENTIFIER, value, 6).valueOf()),
            // BytesValue.fromUTF8('createNewProject'),
            BytesValue.fromUTF8(createJobData.companyName),
            BytesValue.fromUTF8(createJobData.companyLogo),
            BytesValue.fromUTF8(createJobData.jobDescription),
            BytesValue.fromUTF8(createJobData.jobTitle),
            new U32Value(createJobData.salary),
            new U32Value(createJobData.department),
            BytesValue.fromUTF8(createJobData.jobType),
            BytesValue.fromUTF8(createJobData.twitter),
            BytesValue.fromUTF8(createJobData.discord),
            new BooleanValue(false)
        ];

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`createNewProject@${argumentsString}`);
        const gas = (55000000 + createJobData.jobDescription.length * 1500) > 600000000 ? 600000000 : (55000000 + createJobData.jobDescription.length * 1500);

        const tx = {
            value: 0,
            receiver: CONTRACT_ADDRESS,
            data: data.toString(),
            gasLimit: gas,
        };

        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        setSessionId(result.sessionId);
    };

    const handleOpenSelectFeatured = () => {
        setOpenSelectFeatured(true);
    };

    const handleCloseSelectFeatured = () => {
        setOpenSelectFeatured(false);
    };

    const handleDiscardCreateJob = () => {
        setJobEventHandler(0);
    };

    const handleCreateLogo = async (event: any) => {
        const fileToHandle = event.target.files[0];

        if (fileToHandle) {
            try {
                setUploadingNow(true);
                const formData: any = new FormData();
                formData.append('file', fileToHandle);

                const API_KEY = REACT_APP_API_KEY;
                const API_SECRET = REACT_APP_API_SECRET;

                const url = PINATA_URL;

                const response = await axios.post(url, formData, {
                    headers: {
                        'Content-Type': `multipart/form-data;boundary=${formData._boundary}`,
                        pinata_api_key: API_KEY,
                        pinata_secret_api_key: API_SECRET,
                    },
                });

                const photoUrl = response.data.IpfsHash;
                setCreateJobData({ ...createJobData, companyLogo: (PINATA_GATEWAY + photoUrl) });
                setUploadingNow(false);

            } catch (error) {
                console.log('Error sending File to IPFS: ');
                console.log(error);
            }
        }
    };

    const handleEditLogo = async (event: any) => {
        const fileToHandle = event.target.files[0];

        if (fileToHandle) {
            try {
                setUploadingNow(true);
                const formData: any = new FormData();
                formData.append('file', fileToHandle);

                const API_KEY = REACT_APP_API_KEY;
                const API_SECRET = REACT_APP_API_SECRET;

                const url = PINATA_URL;

                const response = await axios.post(url, formData, {
                    headers: {
                        'Content-Type': `multipart/form-data;boundary=${formData._boundary}`,
                        pinata_api_key: API_KEY,
                        pinata_secret_api_key: API_SECRET,
                    },
                });

                const photoUrl = response.data.IpfsHash;

                if (editJobData !== null) {
                    setEditJobData({ ...editJobData, companyLogo: (PINATA_GATEWAY + photoUrl) });
                }
                setUploadingNow(false);

            } catch (error) {
                console.log('Error sending File to IPFS: ');
                console.log(error);
            }
        }
    };

    /********************************************************** */

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            if (transactionType === 'create-project') {
                setTransactionHappen(!transactionHappen);
                setJobEventHandler(0);
                setOpenSelectFeatured(false);
                swal('Success', 'You successfully create a new project!', 'success');
            } else if (transactionType === 'edit-project') {
                setTransactionHappen(!transactionHappen);
                setJobEventHandler(0);
                swal('Success', 'You successfully update project!', 'success');
            }
        }
    }, [sessionId, hasPendingTransactions]);

    useEffect(() => {
        setJobData(jobs?.filter((item: IJobInfo) => item.creator === address));
    }, [jobs]);

    return (
        <div className='post-jobs'>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={uploadingNow}
            >
                <CircularProgress
                    color='inherit' />
            </Backdrop>
            <div className={`job-type ${openSelectFeatured ? 'active' : ''}`}>
                <div className='type-modal'>
                    <div className='close-btn'>
                        <MdClose className='close-icon' onClick={handleCloseSelectFeatured} />
                    </div>
                    <div className='up-title'>
                        <p> CHOOSE &nbsp;
                            <span>
                                JOB TYPE
                            </span> &nbsp;
                        </p>
                    </div>
                    <div className='down-input-form'>
                        <div
                            className='submit-btn'
                            onClick={handleSubmitAsFeatured}
                        >
                            POST AS &nbsp;
                            <strong>FEATURED</strong>
                        </div>
                        <div
                            className='submit-btn'
                            onClick={handleSubmitAsNormal}
                        >
                            POST AS &nbsp;
                            <strong>NORMAL</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div className='top-already-posted'>
                <div className='posted-label'>
                    My Posted Jobs
                </div>
                {
                    jobData?.length === 0 ? (
                        <div className='posted-jobs-empty'>
                            <img src={EmptyJobs} />
                            <p> No Posted Jobs Yet... </p>
                        </div>
                    ) : (
                        <div className='posted-jobs-container'>
                            {
                                jobData?.map((job: IJobInfo, index: number) => {
                                    return (
                                        <div key={index} className='single-job-container'>
                                            {
                                                job.featured ? (
                                                    <div className='featured-container'>
                                                        <FaCrown className='crown-icon' />
                                                        FEATURED
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className='job-info'>
                                                <div className='job-primary-info'>
                                                    <div className='left-info'>
                                                        <div className='job-title'>
                                                            {job.jobTitle}
                                                        </div>
                                                        <div className='job-department'>
                                                            {departmentType[job.department - 1]}
                                                        </div>
                                                    </div>
                                                    <div className='right-info'>
                                                        <div className='logo-social-container'>
                                                            <div className='poster-socials'>
                                                                <a
                                                                    target='_blank'
                                                                    rel='noreferrer'
                                                                    href={job.discord}
                                                                >
                                                                    <FaDiscord className='social-icon' />
                                                                </a>
                                                                <a
                                                                    target='_blank'
                                                                    rel='noreferrer'
                                                                    href={job.twitter}
                                                                >
                                                                    <FaTwitter className='social-icon' />
                                                                </a>
                                                            </div>
                                                            <div className='poster-logo'>
                                                                <div className='hexagon hexagon-div'>
                                                                    <div className='hexagon-in-one'>
                                                                        <div
                                                                            className='hexagon-in-two'
                                                                            style={{
                                                                                backgroundImage: `url(${job.companyLogo})`
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='created-time'>
                                                            {new Date(job.createdTime * 1000).toLocaleDateString()}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='job-main-info'>
                                                    <div className='job-salary'>
                                                        <HiCurrencyDollar className='salary-icon' />
                                                        {job.salary + ' USD / Month'}
                                                    </div>
                                                    <div className='job-poster'>
                                                        <MdGroup className='poster-icon' />
                                                        {job.companyName}
                                                    </div>
                                                    <div className='job-description'>
                                                        {job.jobDescription}
                                                    </div>
                                                    <div className='bottom-job-control'>
                                                        <div className='left-job-type'>
                                                            <MdAccessTimeFilled className='time-icon' />
                                                            {job.jobType}
                                                        </div>
                                                        <div className='right-job-control'>
                                                            <div
                                                                className='edit-job-btn'
                                                                onClick={() => handleEditExistingJob(job.jobId)}
                                                            >
                                                                <div className='icon-container'>
                                                                    <FaPencilAlt />
                                                                </div>
                                                                <div className='edit-text'>
                                                                    Edit
                                                                </div>
                                                            </div>
                                                            <div
                                                                className='delete-job-btn'
                                                                onClick={() => handleRemoveExistingJob(job.jobId)}
                                                            >
                                                                <div className='icon-container'>
                                                                    <HiTrash />
                                                                </div>
                                                                <div className='delete-text'>
                                                                    Delete
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                }
                {
                    jobEventHandler === 0 ? (
                        <div className='new-job-container'>
                            <div
                                className='new-job-btn'
                                onClick={handleCreateNewJob}
                            >
                                Add New Job
                            </div>
                        </div>
                    ) : (
                        <div className='job-detail-container'>
                            {
                                jobEventHandler === 1 ? (
                                    <div className='create-new-job'>
                                        <div className='top-avatar-summary row'>
                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-4'>
                                                <div
                                                    className='avatar-upload'
                                                    style={{
                                                        backgroundImage: `url(${createJobData?.companyLogo})`
                                                    }}
                                                >
                                                    <div
                                                        className='upload-image-btn'
                                                        onClick={() => createInputImage.current.click()}
                                                    >
                                                        <div className='icon-container'>
                                                            <ImCloudUpload />
                                                        </div>
                                                        <div className='upload-text'>
                                                            <input hidden accept='image/*' type='file' onChange={handleCreateLogo} ref={createInputImage} />
                                                            Upload
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='summary-container col-sm-12 col-md-12 col-lg-12 col-xl-8'>
                                                <div className='up-summary-title'>
                                                    Project/Job Description *
                                                </div>
                                                <div className='down-summary-edit'>
                                                    <Form.Control as='textarea'
                                                        placeholder='Write a Professional Summary here...'
                                                        value={createJobData?.jobDescription}
                                                        onChange={handleCreateJobDescriptionChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='single-edit-box'>
                                                <div
                                                    className='up-label'
                                                    style={{
                                                        marginTop: '2vh'
                                                    }}
                                                >
                                                    Job Position Title *
                                                </div>
                                                <div className='down-input'>
                                                    <Form.Control
                                                        placeholder='Write a Job Position Name'
                                                        value={createJobData?.jobTitle}
                                                        onChange={handleCreateJobTitleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='single-edit-box'>
                                                <div className='up-label'>
                                                    Project/Company Name *
                                                </div>
                                                <div className='down-input'>
                                                    <Form.Control
                                                        placeholder='Write Project/Company Name'
                                                        value={createJobData?.companyName}
                                                        onChange={handleCreateCompanyNameChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='single-edit-box'>
                                                <div className='up-label'>
                                                    Salary *
                                                </div>
                                                <div className='down-input'>
                                                    <Form.Control
                                                        placeholder='Ex. 1,000 '
                                                        value={createJobData?.salary}
                                                        onChange={handleCreateSalaryChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='department-type row'>
                                                <div className='choose-department-box col-sm-12 col-md-12 col-lg-6'>
                                                    <div className='up-label'>
                                                        Choose a Department *
                                                    </div>
                                                    <div className='down-select'>
                                                        <NavDropdown
                                                            className='filter-dropdown'
                                                            title={
                                                                <div className='filter-by-options'>
                                                                    {
                                                                        createJobData?.department === 0 ? (
                                                                            <div className='selected-blank-options'>
                                                                                No Filter
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        createJobData?.department === 1 ? (
                                                                            <div className='selected-options'>
                                                                                Graphic & Design
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        createJobData?.department === 2 ? (
                                                                            <div className='selected-options'>
                                                                                Digital Marketing
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        createJobData?.department === 3 ? (
                                                                            <div className='selected-options'>
                                                                                Writing & Translation
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        createJobData?.department === 4 ? (
                                                                            <div className='selected-options'>
                                                                                Video & Animation
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        createJobData?.department === 5 ? (
                                                                            <div className='selected-options'>
                                                                                Music & Audio
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        createJobData?.department === 6 ? (
                                                                            <div className='selected-options'>
                                                                                Programming & Tech
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        createJobData?.department === 7 ? (
                                                                            <div className='selected-options'>
                                                                                Business
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        createJobData?.department === 8 ? (
                                                                            <div className='selected-options'>
                                                                                LifeStyle
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        createJobData?.department === 9 ? (
                                                                            <div className='selected-options'>
                                                                                AI Services
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    <FaChevronDown className='filter-down-icon' />
                                                                </div>
                                                            }
                                                        >
                                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(1)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Graphic & Design
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(2)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Digital Marketing
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(3)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Writing & Translation
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(4)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Video & Animation
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(5)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Music & Audio
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(6)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Programming & Tech
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(7)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Business
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(8)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    LifeStyle
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleCreateDepartmentChange(9)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    AI Services
                                                                </div>
                                                            </NavDropdown.Item>
                                                        </NavDropdown>
                                                    </div>
                                                </div>
                                                <div className='job-type-container col-sm-12 col-md-12 col-lg-6'>
                                                    <div className='up-label'>
                                                        Choose a Type *
                                                    </div>
                                                    <div className='select-job-type'>
                                                        <div
                                                            className={`single-type-box ${createJobData?.jobType === 'Full Time' ? 'active' : ''}`}
                                                            onClick={() => handleCreateJobType('Full Time')}
                                                        >
                                                            Full Time
                                                        </div>
                                                        <div
                                                            className={`single-type-box ${createJobData?.jobType === 'Part Time' ? 'active' : ''}`}
                                                            onClick={() => handleCreateJobType('Part Time')}
                                                        >
                                                            Part Time
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='single-edit-box'>
                                                <div className='up-label'>
                                                    Twitter Profile Link *
                                                </div>
                                                <div className='down-input'>
                                                    <Form.Control
                                                        placeholder='Add Link...'
                                                        value={createJobData?.twitter}
                                                        onChange={handleCreateTwitterChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='single-edit-box'>
                                                <div className='up-label'>
                                                    Discord Profile Link *
                                                </div>
                                                <div className='down-input'>
                                                    <Form.Control
                                                        placeholder='Add Link...'
                                                        value={createJobData?.discord}
                                                        onChange={handleCreateDiscordChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='discard-create-container row'>
                                            <div className='create-container col-sm-12 col-md-12 col-lg-6'>
                                                <div
                                                    className='create-btn'
                                                    onClick={handleOpenSelectFeatured}
                                                >
                                                    Submit
                                                </div>
                                            </div>
                                            <div className='discard-container col-sm-12 col-md-12 col-lg-6'>
                                                <div
                                                    className='discard-btn'
                                                    onClick={handleDiscardCreateJob}
                                                >
                                                    Cancel
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='edit-existing-job'>
                                        <div className='top-avatar-summary row'>
                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-4'>
                                                <div
                                                    className='avatar-upload'
                                                    style={{
                                                        backgroundImage: `url(${editJobData?.companyLogo})`
                                                    }}
                                                >
                                                    <div
                                                        className='upload-image-btn'
                                                        onClick={() => editInputImage.current.click()}
                                                    >
                                                        <div className='icon-container'>
                                                            <ImCloudUpload />
                                                        </div>
                                                        <div className='upload-text'>
                                                            <input hidden accept='image/*' type='file' onChange={handleEditLogo} ref={editInputImage} />
                                                            Upload
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='summary-container col-sm-12 col-md-12 col-lg-12 col-xl-8'>
                                                <div className='up-summary-title'>
                                                    Project/Job Description *
                                                </div>
                                                <div className='down-summary-edit'>
                                                    <Form.Control as='textarea'
                                                        placeholder='Write a Professional Summary here...'
                                                        value={editJobData?.jobDescription}
                                                        onChange={handleJobDescriptionChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='single-edit-box'>
                                                <div
                                                    className='up-label'
                                                    style={{
                                                        marginTop: '2vh'
                                                    }}
                                                >
                                                    Job Position Title *
                                                </div>
                                                <div className='down-input'>
                                                    <Form.Control
                                                        placeholder='Write a Job Position Name'
                                                        value={editJobData?.jobTitle}
                                                        onChange={handleJobTitleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='single-edit-box'>
                                                <div className='up-label'>
                                                    Project/Company Name *
                                                </div>
                                                <div className='down-input'>
                                                    <Form.Control
                                                        placeholder='Write Project/Company Name'
                                                        value={editJobData?.companyName}
                                                        onChange={handleCompanyNameChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='single-edit-box'>
                                                <div className='up-label'>
                                                    Salary *
                                                </div>
                                                <div className='down-input'>
                                                    <Form.Control
                                                        placeholder='Ex. 1,000 '
                                                        value={editJobData?.salary}
                                                        onChange={handleSalaryChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='department-type row'>
                                                <div className='choose-department-box col-sm-12 col-md-12 col-lg-6'>
                                                    <div className='up-label'>
                                                        Choose a Department *
                                                    </div>
                                                    <div className='down-select'>
                                                        <NavDropdown
                                                            className='filter-dropdown'
                                                            title={
                                                                <div className='filter-by-options'>
                                                                    {
                                                                        editJobData?.department === 0 ? (
                                                                            <div className='selected-blank-options'>
                                                                                No Filter
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        editJobData?.department === 1 ? (
                                                                            <div className='selected-options'>
                                                                                Graphic & Design
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        editJobData?.department === 2 ? (
                                                                            <div className='selected-options'>
                                                                                Digital Marketing
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        editJobData?.department === 3 ? (
                                                                            <div className='selected-options'>
                                                                                Writing & Translation
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        editJobData?.department === 4 ? (
                                                                            <div className='selected-options'>
                                                                                Video & Animation
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        editJobData?.department === 5 ? (
                                                                            <div className='selected-options'>
                                                                                Music & Audio
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        editJobData?.department === 6 ? (
                                                                            <div className='selected-options'>
                                                                                Programming & Tech
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        editJobData?.department === 7 ? (
                                                                            <div className='selected-options'>
                                                                                Business
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        editJobData?.department === 8 ? (
                                                                            <div className='selected-options'>
                                                                                LifeStyle
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    {
                                                                        editJobData?.department === 9 ? (
                                                                            <div className='selected-options'>
                                                                                AI Services
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    <FaChevronDown className='filter-down-icon' />
                                                                </div>
                                                            }
                                                        >
                                                            <NavDropdown.Item onClick={() => handleDepartmentChange(1)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Graphic & Design
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleDepartmentChange(2)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Digital Marketing
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleDepartmentChange(3)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Writing & Translation
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleDepartmentChange(4)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Video & Animation
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleDepartmentChange(5)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Music & Audio
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleDepartmentChange(6)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Programming & Tech
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleDepartmentChange(7)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    Business
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleDepartmentChange(8)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    LifeStyle
                                                                </div>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={() => handleDepartmentChange(9)}>
                                                                <div className='filter-menu-dropdown'>
                                                                    AI Services
                                                                </div>
                                                            </NavDropdown.Item>
                                                        </NavDropdown>
                                                    </div>
                                                </div>
                                                <div className='job-type-container col-sm-12 col-md-12 col-lg-6'>
                                                    <div className='up-label'>
                                                        Choose a Type *
                                                    </div>
                                                    <div className='select-job-type'>
                                                        <div
                                                            className={`single-type-box ${editJobData?.jobType === 'Full Time' ? 'active' : ''}`}
                                                            onClick={() => handleEditJobType('Full Time')}
                                                        >
                                                            Full Time
                                                        </div>
                                                        <div
                                                            className={`single-type-box ${editJobData?.jobType === 'Part Time' ? 'active' : ''}`}
                                                            onClick={() => handleEditJobType('Part Time')}
                                                        >
                                                            Part Time
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='single-edit-box'>
                                                <div className='up-label'>
                                                    Twitter Profile Link *
                                                </div>
                                                <div className='down-input'>
                                                    <Form.Control
                                                        placeholder='Add Link...'
                                                        value={editJobData?.twitter}
                                                        onChange={handleTwitterChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='single-edit-box'>
                                                <div className='up-label'>
                                                    Discord Profile Link *
                                                </div>
                                                <div className='down-input'>
                                                    <Form.Control
                                                        placeholder='Add Link...'
                                                        value={editJobData?.discord}
                                                        onChange={handleDiscordChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='discard-save-container row'>
                                            <div className='save-container col-sm-12 col-md-12 col-lg-6'>
                                                {
                                                    editJobData === editDefaultJobData ? (
                                                        <div className='save-btn'>
                                                            Save Changes
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className='save-btn active'
                                                            onClick={handleSaveChangeJob}
                                                        >
                                                            Save Changes
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className='discard-container col-sm-12 col-md-12 col-lg-6'>
                                                <div
                                                    className='discard-btn'
                                                    onClick={handleDiscardChangeJob}
                                                >
                                                    Discard Changes
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
};